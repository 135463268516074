import { DossierService } from 'src/app/core/services/dossier.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ApplicationRef,
  Component,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-file',
  templateUrl: './view-file.component.html',
  styleUrls: ['./view-file.component.sass'],
})
export class ViewFileComponent implements AfterViewInit, OnDestroy {
  fileUid: number;
  fileKey: string;
  docFrameDoneLoading: boolean = false;
  documentTempUrl: SafeResourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl('');
  bugInterval: ReturnType<typeof setInterval>;
  @ViewChild('iframeElement') iframeElement: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private dossierService: DossierService,
    private domSanitizer: DomSanitizer,
    private applicationRef: ApplicationRef
  ) {
    this.route.params.subscribe((params) => {
      if (params['uid']) {
        // If uid is present in the route and it isn't equal to the current uid
        if (this.fileUid != parseInt(params['uid'])) {
          // Set this as the current uid
          this.fileUid = parseInt(params['uid']);
        }
      }
    });
  }


  ngAfterViewInit(): void {
    this.loadDocument();
    this.watchFrame();
  }

  loadDocument() {
    if (this.docFrameDoneLoading == false) {
      this.dossierService.getFileTempKey(this.fileUid).subscribe((response) => {
        if (response.result) {
          this.fileKey = response.result;
          this.loadDocumentInFrame();

          this.bugInterval = setInterval(() => {
            this.loadDocumentInFrame();
          }, 3000);

          this.watchFrame();
        }
      });
    }
  }

  loadDocumentInFrame() {
    this.documentTempUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
      'https://docs.google.com/viewer?embedded=true&url=' +
        environment.fileServiceUpload +
        '?fouid=' +
        this.fileKey
    );

    this.applicationRef.tick();
  }

  watchFrame() {
    let componentScope = this;
    if (this.iframeElement) {
      this.iframeElement.nativeElement.addEventListener('load', function () {
        if (componentScope.fileKey) {
          componentScope.docFrameDoneLoading = true;
          componentScope.applicationRef.tick();
          clearInterval(componentScope.bugInterval);
        }
      });
    }
  }


  ngOnDestroy(): void {
    if(this.bugInterval){
      clearInterval(this.bugInterval);
    }
  }

}
