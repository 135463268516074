<header>
  <div class="header-wrap">
    <div class="left-side">
      <div class="back-button" (click)="onGoBack()">
        <i class="fa-solid fa-arrow-left"></i>
      </div>
      <h1>{{mailTitle}}</h1>
    </div>
    <div class="right-side">
      <div class="mail-compose-icons" *ngIf="!loadingSend">
        <div class="compose-icon attachment" (click)="onAddAttachment()">
          <i class="fa-solid fa-paperclip"></i>
        </div>
        <div class="compose-icon send-postponed" (click)="onPostponeSend()">
          <i class="fa-solid fa-clock"></i>
        </div>
        <div class="compose-icon send" (click)="onSendMail()">
          <i class="fa-solid fa-paper-plane-top"></i>
        </div>
      </div>
      <div class="mailsend-loading" *ngIf="loadingSend">
        <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
          <defs>
              <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
                  <stop stop-color="#0C88E0" stop-opacity="0" offset="0%"/>
                  <stop stop-color="#0C88E0" stop-opacity=".631" offset="63.146%"/>
                  <stop stop-color="#0C88E0" offset="100%"/>
              </linearGradient>
          </defs>
          <g fill="none" fill-rule="evenodd">
              <g transform="translate(1 1)">
                  <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" stroke-width="2">
                      <animateTransform
                          attributeName="transform"
                          type="rotate"
                          from="0 18 18"
                          to="360 18 18"
                          dur="0.9s"
                          repeatCount="indefinite" />
                  </path>
                  <circle fill="#0C88E0" cx="36" cy="18" r="1">
                      <animateTransform
                          attributeName="transform"
                          type="rotate"
                          from="0 18 18"
                          to="360 18 18"
                          dur="0.9s"
                          repeatCount="indefinite" />
                  </circle>
              </g>
          </g>
        </svg>
      </div>
      <div class="postponedrawer">
        <div class="postpone-inner">
          <div class="postponedrawer-title" *transloco="let t; read: 'emails'">{{t('pickADateAndTime')}}</div>
          <input type="datetime-local" [value]="closestHour | date:'YYYY-MM-ddTHH:mm'" [min]="minPostPone | date:'YYYY-MM-ddTHH:mm'" #postponedateInput>
          <div class="buttonwrap-postpone">
            <button class="postpone-send" (click)="onSendMailPostPoned()" *transloco="let t; read: 'emails'">
              {{t('postPoneSend')}}
              <i class="fa-solid fa-paper-plane-top"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
