import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-relations-contact-list',
  templateUrl: './relations-contact-list.component.html',
  styleUrls: ['./relations-contact-list.component.sass']
})
export class RelationsContactListComponent implements OnInit {

  @Input() relationContactsListing:any = [];
  constructor() { }

  ngOnInit(): void {
  }

}
