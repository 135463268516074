import { Subscription } from 'rxjs';
import { CalendarService } from './../../../../core/services/calendar.service';
import { EmailsService } from 'src/app/core/services/emails.service';
import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { calenderItem } from 'src/app/core/interfaces/mail-interface';

@Component({
  selector: 'app-calendar-invite',
  templateUrl: './calendar-invite.component.html',
  styleUrls: ['./calendar-invite.component.sass']
})
export class CalendarInviteComponent implements OnInit, OnDestroy{

  @Input() calItem:calenderItem;
  @Input() mailUid:number;
  loading:boolean = false;
  calItemsCloseBy = [];
  showItemsCloseBy:boolean = false;
  // 0 = Unknown || 1 = Conflicting items || 2 = No Conflicts found
  callConflict:number = 0;
  $subscription1:Subscription;

  constructor(private emailsService: EmailsService, private calendarService: CalendarService) { }




  ngOnInit(): void {
    // When the block is showing, and the status is still pending we can load the calendar items for this day to check if there will be conflicts
    if(this.calItem.show && this.calItem.status == 'pending'){
      this.checkCalendarForThisDay();
    }
  }

  checkCalendarForThisDay(){
    this.$subscription1 = this.calendarService.getItemsByDateTime(this.calItem.start,this.calItem.end).subscribe(response => {
      if(response && response.result){
        for(let closeItem of response.result){
          if(closeItem.title != this.calItem.title){
            this.calItemsCloseBy.unshift(closeItem);
          }
        }
        if(this.calItemsCloseBy.length){
          this.callConflict = 1;
        }else{
          this.callConflict = 2;
        }
      }
    })
  }

  onAcceptInvite(){
    this.loading = true;
    this.emailsService.updateInviteStatus(this.mailUid, 'accepted').subscribe(response => {
      this.calItem.status = 'accepted';
      this.showItemsCloseBy = false;
      this.loading = false;
    });
  }
  onDeclineInvite(){
    this.loading = true;
    this.emailsService.updateInviteStatus(this.mailUid, 'rejected').subscribe(response => {
      this.calItem.status = 'rejected';
      this.showItemsCloseBy = false;
      this.loading = false;
    });
  }

  onShowEvents(){
    this.showItemsCloseBy = !this.showItemsCloseBy;
  }

  ngOnDestroy(): void {
    if(this.$subscription1){
      this.$subscription1.unsubscribe();
    }
  }
}
