import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateconverter'
})

export class DateConverterPipe implements PipeTransform {
  transform(value: string) {
    if(value && value.length == 8){
      let tempval = value;
      let newval = tempval.substr(0,4) +"-"+ tempval.substr(4)
      return newval.substr(0,7) +"-"+ newval.substr(7);
    }else{
      return value;
    }
  }
}
