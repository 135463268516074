import { Subscription } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { SettingsService } from './core/services/settings.service';
import { AuthService } from './core/auth/auth.service';
import {
  Component,
  HostBinding,
  OnInit,
  Renderer2,
  OnDestroy,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivationEnd, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatomoTracker } from '@ngx-matomo/tracker';
import { User } from './core/auth/user.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})

export class AppComponent implements OnInit, OnDestroy{
  authenticated: boolean = false;
  showNavigation: boolean = true;

  $subscription1: Subscription;
  $subscription2: Subscription;
  $subscription3: Subscription;
  $subscription4: Subscription;
  $subscription5: Subscription;

  current_user: User;

  darkMode: boolean = false;

  @HostBinding('style.--theme-main') themeMain: string = '#0c88e0';
  @HostBinding('style.--theme-background') themeBackground: string = '#F3F5FF';

  constructor(
    private renderer: Renderer2,
    private meta: Meta,
    private authService: AuthService,
    private settingService: SettingsService,
    private router: Router,
    private http: HttpClient,
    private translocoService: TranslocoService,
    private elementRef: ElementRef,
    private readonly tracker: MatomoTracker
  ) {

    this.$subscription1 = this.router.events.subscribe((events) => {
      if (events instanceof ActivationEnd) {
        this.showNavigation = events.snapshot.data['show_navigation'];
      }
    });

    let availLangs = JSON.parse(localStorage.getItem('available_langs'));
    if (availLangs) {
      this.translocoService.setAvailableLangs(availLangs);
    }

    this.$subscription2 = this.http
      .get<[]>(
        `https://translations.otysonthego.nl/translations/available.json`
      )
      .subscribe((res) => {
        localStorage.setItem('available_langs', JSON.stringify(res));
        this.translocoService.setAvailableLangs(res);
      });
  }

  ngOnInit(): void {
    // Trigger the auto login
    this.authService.autoLogin();

    this.$subscription3 = this.authService.user.subscribe(user => {
      this.current_user = user;
      if (user != null) {
        if (user.sessionId != null) {
          this.authenticated = true;
        } else {
          this.authenticated = false;
        }
      } else {
        this.authenticated = false;
      }
    });

    // When the theme get's changed let's update it
    this.$subscription4 = this.settingService.themeObservable.subscribe(
      (res) => {
        if (res) {
          this.updateTheme();
        }
      }
    );

    this.$subscription5 = this.settingService.langObservable.subscribe(
      (res) => {
        if (res && res != 'Default') {
          this.translocoService.setActiveLang(res);
        }else if(res == 'Default'){
          let tempUser = this.authService.user.getValue();
          this.translocoService.setActiveLang(tempUser.defaultContentLanguage);
        }
      }
    );


    // Ge the theme and apply it
    this.updateTheme();

  }

  switchBodyClass() {
    if (this.darkMode) {
      this.renderer.addClass(document.body, 'mode--dark');
      this.renderer.removeClass(document.body, 'mode--light');
      if(this.authenticated){
        this.tracker.setCustomDimension(1, 'Dark');
      }
    } else {
      this.renderer.addClass(document.body, 'mode--light');
      this.renderer.removeClass(document.body, 'mode--dark');
      if(this.authenticated){
        this.tracker.setCustomDimension(1, 'Light');
      }
    }
  }

  updateTheme() {
    let themeSetting = this.settingService.getSetting('01theme');
    let darkmodeSetting = this.settingService.getSetting('05darkMode');
    if (darkmodeSetting) {
      if (darkmodeSetting.value == 0) {
        this.darkMode = true;
      } else if (darkmodeSetting.value == 1) {
        if (
          window.matchMedia &&
          window.matchMedia('(prefers-color-scheme: dark)').matches
        ) {
          this.darkMode = true;
        } else {
          this.darkMode = false;
        }
      } else {
        this.darkMode = false;
      }

      this.switchBodyClass();
    }
    if (themeSetting) {
      this.themeMain = themeSetting.value['themeMain'];
      if (this.darkMode) {
        this.themeBackground = '#26303C';
      } else {
        this.themeBackground = themeSetting.value['themeBackground'];
      }

      this.meta.updateTag(
        { content: this.themeBackground },
        'name=theme-color'
      );
    }
  }

  ngOnDestroy(): void {
    if (this.$subscription1) {
      this.$subscription1.unsubscribe();
    }
    if (this.$subscription2) {
      this.$subscription2.unsubscribe();
    }
    if (this.$subscription3) {
      this.$subscription3.unsubscribe();
    }
    if (this.$subscription4) {
      this.$subscription4.unsubscribe();
    }
  }
}


