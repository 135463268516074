import { StoredQueryTransformerService } from './storedquery-transformer.service';
import { CachingService } from './caching.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CandidateService {
  private candidateList = new BehaviorSubject<any>(null);
  private favoriteCandidateList = new BehaviorSubject<any>(null);
  private candidateSearchResultList = new BehaviorSubject<any>(null);
  private candidateDetail = new BehaviorSubject<any>(null);
  private matchCriteriaSettings = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private cachingService: CachingService,
    private storedQueryTransformerService: StoredQueryTransformerService
  ) {
  }

  getCandidatesList(page: number = 1, storedQueryData:any = null) {
    let limit = 20;
    let offset = 0;

    if (page != 1) {
      offset = page * limit - limit;
    }

    // Define the OWS method
    const requestMethod = 'Otys.Services.CandidateService.getListEx';
    // Define the body of the request (params)
    const requestBody:any = [
      {
        search: {
          ACTONOMY: {
            DATA: {
              dummy: {
                value: 1
              }
            },
            OPTIONS: {
              getTotalCount: 1,
              limit: limit,
              offset: offset,
              sort: {
                SCORE: "DESC",
                entryDateFull: "DESC"
              },
              facetsToUse: []
            },
            VERSION: 2,
            SUB_VERSION: 0
          }
        },
        limit: limit,
        excludeLimitCheck: true,
        getTotalCount: true,
        what: {
          uid: 1,
          internalId: 1,
          pointOfEntry: 1,
          hasCv: 1,
          entryDateTime: 1,
          Person: {
            firstName: 1,
            infix: 1,
            lastName: 1,
            photoFileName: 1
          },
          status: 1,
          statusId: 1,
          applyCount: 1
        }
      }
    ];

    if(storedQueryData){
      let transformed = this.storedQueryTransformerService.transform(storedQueryData);
      if(transformed.conditional){
        requestBody[0].condition = transformed.conditional;
      }
      requestBody[0].search.ACTONOMY.DATA = transformed.actonomy;
    }

    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'true'
      },
    };

    if (page == 1) {
      // Retrieve cached data (if theres any)
      let cached_data = this.cachingService.getCache(
        requestMethod,
        requestBody
      );
      if (cached_data) {
        if (cached_data.result.listOutput.length) {
          this.candidateList.next({
            page: page,
            items: cached_data.result.listOutput,
          });
        }
      } else {
        this.candidateList.next(null);
      }
    } else {
      this.candidateList.next(null);
    }

    // Retrieve live data
    this.http
      .post<any>(environment.owsUrl, requestBody, requestHeaders)
      .subscribe((resData) => {
        if (resData) {
          if (resData.result.listOutput) {
            this.candidateList.next({
              page: page,
              items: resData.result.listOutput,
            });
          }
        }
      });

    // Return the relation list as an observable so other components can listen to any updates
    return this.candidateList.asObservable();
  }


  getFavoriteCandidatesList(page: number = 1, storedQueryData:any = null) {
    let limit = 20;
    let offset = 0;

    if (page != 1) {
      offset = page * limit - limit;
    }

    // Define the OWS method
    const requestMethod = 'Otys.Services.CandidateService.getListEx';
    // Define the body of the request (params)
    const requestBody = [
      {
        search: {
          ACTONOMY: {
            DATA: {
              favorites: {
                value: true
              }
            },
            OPTIONS: {
              getTotalCount: 1,
              limit: limit,
              offset: offset,
              sort: {
                SCORE: "DESC",
                entryDateFull: "DESC"
              },
              facetsToUse: []
            },
            VERSION: 2,
            SUB_VERSION: 0
          }
        },
        limit: limit,
        excludeLimitCheck: true,
        getTotalCount: true,
        what: {
          uid: 1,
          internalId: 1,
          pointOfEntry: 1,
          hasCv: 1,
          entryDateTime: 1,
          Person: {
            firstName: 1,
            infix: 1,
            lastName: 1,
            photoFileName: 1
          },
          status: 1,
          statusId: 1,
          applyCount: 1
        }
      }
    ];

    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'true'
      },
    };

    if (page == 1) {
      // Retrieve cached data (if theres any)
      let cached_data = this.cachingService.getCache(
        requestMethod,
        requestBody
      );
      if (cached_data) {
        if (cached_data.result.listOutput.length) {
          this.favoriteCandidateList.next({
            page: page,
            items: cached_data.result.listOutput,
          });
        }
      } else {
        this.favoriteCandidateList.next(null);
      }
    } else {
      this.favoriteCandidateList.next(null);
    }

    // Retrieve live data
    this.http
      .post<any>(environment.owsUrl, requestBody, requestHeaders)
      .subscribe((resData) => {
        if (resData) {
          if (resData.result.listOutput) {
            this.favoriteCandidateList.next({
              page: page,
              items: resData.result.listOutput,
            });
          }
        }
      });

    // Return the relation list as an observable so other components can listen to any updates
    return this.favoriteCandidateList.asObservable();
  }


  getSearchResults(searchInput: string) {

    this.candidateSearchResultList.next(null);

    // Define the OWS method
    const requestMethod = 'Otys.Services.CandidateService.getListEx';
    // Define the body of the request (params)
    const requestBody = [
      {
        search: {
          ACTONOMY: {
            DATA: {
              keywords: {
                options: {
                  enableExpansion: false,
                  required: true,
                  searchList: [
                    "workexperience",
                    "personal"
                  ],
                  searchMode: "one"
                },
                value: '*' + searchInput + '*'
              }
            },
            OPTIONS: {
              getTotalCount: 1,
              limit: 75,
              offset: 0,
              sort: {
                SCORE: "DESC",
                entryDateFull: "DESC"
              },
              facetsToUse: []
            },
            VERSION: 2,
            SUB_VERSION: 0
          }
        },
        limit: 75,
        excludeLimitCheck: true,
        getTotalCount: true,
        what: {
          uid: 1,
          internalId: 1,
          pointOfEntry: 1,
          hasCv: 1,
          entryDateTime: 1,
          Person: {
            firstName: 1,
            infix: 1,
            lastName: 1,
            photoFileName: 1
          },
          status: 1,
          statusId: 1,
          applyCount: 1
        }
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'false',
      },
    };


    // Retrieve live data
    this.http
    .post<any>(environment.owsUrl, requestBody, requestHeaders)
    .subscribe((resData) => {
      if (resData) {
        if (resData.result.listOutput) {
          this.candidateSearchResultList.next({
            page: 1,
            items: resData.result.listOutput,
          });
        }
      }
    });

    // Return the relation list as an observable so other components can listen to any updates
    return this.candidateSearchResultList.asObservable();
  }

  getCandidateDetail(uid: string) {
    // Define the OWS method
    const requestMethod = 'Otys.Services.CandidateService.getDetail';
    // Define the body of the request (params)
    const requestBody = [
      uid,
      {
        uid: 1,
        Placement: 1,
        entryDateTime: 1,
        createdByUser: 1,
        user: 1,
        lastModified: 1,
        modifiedBy: 1,
        internalId: 1,
        shortDescription: 1,
        applyCount: 1,
        status: 1,
        hasCv: 1,
        cvFileName: 1,
        Person: {
          firstName: 1,
          infix: 1,
          suffix: 1,
          lastName: 1,
          emailPrimary: 1,
          birthdate: 1,
          gender: 1,
          PhoneNumbers: {
            type: 1,
            phoneNumber: 1
          },
          AddressPrimary: {
            address: 1,
            city: 1,
            postcode: 1,
            countryCode: 1,
            stateCode: 1
          },
          Emails: {
            email: 1,
            isPrimary: 1
          },
          nationality: 1,
          married: 1,
          placeOfBirth: 1,
          photoFileName: 1
        },
        Addresses: {
          uid: 1,
          address: 1,
          postcode: 1,
          state: 1,
          city: 1,
          type: 1
        },
        matchCriteriaNames: 1,
        matchCriteria_1: 1,
        matchCriteria_2: 1,
        matchCriteria_3: 1,
        matchCriteria_4: 1,
        matchCriteria_5: 1,
        matchCriteria_6: 1,
        matchCriteria_7: 1,
        matchCriteria_8: 1,
        matchCriteria_9: 1,
        matchCriteria_10: 1,
        matchCriteria_11: 1,
        matchCriteria_12: 1,
        matchCriteria_13: 1,
        matchCriteria_14: 1,
        matchCriteria_15: 1,
        matchCriteria_16: 1,
        matchCriteria_17: 1,
        matchCriteria_18: 1,
        Hobbies: 1,
        EducationHistory: {
          uid: 1,
          degree: 1,
          endDateFormatted: 1,
          startDateFormatted: 1,
          institute: 1
        },
        EmploymentHistory: {
          uid: 1,
          experience: 1,
          employerNameAndPlace: 1,
          endDateFormatted: 1,
          startDateFormatted: 1
        },
        ComputerSkills: {
          skill: 1
        },
        SoftSkills: {
          skill: 1
        },
        LanguageSkills: {
          skill: 1,
          languageCode: 1,
          proficiency: 1,
          profLevelRead: 1,
          profLevelWrite: 1,
          profLevelSpoken: 1
        }
      },
      null,
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'true',
        'ows-cachettl': '1'
      },
    };

    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if (cached_data) {
      this.candidateDetail.next(cached_data.result);
    } else {
      this.candidateDetail.next(null);
    }

    // Retrieve live data
    this.http
      .post<any>(environment.owsUrl, requestBody, requestHeaders)
      .subscribe((resData) => {
        if (resData) {
          if (resData.result) {
            this.candidateDetail.next(resData.result);
          }
        }
      });

    // Return the relation list as an observable so other components can listen to any updates
    return this.candidateDetail.asObservable();
  }

  getMatchCriteriaSettings(){
    // Define the OWS method
    const requestMethod = 'Otys.Services.MatchCriteriaSettingsService.getList';
    // Define the body of the request (params)
    const requestBody = [
      {
        what: {
          uid: 1,
          name: 1,
          active: 1
        },
        limit: 100
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'true',
        'ows-cachettl': '1'
      },
    };

    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if (cached_data) {
      this.matchCriteriaSettings.next(cached_data.result);
    } else {
      this.matchCriteriaSettings.next(null);
    }

    if(this.cachingService.getCacheAge(requestMethod, requestBody) === false || this.cachingService.getCacheAge(requestMethod, requestBody) > 60){
    // Retrieve live data
      this.http
      .post<any>(environment.owsUrl, requestBody, requestHeaders)
      .subscribe((resData) => {
        if (resData) {
          if (resData.result) {
            this.matchCriteriaSettings.next(resData.result);
          }
        }
      });
    }

    // Return the relation list as an observable so other components can listen to any updates
    return this.matchCriteriaSettings.asObservable();
  }

  getProcedures(uid:string){
    // Define the OWS method
    const requestMethod = 'Otys.Services.ProcedureService.getListEx';
    // Define the body of the request (params)
    const requestBody = [
      {
        what: {
          uid: 1,
          entryDateTime: 1,
          vacancyUid: 1,
          vacancy: 1,
          ProcedureStatus1: {
            uid: 1,
            status: 1
          },
          ProcedureStatus2: {
            uid: 1,
            status: 1
          },
          ProcedureStatus3: {
            uid: 1,
            status: 1
          }
        },
        limit: 500,
        offset: 0,
        getTotalCount: 1,
        sort: {
          entryDateTime: "DESC"
        },
        excludeLimitCheck: true,
        condition: {
          type: "AND",
          items: [
            {
              type: "COND",
              field: "candidateUid",
              op: "EQ",
              param: uid
            }
          ]
        }
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'false',
      },
    };


    // Retrieve live data
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders)
  }



  updatePicture(uid:string, photoFileName:string){
    // Define the OWS method
    const requestMethod = 'Otys.Services.CandidateService.update';
    // Define the body of the request (params)
    const requestBody = [
      uid,
      {
          Person : {
              photoFileName: photoFileName
          }
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'false',
      },
    };


    // Retrieve live data
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders)
  }


}
