import { TranslocoService } from '@ngneat/transloco';
import { AuthService } from './../../../core/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/core/auth/user.model';
import SwiperCore, { Pagination, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { VacancyService } from 'src/app/core/services/vacancy.service';
import { Subscription } from 'rxjs';
import { publicationOptions, vacancyDetail } from 'src/app/core/interfaces/vacancies.interface';
import { procedure } from 'src/app/core/interfaces/procedures.interface';

SwiperCore.use([Pagination]);

@Component({
  selector: 'app-vacancy-detail',
  templateUrl: './vacancy-detail.component.html',
  styleUrls: ['./vacancy-detail.component.sass']
})

export class VacancyDetailComponent implements OnInit, OnDestroy {

  vacancyLoaded: boolean = false;
  vacancyUid: string;
  vacancy: vacancyDetail;
  vacancyTextFields:string[] = [
    "textField_description",
    "textField_companyProfile",
    "textField_requirements",
    "textField_salary",
    "textField_companyCulture",
    "textField_summary",
    "textField_extra1",
    "textField_extra2",
    "textField_extra3",
    "textField_extra4",
    "textField_extra5",
    "textField_extra6",
    "textField_extra7",
    "textField_extra8",
    "textField_extra9",
    "textField_extra10"
  ];
  vacancyPhotoUrl: string;
  config: SwiperOptions = {
    slidesPerView: 1,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        let tabs = ['General', 'Dossier', 'Procedures', 'Texts', 'Publication'];
        return '<span class="' + className + '">' + tabs[index] + '</span>';
      },
    },
  };
  current_user: User;
  showDossier:boolean = false;
  procedures:procedure[] = [];
  publicationOptions:publicationOptions;

  $subscription1:Subscription;
  $subscription2:Subscription;
  $subscription3:Subscription;
  $subscription4:Subscription;
  $subscription5:Subscription;
  $subscription6:Subscription;

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  constructor(
    private vacancyService: VacancyService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private translationService: TranslocoService
  ) {

    this.$subscription1 = this.authService.user.subscribe((res) => {
      this.current_user = res;
    });

    // When the component is loaded trough an URL including the uid param in the route, we should read it
    this.$subscription2 = this.route.params.subscribe((params) => {
      if (params['uid']) {
        // If uid is present in the route and it isn't equal to the current uid
        if (this.vacancyUid != params['uid']) {
          // Set this as the current uid
          this.vacancyUid = params['uid'];
        }
      }
    });
  }


  ngOnInit(): void {
    this.loadvacancy();
    this.loadProcedures();
    this.loadPublicationData();
    this.loadTabTranslations();
  }

  loadvacancy() {
    this.$subscription3 = this.vacancyService
      .getVacancyDetail(this.vacancyUid)
      .subscribe((response) => {
        if (response) {
          this.vacancy = response;
          this.addPhotoUrl();
          this.vacancyLoaded = true;
          this.showDossier = true;
        }
      });
  }

  addPhotoUrl() {
    let sess = this.current_user.sessionId;
    this.vacancyPhotoUrl =
      environment.fileServiceDownload +
      '?ouid=' +
      this.vacancy.photoFileName +
      '&class=VacancyPhoto&sessionId=' +
      sess;
  }

  loadProcedures(){
    this.$subscription4 = this.vacancyService.getProcedures(this.vacancyUid).subscribe(res => {
      if(res && res.result && res.result.listOutput.length){
        this.procedures = res.result.listOutput;
      }
    })
  }

  loadPublicationData(){
    this.$subscription5 = this.vacancyService.getPublicationOptions().subscribe(res => {
      if(res){
        this.publicationOptions = res;
      }
    });
  }

  loadTabTranslations(){
    this.$subscription6 = this.translationService.selectTranslate('vacancies.tabGeneral').subscribe(res => {
      // Get the translation values for the tabs
      let tabs = [
        this.translationService.translate('vacancies.tabGeneral'),
        this.translationService.translate('vacancies.tabDossier'),
        this.translationService.translate('vacancies.tabProcedures'),
        this.translationService.translate('vacancies.tabTexts'),
        this.translationService.translate('vacancies.tabPublication')
      ];
      // Set the new renderBullet function for the new translations
      this.config.pagination = {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + tabs[index] + '</span>';
        },
      };
    });
  }

  ngOnDestroy(): void {
    if(this.$subscription1){
      this.$subscription1.unsubscribe();
    }
    if(this.$subscription2){
      this.$subscription2.unsubscribe();
    }
    if(this.$subscription3){
      this.$subscription3.unsubscribe();
    }
    if(this.$subscription4){
      this.$subscription4.unsubscribe();
    }
    if(this.$subscription5){
      this.$subscription5.unsubscribe();
    }
    if(this.$subscription6){
      this.$subscription6.unsubscribe();
    }
  }

}
