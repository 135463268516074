import { Subscription } from 'rxjs';
import { SettingsService } from 'src/app/core/services/settings.service';
import { AuthService } from './../../../../core/auth/auth.service';
import { CandidateService } from 'src/app/core/services/candidate.service';
import { Component, OnInit, OnDestroy, AfterContentInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/core/auth/user.model';
import { CachingService } from 'src/app/core/services/caching.service';

@Component({
  selector: 'app-candidatewidget',
  templateUrl: './candidatewidget.component.html',
  styleUrls: ['./candidatewidget.component.sass']
})
export class CandidatewidgetComponent implements AfterContentInit, OnDestroy {

  candidates:any = [];
  current_user:User;
  $candidatesSubscription:Subscription;
  dashboardLayout:any;
  listLimit:number = 1;
  candidateFilter:number = 0;

  constructor(private candidateService: CandidateService, private authService: AuthService, private settingService: SettingsService) {
    this.authService.user.subscribe(res => {
      this.current_user = res;
    })
    this.dashboardLayout = this.settingService.getSetting('02dashboardLayout').value;
    let candidateWidget = this.dashboardLayout.find(item => item.component === 'app-candidatewidget');
    this.listLimit = candidateWidget.limit;
    this.candidateFilter = this.settingService.getSetting('03candidateFilterOnDashboard').value;
  }


  ngAfterContentInit(): void {
    this.loadCandidates();
  }

  loadCandidates(){
    if(this.candidateFilter == 1){
      this.$candidatesSubscription = this.candidateService.getCandidatesList(1).subscribe(response => {
        if(response){
          this.candidates = [response];
          this.addPhotoUrlToCandidates();
        }
      });
    }else{
      this.$candidatesSubscription = this.candidateService.getFavoriteCandidatesList(1).subscribe(response => {
        if(response){
          this.candidates = [response];
          this.addPhotoUrlToCandidates();
        }
      });
    }
  }

  addPhotoUrlToCandidates(){
    var sess = this.current_user.sessionId;
    var candLimit = this.listLimit;
    this.candidates.forEach(function(page){

      // Only show 5 candidates on the dashboard
      page.items = page.items.slice(0, candLimit);

      page.items.forEach(function(candidate){

        // Create a fullname field
        candidate.fullname = '';
        if(candidate.Person.firstName)
          candidate.fullname += candidate.Person.firstName;
        if(candidate.Person.infix)
          candidate.fullname += ' ' + candidate.Person.infix;
        if(candidate.Person.lastName)
          candidate.fullname += ' ' + candidate.Person.lastName;

        if(candidate.Person.photoFileName){
          candidate.photoUrl = environment.fileServiceDownload + '?ouid=' + candidate.uid + '&class=CandidatePhoto&sessionId=' + sess;
        }else{
          candidate.photoUrl = null;
        }
      });
    });
  }


  ngOnDestroy(): void {
    if(this.$candidatesSubscription){
      this.$candidatesSubscription.unsubscribe();
    }
  }

}
