import { TranslocoService } from '@ngneat/transloco';
import { TaskService } from './../../../core/services/task.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import SwiperCore, { Pagination,  SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

SwiperCore.use([Pagination]);

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.sass']
})
export class TaskDetailComponent implements OnInit, OnDestroy{

  $subscription1:Subscription;
  $subscription2:Subscription;
  $subscription3:Subscription;
  taskLoaded:boolean = false;
  taskId:number;
  task:any;
  comments:any = [];

  config: SwiperOptions = {
    slidesPerView: 1,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        let tabs = ['General', 'Comments', 'Dossier'];
        return '<span class="' + className + '">' + tabs[index] + '</span>';
      },
    }
  };

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  constructor(private route: ActivatedRoute, private taskService: TaskService, private translationService: TranslocoService){
    // When the component is loaded trough an URL including the uid param in the route, we should read it
    this.$subscription1 = this.route.params.subscribe((params) => {
      if (params['uid']) {
        // If uid is present in the route and it isn't equal to the current uid
        if (this.taskId != parseInt(params['uid'])) {
          // Set this as the current uid
          this.taskId = parseInt(params['uid']);
        }
      }
    });
  }


  ngOnInit(): void {
    this.loadTask();
    this.loadTabTranslations();
  }

  loadTask(){
    this.$subscription2 = this.taskService.getTask(this.taskId).subscribe(response => {
      if(response && response.result){
        this.taskLoaded = true;
        this.task = response.result
        this.comments = Object.keys(this.task.Comments).map(key => (this.task.Comments[key]));
      }
    })
  }

  loadTabTranslations(){
    this.$subscription3 = this.translationService.selectTranslate('tasks.tabGeneral').subscribe(res => {
      // Get the translation values for the tabs
      let tabs = [
        this.translationService.translate('tasks.tabGeneral'),
        this.translationService.translate('tasks.tabComments'),
        this.translationService.translate('tasks.tabDossier')
      ];
      // Set the new renderBullet function for the new translations
      this.config.pagination = {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + tabs[index] + '</span>';
        },
      };
    });
  }

  ngOnDestroy(): void {
    if(this.$subscription1){
      this.$subscription1.unsubscribe();
    }
    if(this.$subscription2){
      this.$subscription2.unsubscribe();
    }
    if(this.$subscription3){
      this.$subscription3.unsubscribe();
    }
  }
}
