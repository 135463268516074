import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class TaskService {
  constructor(
    private http: HttpClient
  ) {}

  getTask(uid:number) {

    // Define the OWS method
    const requestMethod = 'Otys.Services.TaskService.getDetail';
    // Define the body of the request (params)
    const requestBody:any = [
      uid,
      {
        uid: 1,
        isFinished: 1,
        title: 1,
        description: 1,
        ownerId: 1,
        owner: 1,
        managerId: 1,
        manager: 1,
        assignedToId: 1,
        assignedTo: 1,
        createdById: 1,
        createdBy: 1,
        modifiedById: 1,
        modifiedBy: 1,
        created: 1,
        lastModified: 1,
        taskGroupId: 1,
        taskGroup: 1,
        taskGroupAbbrev: 1,
        userGroupId: 1,
        userGroup: 1,
        userGroupAbbrev: 1,
        start: 1,
        deadline: 1,
        completed: 1,
        activation: 1,
        status: 1,
        statusMerged: 1,
        userStatusId: 1,
        userStatus: 1,
        userStatusColor: 1,
        priority: 1,
        priorityMerged: 1,
        userPriorityId: 1,
        userPriority: 1,
        userPriorityColor: 1,
        typeId: 1,
        type: 1,
        typeAbbrev: 1,
        typeColor: 1,
        typeHelperTemplateId: 1,
        typeHelperTemplateMandatory: 1,
        isPrivate: 1,
        notifyOwnerAssigned: 1,
        notifyManagerAssigned: 1,
        notifyUserGroup: 1,
        allowAssigneeToEdit: 1,
        allowAssigneeToReloc: 1,
        allowUserGroupToEdit: 1,
        progress: 1,
        timeRequired: 1,
        estimateDeveloper: 1,
        timeTaken: 1,
        price: 1,
        totalPrice: 1,
        tariff: 1,
        ownerChecked: 1,
        trackAndTrace: 1,
        managerChecked: 1,
        active: 1,
        linkedCandidateUid: 1,
        linkedVacancyUid: 1,
        linkedContactPersonUid: 1,
        linkedRelationUid: 1,
        linkedUserId: 1,
        billable: 1,
        flowId: 1,
        flowRuleLockId: 1,
        flowName: 1,
        flowAlreadyRun: 1,
        orderId: 1,
        invoiceId: 1,
        trajectoryId: 1,
        productId: 1,
        originUid: 1,
        lastComment: 1,
        appointment: 1,
        tag: 1,
        Comments: {
          uid: 1,
          userId: 1,
          user: 1,
          relationContactUid: 1,
          datetime: 1,
          comment: 1,
          format: 1,
          from: 1
        },
        Bindings: {
          entityId: 1,
          entity: 1,
          uid: 1,
          title: 1
        }
      },
      null
    ];

    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'false'
      },
    };

    // Retrieve live data
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }



}
