import { environment } from 'src/environments/environment';
import { LoaderService } from './../../core/services/loader.service';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { User } from 'src/app/core/auth/user.model';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';

interface list {
  listType: string;
  listItems: any;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})

export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('keywordsInput') keywordsInput: ElementRef;
  version:string = environment.version;
  current_user: User;
  searchOverlay: boolean = false;
  searchOverlayStr: string = 'false';
  online: boolean = true;
  httpRequestLoading: boolean = false;
  // Timer reference so we can reset it
  typingTimer: any;
  // When the user doesnt type for X ms we emit the event that the input changed
  typingInterval: number = 350;
  // Define subscription
  $searchResultsSubscription: Subscription;
  // List items
  list: list = {
    listType: '',
    listItems: null,
  };

  @Input() showSearchBar: boolean = true;
  @Input() title: string;
  @Input() searchPlaceHolder: string = 'Search';
  @Input() additionalSection: any;
  @Input() listType: string = '';
  @Input() searchResults: Observable<any> = new Subject<any>();
  @Input() showLoading: boolean = true;
  @Input() hideHeader: boolean = false;
  @Input() showVersion: boolean = false;

  $subscription1: Subscription;
  $subscription2: Subscription;
  $subscription3: Subscription;

  @Output() onSearchInput: EventEmitter<string> = new EventEmitter();

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private loaderService: LoaderService
  ) {
    // Update the Loading variable so we can show a loader icon
    this.$subscription3 = this.loaderService.isLoading.subscribe((data) => {
      this.httpRequestLoading = data;
    });

    this.$subscription1 = this.authService.user.subscribe((user) => {
      this.current_user = user;
    });

    this.$subscription2 = this.route.queryParams.subscribe((queryParams) => {
      this.searchOverlay = queryParams['search-active'] === 'true';
      if (!this.searchOverlay) {
        this.searchOverlayStr = 'true';
      } else {
        this.searchOverlayStr = 'false';
        //There needs to be a timeout because it takes a bit before we can access the element
        setTimeout(() => {
          this.keywordsInput.nativeElement.focus();
        }, 100);
      }

    });
  }


  ngOnInit(): void {
    this.online = navigator.onLine;

    fromEvent(window, 'online').subscribe((data) => {
      if (data.type == 'online') {
        this.online = true;
      }
    });
    fromEvent(window, 'offline').subscribe((data) => {
      if (data.type == 'offline') {
        this.online = false;
      }
    });
    this.watchSearchResults();
  }

  watchSearchResults(){
    this.$searchResultsSubscription = this.searchResults.subscribe(
      (results) => {
        if (results) {
          this.list.listItems = {
            page: 1,
            items: results.items,
          };
          this.list.listType = this.listType;
        }
      }
    );
  }

  onKeyUp() {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(this.onInputChangeEvent, this.typingInterval);
  }

  onClearInput() {
    this.keywordsInput.nativeElement.value = '';
    this.onInputChangeEvent();
  }

  onEnter(){
    if(this.keywordsInput.nativeElement.value.toLowerCase().slice(0, 3) == 'td ' && this.keywordsInput.nativeElement.value.length > 3 && !isNaN(this.keywordsInput.nativeElement.value.slice(3, 9000)) && parseInt(this.keywordsInput.nativeElement.value.slice(3, 9000))  < 1000000 && parseInt(this.keywordsInput.nativeElement.value.slice(3, 9000))  > 100000){
      let trailingString = this.keywordsInput.nativeElement.value.slice(3, 9000);
      let taskId = parseInt(trailingString);
      this.router.navigate(['/tasks/' + taskId]);
    }else{
      this.onSearchInput.emit(this.keywordsInput.nativeElement.value);
    }
  }

  onInputChangeEvent = () => {
    this.onSearchInput.emit(this.keywordsInput.nativeElement.value);
  };

  ngOnDestroy(): void {
    if (this.$searchResultsSubscription) {
      this.$searchResultsSubscription.unsubscribe();
    }
    if (this.$subscription1) {
      this.$subscription1.unsubscribe();
    }
    if (this.$subscription2) {
      this.$subscription2.unsubscribe();
    }
    if (this.$subscription3) {
      this.$subscription3.unsubscribe();
    }
  }
}
