import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`https://translations.otysonthego.nl/translations/${lang}.json` + "?version=" + environment.version);
  }
}

@NgModule({
  exports: [ TranslocoModule ],
  imports: [],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: [
          "en",
          "nl",
          "de",
          "fr",
          "cs",
          "es",
          "us",
          "pl",
          "ro",
          "ru",
          "sk",
          "tr",
          "uk"
        ],
        defaultLang: 'en',
        fallbackLang: ['nl', 'de', 'fr'],
        failedRetries: 15,
        missingHandler: {
          useFallbackTranslation: true
        },
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: false
      })
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }
  ]
})
export class TranslocoRootModule {}
