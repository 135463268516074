import { Component, Input } from '@angular/core';
import { vacanciesPage } from 'src/app/core/interfaces/vacancies.interface';

@Component({
  selector: 'app-vacancies-list',
  templateUrl: './vacancies-list.component.html',
  styleUrls: ['./vacancies-list.component.sass']
})
export class VacanciesListComponent{

  @Input() vacanciesListing:vacanciesPage[] = [];

  constructor() { }

}
