<div class="dossier">
  <div class="dossier-tabs">
    <div class="dossier-tab" *ngFor="let dossierTab of dossierTabs; let i = index" (click)="onClickTab(i)">
      <span [ngClass]="{'active' : dossierTab.active}">
        {{dossierTab.name | capitalize}}
      </span>
    </div>
  </div>
  <div class="dossier-tab-contents-wrap" *transloco="let t; read: 'shared'">
    <div class="dossier-tab-content" *ngFor="let dossierTab of dossierTabs; let i = index">
      <div *ngIf="activeTab == i">

        <img src="/assets/images/spinner.svg" width="30" height="30" class="loading_spinner" *ngIf="dossierTab.loading && dossierTab.pages.length === 0 && dossierTab.hasAnyResults !== false">

        <div class="tab-page" *ngFor="let dossierTabPage of dossierTab.pages">

          <div class="documents-list" *ngIf="dossierTab.classes.includes('Emails')">
            <a class="list-item list-item-mail" *ngFor="let dossierItem of dossierTabPage.items;let i = index;" [routerLink]="['/emails/' + dossierItem.id_rec]">
              <div class="list-image">
                <!--When it isnt in sent items its from someone-->
                <span *ngIf="dossierItem.pint5 != '-2'">
                  {{ dossierItem.from | getInitials}}
                </span>

                <!-- When it is sent its in the sent items folder going out so we use pvar 2 as receiver-->
                <span *ngIf="dossierItem.pint5 == '-2'">
                  {{ dossierItem.pvar1 | getInitials}}
                </span>
              </div>
              <div class="list-item-content">
                <div class="list-item-content-left">
                  <div class="list-item-title medium" *ngIf="dossierItem.pint5 == '-2'">
                    <span *ngIf="dossierItem.pvar9">{{ dossierItem.pvar9}}</span>
                    <span *ngIf="!dossierItem.pvar9">{{ dossierItem.pvar1}}</span>
                  </div>
                  <div class="list-item-title medium" *ngIf="dossierItem.pint5 != '-2'">
                    {{ dossierItem.from}}
                  </div>
                  <div class="list-item-subtitle">
                    {{ dossierItem.subject }}
                  </div>
                </div>
                <div class="list-item-content-right">
                  <div class="top">
                    <span class="list-item-date">{{ dossierItem.dt | customDate }}</span>
                  </div>
                  <div class="bottom">
                    <i class="fa-solid fa-paper-plane-top plane-incoming" *ngIf="dossierItem.pint5 != '-2'"></i>
                    <i class="fa-solid fa-paper-plane-top plane-outgoing" *ngIf="dossierItem.pint5 == '-2'"></i>
                  </div>
                </div>
              </div>
            </a>
          </div>


          <div class="documents-list" *ngIf="dossierTab.classes.includes('AttachedDocs')">
            <ng-container *ngFor="let dossierItem of dossierTabPage.items;let i = index;">
              <a class="list-item list-item-document" [href]="this.downloadLinkBase + '?ouid='+ dossierItem.id_rec + '&class=AttachedDocument&mode=0&sessionId=' + current_user.sessionId" *ngIf="dossierItem.class != 'CV'">
                <div class="list-image">
                  <i class="fa-solid fa-file" *ngIf="dossierItem.alwaysOnTop != 1"></i>
                  <i class="fa-solid fa-thumbtack" *ngIf="dossierItem.alwaysOnTop == 1"></i>
                </div>
                <div class="list-item-content">
                  <div class="list-item-content-left">
                    <div class="list-item-title medium">
                      {{ dossierItem.subject}}
                    </div>
                    <div class="list-item-subtitle">
                      {{ dossierItem.pvar2 }}
                    </div>
                  </div>
                  <div class="list-item-content-right">
                    <div class="top">
                      <span class="list-item-date">{{ dossierItem.dt | customDate }}</span>
                    </div>
                    <div class="bottom">
                    </div>
                  </div>
                </div>
              </a>
              <a class="list-item list-item-document" [href]="this.downloadLinkBase + '?ouid='+ entityUid + '&class=CandidateCv&mode=0&sessionId=' + current_user.sessionId"  *ngIf="dossierItem.class == 'CV'">
                <div class="list-image">
                  <i class="fa-solid fa-file" *ngIf="dossierItem.alwaysOnTop != 1"></i>
                  <i class="fa-solid fa-thumbtack" *ngIf="dossierItem.alwaysOnTop == 1"></i>
                </div>
                <div class="list-item-content">
                  <div class="list-item-content-left">
                    <div class="list-item-title medium">
                      {{ dossierItem.subject}}
                    </div>
                    <div class="list-item-subtitle">
                      {{ dossierItem.pvar2 }}
                    </div>
                  </div>
                  <div class="list-item-content-right">
                    <div class="top">
                      <span class="list-item-date">{{ dossierItem.dt | customDate }}</span>
                    </div>
                    <div class="bottom">
                    </div>
                  </div>
                </div>
              </a>
            </ng-container>
          </div>


          <div class="documents-list" *ngIf="dossierTab.classes.includes('AgendaItem')">
            <a class="list-item list-item-calendar" *ngFor="let dossierItem of dossierTabPage.items;let i = index;" [routerLink]="['/calendar/compose/' + dossierItem.id_rec]">
              <div class="list-image">
                <i class="fa-solid fa-calendar" *ngIf="dossierItem.alwaysOnTop != 1"></i>
                <i class="fa-solid fa-thumbtack" *ngIf="dossierItem.alwaysOnTop == 1"></i>
              </div>
              <div class="list-item-content">
                <div class="list-item-content-left">
                  <div class="list-item-title medium">
                    {{ dossierItem.subject}}
                  </div>
                  <div class="list-item-subtitle">
                    {{ dossierItem.from }}
                  </div>
                </div>
                <div class="list-item-content-right">
                  <div class="top">
                    <span class="list-item-date">{{ dossierItem.dt | customDate }}</span>
                  </div>
                  <div class="bottom">
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div class="documents-list" *ngIf="dossierTab.classes.includes('Notes')">
            <a class="list-item list-item-note" *ngFor="let dossierItem of dossierTabPage.items;let i = index;" [routerLink]="['/dossier/note/' + dossierItem.id_rec]">
              <div class="list-image">
                <i class="fa-solid fa-note" *ngIf="dossierItem.alwaysOnTop != 1"></i>
                <i class="fa-solid fa-thumbtack" *ngIf="dossierItem.alwaysOnTop == 1"></i>
              </div>
              <div class="list-item-content">
                <div class="list-item-content-left">
                  <div class="list-item-title medium">
                    {{ dossierItem.subject}}
                  </div>
                  <div class="list-item-subtitle">
                    {{ dossierItem.from }}
                  </div>
                </div>
                <div class="list-item-content-right">
                  <div class="top">
                    <span class="list-item-date">{{ dossierItem.dt | customDate }}</span>
                  </div>
                  <div class="bottom">
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div class="documents-list" *ngIf="dossierTab.classes.includes('Reminders')">
            <a class="list-item list-item-reminder" *ngFor="let dossierItem of dossierTabPage.items;let i = index;" [routerLink]="['/reminders/' + dossierItem.id_rec]">
              <div class="list-image">
                <i class="fa-solid fa-bell" *ngIf="dossierItem.alwaysOnTop != 1"></i>
                <i class="fa-solid fa-thumbtack" *ngIf="dossierItem.alwaysOnTop == 1"></i>
              </div>
              <div class="list-item-content">
                <div class="list-item-content-left">
                  <div class="list-item-title medium">
                    {{ dossierItem.subject}}
                  </div>
                  <div class="list-item-subtitle">
                    {{ dossierItem.from }}
                  </div>
                </div>
                <div class="list-item-content-right">
                  <div class="top">
                    <span class="list-item-date">{{ dossierItem.dt | customDate }}</span>
                  </div>
                  <div class="bottom">
                  </div>
                </div>
              </div>
            </a>
          </div>

        </div>


        <div class="no-results space-top" *ngIf="dossierTab.hasAnyResults === false">
          {{t('noResults')}}
        </div>
        <img src="/assets/images/spinner.svg" width="30" height="30" class="loading_spinner" *ngIf="dossierTab.loading && dossierTab.pages.length > 0 && dossierTab.hasAnyResults !== false">
        <app-load-more-button [showLoadMoreButton]="dossierTab.loading == false && dossierTab.pages.length > 0 && dossierTab.pages[dossierTab.pages.length - 1].items.length > 49" (buttonClicked)="onNextTab(i);"></app-load-more-button>


        <app-action-button-single [icon]="actionIcon" (onClick)="onAddDocument()" *ngIf="activeClasses.includes('AttachedDocs')"></app-action-button-single>
        <app-action-button-single [icon]="noteIcon" (onClick)="onAddNote()" *ngIf="activeClasses.includes('Notes')"></app-action-button-single>
      </div>
    </div>
  </div>

</div>
