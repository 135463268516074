<header [ngClass]="{'loading': httpRequestLoading == true && online == true && showLoading == true,'batchmode': hideHeader}">
  <div class="header-top">
    <h1>{{ title }}</h1>
    <div class="offline-message" *ngIf="online == false">
      <span *transloco="let t; read: 'shared'">{{t('userOfflineMessage')}}</span>
      <i class="fa-solid fa-wifi-slash"></i>
    </div>
    <div class="development-message" *ngIf="showVersion">
      <span>
        v{{version}}
      </span>
    </div>
  </div>
  <div class="header-searhbar" *ngIf="showSearchBar">
    <div class="search-icon">
      <i class="fa-solid fa-magnifying-glass"></i>
    </div>
    <a class="fake-input" [routerLink]="[]" [queryParams]="{ 'search-active': searchOverlayStr}">{{searchPlaceHolder}}</a>
    <app-profile-picture></app-profile-picture>
  </div>

  <div class="header-additional">
    <ng-container *ngTemplateOutlet="additionalSection"></ng-container>
  </div>
</header>

<div class="search-overlay" *ngIf="searchOverlay && showSearchBar">
  <div class="search-overlay-header" [ngClass]="{'loading': httpRequestLoading == true && online == true}">
    <div class="searchbar-wrap" *transloco="let t; read: 'shared'">
      <a class="search-back-icon" [routerLink]="[]">
        <i class="fa-solid fa-chevron-left"></i>
      </a>
      <input type="text" [placeholder]="t('keywords')" (keyup)="onKeyUp()" (keyup.enter)="onEnter()" name="keywordinput" ngModel #keywordsInput>
      <div class="search-clear-icon" (click)="onClearInput()">
        <i class="fa-solid fa-xmark"></i>
      </div>
    </div>
  </div>

  <div class="search-results-wrap">
    <app-search-result-list [listItems]="list.listItems" [listType]="list.listType"></app-search-result-list>
  </div>
</div>
