import { TranslocoService } from '@ngneat/transloco';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {BehaviorSubject, map} from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from './user.model';
import { MatomoTracker } from '@ngx-matomo/tracker';


@Injectable()
export class AuthService {

  // Create a subject people can subscribe to
  user = new BehaviorSubject<User | null>(null);
  // Something we can use
  current_user:User;

  // CheckResponse for totp
  checkResponse:any = {};

  constructor(private http: HttpClient,private router: Router, private translationService: TranslocoService, private readonly tracker: MatomoTracker) {
    // Update the current user variable
    this.user.subscribe(userData => {
      this.current_user = userData;
    })
  }

  setUser(userData:User){
    const loadedUser = new User(
      userData.canUseApp,
      userData.username,
      userData.id,
      userData.clientId,
      userData.client,
      userData.email,
      userData.keyUser,
      userData.defaultContentLanguage,
      userData.defaultCountry,
      userData.contentLanguages,
      userData.firstName,
      userData.infix,
      userData.lastName,
      userData.photoFileName,
      userData.modules,
      userData.userSettings,
      userData.languageSet,
      userData.sessionId
    );
    this.user.next(loadedUser);
    localStorage.setItem('user', JSON.stringify(loadedUser));
  }

  checkTunix(username: string, password: string) {
    return this.http
      .post<any>(
        environment.owsUrl, [username, password],
        {
          headers: {
            'ows-method' : 'getTunixIsActiveValue',
          },
        }
      )
  }

  setCheckResponse($respobj){
    this.checkResponse = $respobj;
  }

  checkTotp(sessionId) {
    return this.http
    .post<any>(
      environment.owsUrl, [sessionId],
      {
        headers: {
          'ows-method' : 'check',
        },
      }
    );
  }

  checkPrefix(prefix:string){
    return this.http
      .post<any>(
        environment.owsUrl,
        [
          "",
          prefix,
          "https://www.otysonthego.nl/verifyToken"
        ],
        {
          headers: {
            'ows-method' : 'Otys.Services.LoginOptionsService.getLoginOptions',
          },
        }
      )
  }

  checkTotpCode(code:string){
    return this.http
      .post<any>(
        environment.owsUrl,
        [
          this.checkResponse,
          code
        ],
        {
          headers: {
            'ows-method' : 'checkTotpcode',
          },
        }
      )
  }

  verifyToken(ssoToken:string){
    return this.http
      .post<any>(
        environment.owsUrl,
        [
          '',
          ssoToken
        ],
        {
          headers: {
            'ows-method' : 'Otys.Services.LoginOptionsService.getAzuretokenStatus',
          },
        }
      )
  }

  loginBySessionId(sessionId:string, skipTotp:boolean = false){

    if(skipTotp){
      this.tracker.trackGoal(17);
    }

    const user = new User(
      null,
      'unknown',
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      false,
      null,
      sessionId
    );
    this.user.next(user);

    // Save the user in local storage
    localStorage.setItem('user', JSON.stringify(user));

    // When we are logged in we can start retrieving more data about this user
    this.updateUser(skipTotp);
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(
        environment.owsUrl, [username, password, null, null, 'otysmobile'],
        {
          headers: {
            'ows-method' : 'login',
          },
        }
      )
      .pipe(
        map((resData:any) => {
          // Create a new (mostly empty) user
          const user = new User(
            null,
            username,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            [],
            false,
            null,
            resData.result
          );
          this.user.next(user);

          // Save the user in local storage
          localStorage.setItem('user', JSON.stringify(user));

          // When we are logged in we can start retrieving more data about this user
          this.updateUser();

          return resData;
        })
      );
  }

  setUserSettings(newSettings:any){
    let newUser = new User(
      this.current_user.canUseApp,
      this.current_user.username,
      this.current_user.id,
      this.current_user.clientId,
      this.current_user.client,
      this.current_user.email,
      this.current_user.keyUser,
      this.current_user.defaultContentLanguage,
      this.current_user.defaultCountry,
      this.current_user.contentLanguages,
      this.current_user.firstName,
      this.current_user.infix,
      this.current_user.lastName,
      this.current_user.photoFileName,
      this.current_user.modules,
      newSettings,
      this.current_user.languageSet,
      this.current_user.sessionId
    );
    this.setUser(newUser);
  }

  checkIfSessionIdIsValid(){
    // Define the OWS method
    const requestMethod = 'check';
    // Define the body of the request (params)
    const requestBody: any[] = [];

    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod
      },
    };
    // Only get live data of this user when theres no cache
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

  updateUser(skipcheck = false){
    // Define the OWS method
    const requestMethod = 'check';
    // Define the body of the request (params)
    const requestBody: any[] = [];

    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod
      },
    };
    // Only get live data of this user when theres no cache
    this.http.post<any>(environment.owsUrl, requestBody, requestHeaders).subscribe( resData => {
      if(resData && resData.result !== false){
        if(resData.result.totpActive === true && skipcheck == false){
          let newUser = new User(
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            this.current_user.sessionId
          );
          this.setUser(newUser);
        }else{
          let newUser = new User(
            null,
            resData.result.username,
            resData.result.id,
            resData.result.clientId,
            resData.result.client,
            resData.result.email,
            resData.result.keyUser,
            null,
            resData.result.defaultCountry,
            resData.result.contentLanguages,
            this.current_user.firstName,
            this.current_user.infix,
            this.current_user.lastName,
            this.current_user.photoFileName,
            this.current_user.modules,
            this.current_user.userSettings,
            this.current_user.languageSet,
            this.current_user.sessionId
          );
          this.setUser(newUser);
          this.getAllUserData();
        }
      }
    });
  }

  getAllUserData(){
    // Define the OWS method
    const requestMethod = 'Otys.Services.MultiService.execute';
    // Define the body of the request (params)
    const requestBody: any[] = [
      [
        {
          method : "Otys.Services.UserService.getDetail",
          args : [
            this.current_user.sessionId,
            this.current_user.id,
            {
              Person: {
                title: 1,
                firstName: 1,
                infix: 1,
                lastName: 1,
                photoFileName: 1
              }
            }
          ]
        },
        {
          method : "Otys.Services.SettingsService.get",
          args : [
            this.current_user.sessionId,
            {
                ote: [
                    "locale"
                ]
            }
          ]
        },
        {
          method : "Otys.Services.PhoenixModuleService.get",
          args : [
            this.current_user.sessionId
          ]
        },
        {
          method : "Otys.Services.CsmService.getValue",
          args : [
            this.current_user.sessionId,
            "PA81",
            this.current_user.clientId,
            this.current_user.id,
            0
          ]
        },
        {
          method : "Otys.Services.CsmService.getValue",
          args : [
            this.current_user.sessionId,
            "PA79",
            this.current_user.clientId,
            this.current_user.id,
            0
          ]
        }
      ]
    ];

    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod
      },
    };

    // Only get live data of this user when theres no cache
    this.http.post<any>(environment.owsUrl, requestBody, requestHeaders).subscribe( resData => {

      let userData = resData.result[0].data;
      var locale = resData.result[1].data.ote.locale;
      let modules = resData.result[2].data;
      let partnerSetting = resData.result[3];
      let languageSet = resData.result[4];
      var overwriteLocale = null;

      if(partnerSetting.status && partnerSetting.status == 'error'){
        var canUseApp = false;
        var userSettings:any = [];
      }else{
        var canUseApp = true;
        var userSettings:any = JSON.parse(partnerSetting.data.value);
      }

      if(userSettings && userSettings.values['06language'] && userSettings.values['06language'].value != 0){
        // User has configured something else then their Go! language
        let settingVal = userSettings.values['06language'].value;
        let langCode = userSettings.values['06language'].answers[settingVal];
        var overwriteLocale = langCode;
      }

      if(partnerSetting.status && partnerSetting.status == 'error'){
        var langSet:number = 0;
      }else{
        var langSet:number = languageSet.data.value;
      }

      if(overwriteLocale){
        if(langSet !== 0){
          this.translationService.setActiveLang(langSet + '_' + overwriteLocale);

          localStorage.setItem('lastLanguage', overwriteLocale);
          localStorage.setItem('lastLanguageSet', langSet.toString());
        }else{
          this.translationService.setActiveLang(overwriteLocale);

          localStorage.setItem('lastLanguage', overwriteLocale);
        }
      }else{
        if(langSet !== 0){
          this.translationService.setActiveLang(langSet + '_' + locale);

          localStorage.setItem('lastLanguage', locale);
          localStorage.setItem('lastLanguageSet', langSet.toString());
        }else{
          this.translationService.setActiveLang(locale);

          localStorage.setItem('lastLanguage', locale);
        }
      }

      let newUser = new User(
        canUseApp,
        this.current_user.username,
        this.current_user.id,
        this.current_user.clientId,
        this.current_user.client,
        this.current_user.email,
        this.current_user.keyUser,
        locale,
        this.current_user.defaultCountry,
        this.current_user.contentLanguages,
        userData.Person.firstName,
        userData.Person.infix,
        userData.Person.lastName,
        userData.Person.photoFileName,
        modules,
        userSettings,
        langSet,
        this.current_user.sessionId
      );
      this.setUser(newUser);
      this.logUserData();
    });

  }


  autoLogin() {
    const userString: any = localStorage.getItem('user');
    const userData:any = JSON.parse(userString);
    if (!userData) {
      return;
    }
    if(userData.canUseApp !== true){
      return;
    }


    if(userData.userSettings && userData.userSettings.values['06language'] && userData.userSettings.values['06language'].value != 0){
      // User has configured something else then their Go! language
      let settingVal = userData.userSettings.values['06language'].value;
      let langCode = userData.userSettings.values['06language'].answers[settingVal];
      var overwriteLocale = langCode;
    }


    if(overwriteLocale){
      if(userData.languageSet !== 0){
        this.translationService.setActiveLang(userData.languageSet + '_' + overwriteLocale);

        localStorage.setItem('lastLanguage', overwriteLocale);
        localStorage.setItem('lastLanguageSet', userData.languageSet.toString());
      }else{
        this.translationService.setActiveLang(overwriteLocale);

        localStorage.setItem('lastLanguage', overwriteLocale);
      }
    }else{
      if(userData.languageSet !== 0){
        this.translationService.setActiveLang(userData.languageSet + '_' + userData.defaultContentLanguage);

        localStorage.setItem('lastLanguage', userData.defaultContentLanguage);
        localStorage.setItem('lastLanguageSet', userData.languageSet.toString());
      }else{
        this.translationService.setActiveLang(userData.defaultContentLanguage);

        localStorage.setItem('lastLanguage', userData.defaultContentLanguage);
      }
    }

    const loadedUser = new User(
      userData.canUseApp,
      userData.username,
      userData.id,
      userData.clientId,
      userData.client,
      userData.email,
      userData.keyUser,
      userData.defaultContentLanguage,
      userData.defaultCountry,
      userData.contentLanguages,
      userData.firstName,
      userData.infix,
      userData.lastName,
      userData.photoFileName,
      userData.modules,
      userData.userSettings,
      userData.languageSet,
      userData._sessionid
    );
    this.user.next(loadedUser);

    this.logUserData();
  }

  logUserData(){
    if(environment.production){
      this.tracker.setUserId('[' + this.current_user.id.toString() + '_' + this.current_user.clientId.toString() + '] - [' + this.current_user.client + '_' + this.current_user.username + ']');
      this.tracker.setCustomDimension(2, environment.version);
    }else{
      this.tracker.optUserOut()
    }
  }

  resetUserData(){
    if(environment.production){
      this.tracker.resetUserId();
    }else{
      this.tracker.optUserOut()
    }
  }

  onLogOutOWs(){
    // Define the OWS method
    const requestMethod = 'logout';
    // Define the body of the request (params)
    const requestBody: any[] = [];

    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod
      },
    };
    // Only get live data of this user when theres no cache
    this.http.post<any>(environment.owsUrl, requestBody, requestHeaders).subscribe(res => {
    })
  }

  logout(){
    this.onLogOutOWs();
    // Remove the localstorage for this user
    localStorage.removeItem('user');
    // Update the user observable
    const emptUser = new User(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      false,
      null,
      null
    );
    this.user.next(emptUser);
    this.resetUserData();
    this.router.navigate(['/login']);
  }
}
