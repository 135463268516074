import { Injector } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { LoaderService } from './loader.service';
import { CachingService } from './caching.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpResponse } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  finalize,
  map,
  Observable,
  of,
  switchMap,
  tap,
  throwError
} from 'rxjs';
import { AlertService } from './alert.service';
import {AuthKeysService} from "../auth/auth-keys.service";
import {User} from "../auth/user.model";

@Injectable()
export class InterceptorService implements HttpInterceptor {

  current_requests:any = [];
  offline:boolean = false;

  private sessionId$ = new BehaviorSubject<string | null>(null)

  constructor(
    private readonly injector: Injector,
    private authService: AuthService,
    private loaderService: LoaderService,
    private alertService: AlertService,
    private authKeys: AuthKeysService
  ){
    this.authService.user.pipe(
      map(u => u?.sessionId)
    ).subscribe(this.sessionId$)
  }

  updateLoaderService(methodArray:string[]){
    this.loaderService.loadingMethods = methodArray;
  }

  private wrapRequest(req: HttpRequest<any>){
    if(req.method == 'POST' && req.headers.get('ows-method')){

      // Lets wrap the body inside an object OWS understands
      req = req.clone({
        headers: req.headers,
        body: {
          jsonrpc: "2.0",
          method: req.headers.get('ows-method'),
          params: req.body,
          id: 1
        }
      });

      // Only add session id when the user is logged in
      if(
        req.headers.get('ows-method') != 'login' &&
        req.headers.get('ows-method') != 'Otys.Services.MultiService.execute' &&
        req.headers.get('ows-method') != 'getTunixIsActiveValue' &&
        req.headers.get('ows-method') != 'checkTotpcode' &&
        req.headers.get('ows-method') != 'Otys.Services.LoginOptionsService.getLoginOptions' &&
        req.headers.get('ows-method') != 'Otys.Services.LoginOptionsService.getAzuretokenStatus' &&
        req.headers.get('ows-method') != 'Otys.Services.AuthKeysService.restoreSession'
      ){
        // Add the SESSION id to the params array when logged in
        if(this.sessionId$.getValue()){
          req.body.params.unshift(this.sessionId$.getValue())
        }

      }
    }

    return req
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    if(!req.url.includes('fileService')){
      // Now we know this is an POST with an OWS service and is called to the API url
      var backupReqBody = structuredClone(req.body)
    }else{
        // Now we know this is an POST without an OWS service and is called to the API url
      var backupReqBody = req.body;
    }


    req = this.wrapRequest(req)
    // Check for an already active request
    if(this.current_requests.includes(req.headers.get('ows-method'))){
      // Return empty to cancel the request
      //return EMPTY;
    }else{
      this.current_requests.push(req.headers.get('ows-method'));
    }

    this.loaderService.loadingMethods = this.current_requests;

    return next.handle(req).pipe(
      switchMap(e => {
        if(!(e instanceof HttpResponse)){
          return of(e)
        } else {
          return of(e).pipe(
            // Auth check
            tap(resp => {
              if(
                resp.body.hasOwnProperty('error') && resp.body.error.message == "Access denied; please login again"
                || resp.body.hasOwnProperty('error') && resp.body.error.message == "Access denied; please login first"
              ){
                throw new Error("unauthorised")
              }
            }),
            // Caching
            tap(event => {
              if(req.headers.get('ows-cachable') == 'true'){
                const cachingService = this.injector.get(CachingService);
                const unique = req.body.params;
                unique.shift();
                const cacheKey = cachingService.createCacheKey(req.headers.get('ows-method'), unique);
                if(req.headers.get('ows-cachettl')){
                  let ttlHours = parseInt(req.headers.get('ows-cachettl'));
                  cachingService.createCache(cacheKey,event.body, ttlHours)
                }else{
                  cachingService.createCache(cacheKey,event.body)
                }
              }
            })
          )
        }
      }),
      catchError(error => {
        if(error instanceof Error && error.message === 'unauthorised'){
          const performLogout = () => {
            const u = this.authService.user.getValue()
            if (u instanceof User && u.id !== null){
              const translateService = this.injector.get(TranslocoService);

              this.alertService.error(translateService.translate('shared.sessionExpiredMessage'));
              this.authService.logout();
            }
          }
          if(this.authKeys.canRestoreSession()){
            return this.authKeys.restoreSession$().pipe(
              catchError(() => {
                performLogout()
                return EMPTY
              }),
              switchMap(() => {
                return next.handle(this.wrapRequest(req.clone({body: backupReqBody})))
              }),
            )
          } else {
            performLogout()

            return EMPTY
          }
        }
        return throwError(error);
       }),
      finalize(() => {
        const index = this.current_requests.indexOf(req.headers.get('ows-method'));
        if (index > -1) {
          this.current_requests.splice(index, 1);
        }
        this.loaderService.loadingMethods = this.current_requests;
      })
    )
  }
}
