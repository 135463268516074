<header>
  <div class="header-wrap" *ngIf="mailLoaded">
    <div class="left-side">
      <app-back-button></app-back-button>
      <h1 *ngIf="mailDetail.subject">
        <i
          class="fa-solid fa-exclamation"
          *ngIf="mailDetail.priorityId == 1"
        ></i>
        {{ mailDetail.subject }}
        <span class="folder">{{ mailDetail.folder }}</span>
      </h1>
      <h1 *ngIf="!mailDetail.subject && mailDetail.From.name">
        <i
          class="fa-solid fa-exclamation"
          *ngIf="mailDetail.priorityId == 1"
        ></i>
        {{ mailDetail.From.name }}
        <span class="folder">{{ mailDetail.folder }}</span>
      </h1>
    </div>
    <app-email-controls
      class="right-side controls"
      [mailUid]="mailUid"
      (onMailActionTrigger)="onDrawerItemClicked($event)"
    ></app-email-controls>
  </div>
</header>

<app-email-information
  [mailDetail]="mailDetail"
  *ngIf="mailLoaded"
  (flagUpdated)="mailDetail.tag = $event"
  (showMailLight)="onShowMailInverted($event)"
></app-email-information>

<app-calendar-invite [calItem]="calItem" [mailUid]="mailUid" *ngIf="mailLoaded"></app-calendar-invite>

<div class="content" #iframe_holder [ngClass]="{'darkmode' : showContentsDark}">
  <iframe #mailbody frameborder="0" (load)="iframeLoaded()"></iframe>
</div>

<app-drawer-component [drawerOpen]="showMoveMailDrawer" [list]="moveMailToFolderList" drawerClass="moveDrawer" (drawerState)="showMoveMailDrawer = $event" (onClicked)="onMoveMailToFolder($event)"></app-drawer-component>
