<div class="phone-number-with-icons" *ngIf="phone">
  <div class="iconwrap">
    <a class="icon" href="tel:{{phone}}" target="_blank">
      <i class="fa-solid fa-phone"></i>
    </a>
    <a class="icon" href="https://wa.me/{{phone}}" target="_blank">
      <i class="fa-brands fa-whatsapp"></i>
    </a>
  </div>
  <div class="number-text">
    <a href="tel:{{phone}}" target="_blank">
      {{phone}}
    </a>
  </div>
</div>
