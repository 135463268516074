import { Router } from '@angular/router';
import { AuthService } from './../../../core/auth/auth.service';
import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.sass']
})
export class ListComponent implements OnInit, OnChanges {

  @Input() emails:any;
  @Input() showFolders:boolean = false;
  @Input() useBatch:boolean = false;
  @Output() batchUids: EventEmitter<number[]> = new EventEmitter();
  @Output() flagUpdated: EventEmitter<any> = new EventEmitter();
  environment = environment;
  current_user:any;

  updateMailFlagShow:boolean = false;
  flagState:number = -1;
  flagMailUid:number = -1;
  listItemIndex:number = -1;
  listItemPage:number = -1;

  // Only make batch actions possible after some small amount of time so that the list doesnt refresh
  batchPossibleTimeOut:ReturnType<typeof setTimeout>;
  batchPossible:boolean = false;

  batchMode:boolean = false;
  batchItems:number[] = [];
  currentMailHolded:number = 0;

  @ViewChildren("listItem") listItem: QueryList<ElementRef>;

  constructor(private authService: AuthService, private router: Router) {
    this.authService.user.subscribe(res => {
      this.current_user = res;
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    clearTimeout(this.batchPossibleTimeOut);
    this.batchPossible = false;
    this.batchPossibleTimeOut = setTimeout(() => {
      this.batchPossible = true;
    }, 600)
  }

  ngOnInit(): void {

  }



  onFlagUpdate(newFlag:number){
    this.flagUpdated.emit({flag: newFlag, mail: this.flagMailUid, index: this.listItemIndex, page: this.listItemPage});
  }

  onListItemFlagClicked(event:Event,currentFlag:number,emailUid:number,i:number,page:number){
    event.preventDefault();
    event.stopPropagation();
    this.flagState = currentFlag;
    this.flagMailUid = emailUid;
    this.updateMailFlagShow = true;
    this.listItemIndex = i;
    this.listItemPage = page;
  }


  onListImageClick(event:Event,mailUid:number, listItem:Element){
    if(this.useBatch && this.batchPossible){
      event.preventDefault();
      event.stopPropagation();

      if(!this.batchMode){
        this.onPressAndHold(mailUid, listItem);
      }else{
        this.onMailItemClick(mailUid, listItem);
      }
    }
  }

  onPressAndHold(mailUid:number, listItem:Element){
    if(this.useBatch && this.batchPossible){
      // Lets assign this mailUid to the temporary variable so we can ignore any click events fired on this one
      this.currentMailHolded = mailUid;
      // Lets give it an batch class
      listItem.classList.add('batch');
      // Lets add it to the array of items in bbatch
      this.batchItems.push(mailUid);
      // Lets enable batch mdoe
      this.batchMode = true;
      // Update the component output
      this.onBatchChange();
    }
  }

  onMailItemClick(mailUid:number, listItem:Element){
    // When batch mode is on we don't navigate to the email detail page but we add/remove the item
    if(this.batchMode == true){
      // Check if this one is already selected
      if(this.batchItems.indexOf(mailUid) > -1){
        // It's already in the array so lets remove it then
        // Remove the class of the elemnt
        listItem.classList.remove('batch');
        // Remove the mail uid from the array
        this.batchItems = this.batchItems.filter(function(value, index, arr){
          return value != mailUid;
        });
        // Disable batch mode if this was the last one
        if(this.batchItems.length < 1){
          this.batchMode = false;
        }
      }else{
        // Lets add it tot the array
        // Add the batch class
        listItem.classList.add('batch');
        // Push it to the array
        this.batchItems.push(mailUid);
      }
      // Update the component output
      this.onBatchChange();
    }else{
      // If we are now holding this item with press and hold we can ignore the click event
      if(mailUid != this.currentMailHolded){
        // The mail item was clicked with batch mode disabled so we can just go the the page
        this.router.navigate(['/emails/' + mailUid])
      }
    }
    // Lets reset the batch temp variable
    this.currentMailHolded = 0;
  }

  onBatchChange(){
    const canVibrate = window.navigator.vibrate;
    if (canVibrate){
      window.navigator.vibrate(1);
    }
    this.batchUids.emit(this.batchItems);
  }

  onResetBatch(){
    this.batchItems = [];
    this.batchMode = false;
    this.listItem.forEach(listI => {
      if(listI.nativeElement.classList.contains('batch')){
        listI.nativeElement.classList.remove('batch');
      }
    });
    this.onBatchChange();
  }

}
