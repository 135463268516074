import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { User } from '../auth/user.model';

@Injectable()
export class FileUploadService{

  current_user:User;

  constructor(private http: HttpClient,private authService: AuthService){
    this.authService.user.subscribe(res => {
      this.current_user = res;
    })
  }

   uploadFile(file:File){
    // Return the mailWidget as an observable so other components can listen to any updates
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('sessionId', this.current_user.sessionId);
    return this.http.post<any>(environment.fileServiceUpload, formData);
  }


}
