import { Subject, Subscription } from 'rxjs';
import { AuthService } from './../../core/auth/auth.service';
import { RelationService } from './../../core/services/relation.service';
import { Component, AfterContentInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/core/auth/user.model';

@Component({
  selector: 'app-relations',
  templateUrl: './relations.component.html',
  styleUrls: ['./relations.component.sass']
})
export class RelationsComponent implements AfterContentInit, OnDestroy {

  relationsListing:any = [];
  showLoadMoreButton:boolean = false;
  relationsLoading:boolean = false;
  currentPage:number = 1;
  current_user:User;
  searchResults: Subject<any> = new Subject<any>();

  showStoredQueries:boolean = false;
  storedQueryData:any;

  $subscription1:Subscription;
  $subscription2:Subscription;

  constructor(private relationService: RelationService, private authService: AuthService) {
    this.authService.user.subscribe(res => {
      this.current_user = res;
    })
  }

  ngAfterContentInit(): void {
    //this.loadRelations();
  }

  onStoredQueryClicked(storedQueryData:any){
    if(this.$subscription1){
      this.$subscription1.unsubscribe();
    }
    this.storedQueryData = storedQueryData;
    this.currentPage = 1;
    this.relationsListing = [];
    this.loadRelations();
  }

  loadRelations(){

    this.relationsLoading = true;

    this.$subscription1 = this.relationService.getRelationList(this.currentPage, this.storedQueryData).subscribe(resData => {
      if(resData){
        if(resData.items.length < 20){
          this.showLoadMoreButton = false;
        }else{
          this.showLoadMoreButton = true;
        }

        if(this.relationsListing.find(o => o.page === this.currentPage)){
          this.relationsListing.find((o:{page:number, items:[]}, i:number) => {
            if (o.page === this.currentPage) {
                this.relationsListing[i] = resData;
                return true;
            }else{
              return false;
            }
          });
        }else{
          this.relationsListing.push(resData);
        }

        this.relationsLoading = false;

        this.addPhotoUrlToEmails();
      }
    });
  }


  onNextPage(){
    if(this.relationsLoading == false){
      this.currentPage++;
      this.loadRelations();
    }
  }

  addPhotoUrlToEmails(){
    var sess = this.current_user.sessionId;
    this.relationsListing.forEach(function(page){
      page.items.forEach(function(relation){
        if(relation.logoFileName){
          relation.logoUrl = environment.fileServiceDownload + '?ouid=' + relation.uid + '&class=RelationLogo&sessionId=' + sess;
        }else{
          relation.logoUrl = null;
        }
      });
    });
  }


  onSearchInput(searchInput:string){

    if(this.$subscription2){
      this.$subscription2.unsubscribe();
    }

    var sess = this.current_user.sessionId;
    // There should be at least 2 characters
    if (searchInput.length > 2) {
      this.$subscription2 = this.relationService
        .getSearchResults(searchInput)
        .subscribe((resData) => {
          if (resData) {
            resData.items.forEach(function(relation){
              if(relation.logoFileName){
                relation.logoUrl = environment.fileServiceDownload + '?ouid=' + relation.uid + '&class=RelationLogo&sessionId=' + sess;
              }else{
                relation.logoUrl = null;
              }
            });
            this.searchResults.next(resData);
          } else {
            this.searchResults.next([]);
          }
        });
    } else {
      this.searchResults.next([]);
    }
  }

  ngOnDestroy(): void {
    if(this.$subscription1){
      this.$subscription1.unsubscribe();
    }
  }

}
