import { WhatsappService } from './../../../core/services/whatsapp.service';
import { Subscription } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { DossierService } from 'src/app/core/services/dossier.service';
import { AuthService } from './../../../core/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/core/auth/user.model';
import SwiperCore, { Pagination,  SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { CandidateService } from 'src/app/core/services/candidate.service';

SwiperCore.use([Pagination]);

@Component({
  selector: 'app-candidate-detail',
  templateUrl: './candidate-detail.component.html',
  styleUrls: ['./candidate-detail.component.sass']
})

export class CandidateDetailComponent implements OnInit, OnDestroy {

  candidateLoaded: boolean = false;
  candidateUid: string;
  candidate: any;
  candidatePhotoUrl: string;
  vacanciesEnabled:boolean = false;
  procedures:any = [];
  config: SwiperOptions = {
    slidesPerView: 1,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        let tabs = ['General', 'Dossier', 'Procedures'];
        return '<span class="' + className + '">' + tabs[index] + '</span>';
      },
    }
  };
  current_user: User;
  candidateVacancies:any = [];
  showDossier:boolean = false;
  documentTypeDrawer:any;
  lastCVUid:number;
  lastCVName:string;
  showPhotoDetail:boolean = false;
  photoNumber:number = 0;
  pictureUpload:boolean = false;

  $subscription1:Subscription;
  $subscription2:Subscription;
  $subscription3:Subscription;
  $subscription4:Subscription;
  $subscription5:Subscription;
  $subscription6:Subscription;
  $subscription7:Subscription;
  $subscription8:Subscription;
  $subscription9:Subscription;

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;


  constructor(
    private candidateService: CandidateService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private dossierService: DossierService,
    private translationService: TranslocoService
  ) {
    this.$subscription1 = this.authService.user.subscribe((res) => {
      this.current_user = res;
    });

    // When the component is loaded trough an URL including the uid param in the route, we should read it
    this.$subscription2 = this.route.params.subscribe((params) => {
      if (params['uid']) {
        // If uid is present in the route and it isn't equal to the current uid
        if (this.candidateUid != params['uid']) {
          // Set this as the current uid
          this.candidateUid = params['uid'];
        }
      }
    });
  }


  ngOnInit(): void {
    this.loadcandidate();
    this.loadProcedures();
    this.loadCV();
    this.loadTabTranslations();
  }

  loadcandidate() {
    this.$subscription3 = this.candidateService
      .getCandidateDetail(this.candidateUid)
      .subscribe((response) => {
        if (response) {
          this.candidate = response;
          this.addFullName();
          this.addSinglePhone();
          this.addPhotoUrl();
          this.loadCriteriaSettings();
          this.candidateLoaded = true;
          this.showDossier = true;
        }
      });
  }

  loadCriteriaSettings(){
    this.$subscription9 = this.candidateService.getMatchCriteriaSettings().subscribe(settings => {
      if(settings){
        settings.forEach(element => {
          var critNumber = element.uid;
          // If the active value is C it can be used, if not we can rewmove the reference to it
          if(element.active !== 'C'){
            delete this.candidate['matchCriteria_' + critNumber];
            delete this.candidate.matchCriteriaNames['matchCriteria_' + critNumber];
          }
        });
      }
    });
  }

  addFullName(){
    this.candidate.fullname = '';
    if(this.candidate.Person.firstName)
      this.candidate.fullname += this.candidate.Person.firstName;
    if(this.candidate.Person.infix)
      this.candidate.fullname += ' ' + this.candidate.Person.infix;
    if(this.candidate.Person.lastName)
      this.candidate.fullname += ' ' + this.candidate.Person.lastName;
  }

  addSinglePhone(){
    this.candidate.phone = '';
    if(this.candidate.Person.PhoneNumbers["1"].phoneNumber && this.candidate.phone == '')
      this.candidate.phone = this.candidate.Person.PhoneNumbers["1"].phoneNumber
    if(this.candidate.Person.PhoneNumbers["2"].phoneNumber && this.candidate.phone == '')
      this.candidate.phone = this.candidate.Person.PhoneNumbers["2"].phoneNumber
    if(this.candidate.Person.PhoneNumbers["3"].phoneNumber && this.candidate.phone == '')
      this.candidate.phone = this.candidate.Person.PhoneNumbers["3"].phoneNumber
    if(this.candidate.Person.PhoneNumbers["4"].phoneNumber && this.candidate.phone == '')
      this.candidate.phone = this.candidate.Person.PhoneNumbers["4"].phoneNumber
    if(this.candidate.Person.PhoneNumbers["5"].phoneNumber && this.candidate.phone == '')
      this.candidate.phone = this.candidate.Person.PhoneNumbers["5"].phoneNumber
  }

  addPhotoUrl(unique:boolean = false) {
    if(unique){
      this.photoNumber++;
    }
    if(this.candidate.Person.photoFileName){
      let sess = this.current_user.sessionId;
      this.candidatePhotoUrl =
        environment.fileServiceDownload +
        '?ouid=' +
        this.candidateUid +
        '&class=CandidatePhoto&sessionId=' +
        sess + '&testthing=' + this.photoNumber;
    }else{
      this.candidatePhotoUrl = null;
    }
  }

  onNewPictureUploaded(photoFileName:string){
    this.$subscription4 = this.candidateService.updatePicture(this.candidateUid, photoFileName).subscribe(response => {
      if(response && response.result){
        window.location.href = window.location.href;
      }
    });
  }

  onRemovePicture(){
    this.$subscription5 = this.candidateService.updatePicture(this.candidateUid, '').subscribe(response => {
      if(response && response.result){
        this.addPhotoUrl(true);
        this.candidate.Person.photoFileName = '';
        this.candidatePhotoUrl = null;
      }
    });
  }

  loadProcedures() {
    this.$subscription6 = this.candidateService
      .getProcedures(this.candidateUid)
      .subscribe((res) => {
        if(res && res.result && res.result.listOutput.length){
          this.procedures = res.result.listOutput;
        }
      });
  }

  loadCV(){
    this.$subscription7 = this.dossierService.getCandidateCVs(this.candidateUid).subscribe(response => {
      if(response.result.length > 0){
        this.lastCVUid = response.result[0].uid;
        this.lastCVName = response.result[0].fileName;

      }
    });
  }

  loadTabTranslations(){
    this.$subscription8 = this.translationService.selectTranslate('candidates.tabGeneral').subscribe(res => {
      // Get the translation values for the tabs
      let tabs = [
        this.translationService.translate('candidates.tabGeneral'),
        this.translationService.translate('candidates.tabDossier'),
        this.translationService.translate('candidates.tabProcedures')
      ];
      // Set the new renderBullet function for the new translations
      this.config.pagination = {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + tabs[index] + '</span>';
        },
      };
    });
  }




  ngOnDestroy(): void {
    if(this.$subscription1){
      this.$subscription1.unsubscribe();
    }
    if(this.$subscription2){
      this.$subscription2.unsubscribe();
    }
    if(this.$subscription3){
      this.$subscription3.unsubscribe();
    }
    if(this.$subscription4){
      this.$subscription4.unsubscribe();
    }
    if(this.$subscription5){
      this.$subscription5.unsubscribe();
    }
    if(this.$subscription6){
      this.$subscription6.unsubscribe();
    }
    if(this.$subscription7){
      this.$subscription7.unsubscribe();
    }
    if(this.$subscription8){
      this.$subscription8.unsubscribe();
    }
    if(this.$subscription9){
      this.$subscription9.unsubscribe();
    }
  }

}
