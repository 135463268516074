import { CachingService } from './caching.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class InteractionService {
  private jobInsertionForms = new BehaviorSubject<any>(null);
  private candidateInsertionForms = new BehaviorSubject<any>(null);
  private relationInsertionForms = new BehaviorSubject<any>(null);
  constructor(
    private http: HttpClient,
    private cachingService: CachingService
  ) {}

  getRelationInsertionForms(){
    // Define the OWS method
    const requestMethod = 'Otys.Services.FreeInteractionService.getList';
    // Define the body of the request (params)
    const requestBody = [
      {
        what: {
          uid: 1,
          name: 1
        },
        condition: {
          type: "AND",
          items: [
            {
              type: "COND",
              field: "actionId",
              op: "EQ",
              param: "InsertRelation"
            },
            {
              type: "COND",
              field: "deleted",
              op: "EQ",
              param: false
            }
          ]
        },
        limit: 200
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'true'
      },
    };


    let cached_data = this.cachingService.getCache(
      requestMethod,
      requestBody
    );
    if (cached_data) {
      if (cached_data.result && cached_data.result.length) {
        this.relationInsertionForms.next(cached_data.result);
      }
    } else {
      this.relationInsertionForms.next(null);
    }

    if(this.cachingService.getCacheAge(requestMethod, requestBody) === false || this.cachingService.getCacheAge(requestMethod, requestBody) > 1480){
      // Retrieve live data
      this.http
      .post<any>(environment.owsUrl, requestBody, requestHeaders)
      .subscribe((resData) => {
        if (resData && resData.result.length) {
          this.relationInsertionForms.next(resData.result);
        }
      });
    }


    // Return the vacancy list as an observable so other components can listen to any updates
    return this.relationInsertionForms.asObservable();
  }

  getJobInsertionForms(){
    // Define the OWS method
    const requestMethod = 'Otys.Services.FreeInteractionService.getList';
    // Define the body of the request (params)
    const requestBody = [
      {
        what: {
          uid: 1,
          name: 1
        },
        condition: {
          type: "AND",
          items: [
            {
              type: "COND",
              field: "actionId",
              op: "EQ",
              param: "InsertJob"
            },
            {
              type: "COND",
              field: "deleted",
              op: "EQ",
              param: false
            }
          ]
        },
        limit: 200
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'true'
      },
    };


    let cached_data = this.cachingService.getCache(
      requestMethod,
      requestBody
    );
    if (cached_data) {
      if (cached_data.result && cached_data.result.length) {
        this.jobInsertionForms.next(cached_data.result);
      }
    } else {
      this.jobInsertionForms.next(null);
    }

    if(this.cachingService.getCacheAge(requestMethod, requestBody) === false || this.cachingService.getCacheAge(requestMethod, requestBody) > 1480){
      // Retrieve live data
      this.http
      .post<any>(environment.owsUrl, requestBody, requestHeaders)
      .subscribe((resData) => {
        if (resData && resData.result.length) {
          this.jobInsertionForms.next(resData.result);
        }
      });
    }


    // Return the vacancy list as an observable so other components can listen to any updates
    return this.jobInsertionForms.asObservable();
  }


  getCandidateInsertionForms(){
    // Define the OWS method
    const requestMethod = 'Otys.Services.FreeInteractionService.getList';
    // Define the body of the request (params)
    const requestBody = [
      {
        what: {
          uid: 1,
          name: 1
        },
        condition: {
          type: "AND",
          items: [
            {
              type: "COND",
              field: "actionId",
              op: "EQ",
              param: "InsertCandidate"
            },
            {
              type: "COND",
              field: "deleted",
              op: "EQ",
              param: false
            }
          ]
        },
        limit: 200
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'true'
      },
    };


    let cached_data = this.cachingService.getCache(
      requestMethod,
      requestBody
    );
    if (cached_data) {
      if (cached_data.result && cached_data.result.length) {
        this.candidateInsertionForms.next(cached_data.result);
      }
    } else {
      this.candidateInsertionForms.next(null);
    }

    if(this.cachingService.getCacheAge(requestMethod, requestBody) === false || this.cachingService.getCacheAge(requestMethod, requestBody) > 1480){
      // Retrieve live data
      this.http
      .post<any>(environment.owsUrl, requestBody, requestHeaders)
      .subscribe((resData) => {
        if (resData && resData.result.length) {
          this.candidateInsertionForms.next(resData.result);
        }
      });
    }


    // Return the vacancy list as an observable so other components can listen to any updates
    return this.candidateInsertionForms.asObservable();
  }

  getInteractionForm(formUid:number){
    // Define the OWS method
    const requestMethod = 'Otys.Services.FreeInteractionService.sessionStart';
    // Define the body of the request (params)
    const requestBody = [
      formUid,
      null,
      null,
      null
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'false'
      },
    };

    // Return the vacancy list as an observable so other components can listen to any updates
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }


  submitForm(formSession:string, formValue:any){
    // Define the OWS method
    const requestMethod = 'Otys.Services.FreeInteractionService.sessionSubmit';
    // Define the body of the request (params)
    const requestBody = [
      formSession,
      formValue
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'false'
      },
    };

    // Return the vacancy list as an observable so other components can listen to any updates
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

  commitForm(formSession:string){
    // Define the OWS method
    const requestMethod = 'Otys.Services.FreeInteractionService.sessionCommit';
    // Define the body of the request (params)
    const requestBody = [
      formSession
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'false'
      },
    };

    // Return the vacancy list as an observable so other components can listen to any updates
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

}
