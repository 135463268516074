import { AuthService } from './../auth/auth.service';
import { CachingService } from './caching.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { mailFolder } from '../interfaces/mail-interface';
import { User } from '../auth/user.model';
import { MatomoTracker } from '@ngx-matomo/tracker';

@Injectable()
export class EmailsService{

  private mailList = new BehaviorSubject<any>(null);
  private mailSearchList = new BehaviorSubject<any>(null);
  private mailDetail = new BehaviorSubject<any>(null);
  private mailFolders = new BehaviorSubject<mailFolder[]>([]);
  private mailSharedFolders = new BehaviorSubject<any>(null);
  private mailEmptyMessage = new BehaviorSubject<any>(null);
  private mailSendingProfiles = new BehaviorSubject<any>(null);
  current_user:User;

  constructor(private http: HttpClient, private cachingService: CachingService, private authService: AuthService, private readonly tracker: MatomoTracker){
    this.authService.user.subscribe(res => {
      this.current_user = res;
    })
  }

  getMailPage(folder:any, page:number = 1, flag:any = '0'){

    let limit = 20;
    let offset = 0;

    if(page != 1){
      offset = page * limit - limit;
    }

    // Define the OWS method
    const requestMethod = 'Otys.Services.EmailService.getListEx';
    // Define the body of the request (params)
    const requestBody = [
    {
        what: {
          uid: 1,
          userId: 1,
          subject: 1,
          From: 1,
          To: 1,
          plainTextMessage: 1,
          Person: {
            photoFileName: 1,
            photoFileOuid: 1,
            photoFileClass: 1,
            candidateUid: 1,
            relationContactUid: 1,
            userId: 1
          },
          read: 1,
          sentDateTime: 1,
          hasAttachment: 1,
          lastAction: 1,
          lastActionDateRaw: 1,
          folderId: 1,
          folder: 1,
          tag: 1,
          private: 1,
          priority: 1,
          priorityId: 1
        },
        limit: 20,
        offset: offset,
        getTotalCount: 1,
        excludeLimitCheck: true,
        condition: {
          type: "AND",
          items: [
            {
              type: "COND",
              field: "folderId",
              op: "EQ",
              param: [
                folder
              ]
            }
          ]
        },
        sort: {
          sentDateTime: "DESC"
        }
      }
    ];


    if(flag !== '0'){
      requestBody[0].condition.items.push({
        type:"COND",
        field:"tag",
        op:"EQ",
        param:[
            flag
        ]
      });
    }

    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'true'
      },
    };


    if(page == 1){
      // Retrieve cached data (if theres any)
      let cached_data = this.cachingService.getCache(requestMethod, requestBody);
      if(cached_data){
        if(cached_data.result.listOutput.length){
          this.mailList.next({
            page: page,
            items: cached_data.result.listOutput
          });
        }
      }else{
        this.mailList.next(null);
      }
    }else{
      this.mailList.next(null);
    }

    // Retrieve live data
    this.http.post<any>(environment.owsUrl, requestBody, requestHeaders).subscribe( resData => {
      if(resData && resData.result){
        if(resData.result.listOutput){
          this.mailList.next({
            page: page,
            items: resData.result.listOutput
          })
        }
      }

    });

    // Return the mailWidget as an observable so other components can listen to any updates
    return this.mailList.asObservable();
  }

  getSearchResults(searchInput:string){

    this.mailSearchList.next(null);

    // Define the OWS method
    const requestMethod = 'Otys.Services.EmailService.getListEx';
    // Define the body of the request (params)
    const requestBody = [
    {
        what: {
          uid: 1,
          userId: 1,
          subject: 1,
          From: 1,
          To: 1,
          plainTextMessage: 1,
          Person: {
            photoFileName: 1,
            photoFileOuid: 1,
            photoFileClass: 1,
            candidateUid: 1,
            relationContactUid: 1,
            userId: 1
          },
          read: 1,
          sentDateTime: 1,
          hasAttachment: 1,
          folderId: 1,
          folder: 1,
          tag: 1,
          private: 1,
          priority: 1,
          priorityId: 1
        },
        limit: 75,
        offset: 0,
        getTotalCount: 1,
        excludeLimitCheck: true,
        search: {
          ELASTICSEARCH: {
            DATA: {
              QUERIES: {
                queryString: "from:("+searchInput+") OR subject:("+searchInput+") OR body:("+searchInput+")"
              },
              FILTERS: {
                hidden: false,
                userId: this.current_user.id
              }
            },
            OPTIONS: {
              limit: 75,
              offset: 0
            }
          }
        },
        sort: {
          sentDateTime: "DESC"
        }
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };

    // Retrieve live data
    this.http.post<any>(environment.owsUrl, requestBody, requestHeaders).subscribe( resData => {
      if(resData){
        if(resData.result.listOutput){
          this.mailSearchList.next({
            page: 1,
            items: resData.result.listOutput
          })
        }
      }

    });

    // Return the mailWidget as an observable so other components can listen to any updates
    return this.mailSearchList.asObservable();
  }


  getMailDetail(uid:number){
    // Define the OWS method
    const requestMethod = 'Otys.Services.EmailService.getDetail';
    // Define the body of the request (params)
    const requestBody = [
      uid,
      {
        uid: 1,
        From: 1,
        subject: 1,
        To: 1,
        Cc: 1,
        Bcc: 1,
        read: 1,
        sentDateTime: 1,
        receivedDateTime: 1,
        htmlMessage: 1,
        userId: 1,
        folderId: 1,
        folder: 1,
        hasAttachment: 1,
        lastAction: 1,
        lastActionDateRaw: 1,
        tag: 1,
        private: 1,
        priority: 1,
        priorityId: 1,
        Attachments: {
          filename: 1,
          size: 1
        },
        Person: {
          photoFileName: 1,
          photoFileOuid: 1,
          photoFileClass: 1,
          candidateUid: 1,
          relationContactUid: 1,
          userId: 1
        },
        Calendar: {
          startDateTime: 1,
          endDateTime: 1,
          title: 1,
          location: 1,
          description: 1,
          status: 1
        }
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };

    this.mailDetail.next(null);

    // Retrieve live data
    this.http.post<any>(environment.owsUrl, requestBody, requestHeaders).subscribe(resData => {
      if(resData){
        this.mailDetail.next(resData);
      }
    });

    // Return the mailWidget as an observable so other components can listen to any updates
    return this.mailDetail.asObservable();
  }


  markAsRead(uid:number){
    // Define the OWS method
    const requestMethod = 'Otys.Services.EmailService.update';
    // Define the body of the request (params)
    const requestBody = [
      uid,
      {
        read : true
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };

    // Return the response as an observable so other components can listen to any updates
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

  markAsUnRead(uid:number){
    // Define the OWS method
    const requestMethod = 'Otys.Services.EmailService.update';
    // Define the body of the request (params)
    const requestBody = [
      uid,
      {
        read : false
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };

    // Return the response as an observable so other components can listen to any updates
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

  getSendingProfiles(){
    // Define the OWS method
    const requestMethod = 'Otys.Services.SendingProfileService.getList';
    // Define the body of the request (params)
    const requestBody = [
      {
        what: {
          uid: 1,
          name: 1,
          isStandardProfile: 1
        },
        limit: 200
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'true'
      },
    };
    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if(cached_data && cached_data.result){
      this.mailSendingProfiles.next(cached_data.result);
    }else if(cached_data && cached_data.error){
      this.mailSendingProfiles.next(null);
    }else{
      this.mailSendingProfiles.next(null);
    }

    if(this.cachingService.getCacheAge(requestMethod, requestBody) === false || this.cachingService.getCacheAge(requestMethod, requestBody) > 180){
      // Retrieve live data
      this.http.post<any>(environment.owsUrl, requestBody, requestHeaders).subscribe( resData => {
        if(resData.result){
          this.mailSendingProfiles.next(resData.result);
        }else if(resData.error){
          this.mailSendingProfiles.next([]);
        }
      });
    }

    // Return the mailWidget as an observable so other components can listen to any updates
    return this.mailSendingProfiles.asObservable();
  }

  getMailFolders(){
    // Define the OWS method
    const requestMethod = 'Otys.Services.EmailFolderService.getList';
    // Define the body of the request (params)
    const requestBody = [
      {
        what: {
          uid: 1,
          folder: 1,
          unreadEmailCount: 1
        },
        limit: 500
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'true'
      },
    };
    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if(cached_data && cached_data.result){
      this.mailFolders.next(cached_data.result);
    }else if(cached_data && cached_data.error){
      this.mailFolders.next([]);
    }else{
      this.mailFolders.next(null);
    }

    // Retrieve live data
    this.http.post<any>(environment.owsUrl, requestBody, requestHeaders).subscribe( resData => {
      if(resData.result){
        this.mailFolders.next(resData.result);
      }else if(resData.error){
        this.mailFolders.next([]);
      }
    });

    // Return the mailWidget as an observable so other components can listen to any updates
    return this.mailFolders.asObservable();
  }

  getSharedFolders(){
    // Define the OWS method
    const requestMethod = 'Otys.Services.EmailFolderService.getPublicFolders';
    // Define the body of the request (params)
    const requestBody = [];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'true'
      },
    };
    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if(cached_data && cached_data.result){
      this.mailSharedFolders.next(cached_data.result);
    }else if(cached_data && cached_data.error){
      this.mailSharedFolders.next([]);
    }else{
      this.mailSharedFolders.next(null);
    }

    // Retrieve live data
    this.http.post<any>(environment.owsUrl, requestBody, requestHeaders).subscribe( resData => {
      if(resData.result){
        this.mailSharedFolders.next(resData.result);
      }else if(resData.error){
        this.mailSharedFolders.next([]);
      }
    });

    // Return the mailWidget as an observable so other components can listen to any updates
    return this.mailSharedFolders.asObservable();
  }

  moveEmails(emailArray:number[], folderUid:string){
    // Define the OWS method
    const requestMethod = 'Otys.Services.EmailService.moveEmailsToFolder';
    // Define the body of the request (params)
    const requestBody = [
      emailArray,
      folderUid
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };

    // Return the mailWidget as an observable so other components can listen to any updates
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

  deleteEmail(emailUid:number){
    // Define the OWS method
    const requestMethod = 'Otys.Services.EmailService.delete';
    // Define the body of the request (params)
    const requestBody = [
      emailUid
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };

    // Return the mailWidget as an observable so other components can listen to any updates
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }


  getAutoSuggestionsTo(input:string){
    // Define the OWS method
    const requestMethod = 'Otys.Services.PersonSearchService.findEmailRecipients';
    // Define the body of the request (params)
    const requestBody = [
      input,
      []
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

  getAutoSuggestionsCc(input:string){
    // Define the OWS method
    const requestMethod = 'Otys.Services.PersonSearchService.findEmailRecipients';
    // Define the body of the request (params)
    const requestBody = [
      input,
      []
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

  updateFlag(uid:number, id:number){
    // Define the OWS method
    const requestMethod = 'Otys.Services.EmailService.update';
    // Define the body of the request (params)
    const requestBody = [
      uid,
      {
        tag : id
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };

    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

  sendMail(toValues:string[], ccValues:string[], subject:string, message:string, attachmentArray:any, postpone:any = false, sendingProfileUid:any = false, actionMode:any = 'send', relatedEmail:any = false){
    // Define the OWS method
    const requestMethod = 'Otys.Services.EmailService.send';
    // Define the body of the request (params)

    const requestBody:any = [
      {
        htmlMessage: message,
        subject: subject,
        To: toValues,
        Cc: ccValues,
        Bcc: [],
        Bindings: [],
        private: false,
        BindingsMerged: {},
        Attachments: attachmentArray,
        postponeDateTime: false
      }
    ];

    if(postpone !== false){
      requestBody[0].postponeDateTime = postpone;
    }

    if(sendingProfileUid !== false){
      requestBody[0].profileUid = sendingProfileUid;
    }

    if(actionMode !== 'send'){
      if(actionMode == 'forward' && relatedEmail !== false){
        requestBody[0].forwardOfEmailId = relatedEmail;
      }

      if(actionMode == 'reply' && relatedEmail !== false){
        requestBody[0].replyToEmailId = relatedEmail;
      }

      if(actionMode == 'replyAll' && relatedEmail !== false){
        requestBody[0].replyToEmailId = relatedEmail;
      }
    }


    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };

    this.tracker.trackGoal(1);

    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

  saveAsConcept(conceptUid:number, toValues:string[], ccValues:string[], subject:string, message:string, attachmentArray:any){
    // Define the OWS method
    const requestMethod = 'Otys.Services.EmailService.saveConcept';
    // Define the body of the request (params)
    const requestBody = [
      {
        htmlMessage: message,
        subject: subject,
        To: toValues,
        Cc: ccValues,
        Bcc: [],
        Bindings: [],
        private: false,
        BindingsMerged: {},
        Attachments: attachmentArray,
        uid: conceptUid,
        id: conceptUid
      }
    ];

    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };

    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }


  getEmptyMessage(sendingProfileUid:number){
    // Define the OWS method
    const requestMethod = 'Otys.Services.EmailService.getEmptyMessage';
    // Define the body of the request (params)
    const requestBody = [];
    if(sendingProfileUid && sendingProfileUid > 0){
      requestBody.push(sendingProfileUid)
    }

    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'true'
      },
    };
    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if(cached_data){
      this.mailEmptyMessage.next(cached_data);
    }else{
      this.mailEmptyMessage.next(null);
    }
    if(this.cachingService.getCacheAge(requestMethod, requestBody) === false || this.cachingService.getCacheAge(requestMethod, requestBody) > 30){
      // Retrieve live data
      this.http.post<any>(environment.owsUrl, requestBody, requestHeaders).subscribe( resData => {
        this.mailEmptyMessage.next(resData)
      });
    }

    // Return the mailWidget as an observable so other components can listen to any updates
    return this.mailEmptyMessage.asObservable();
  }

  getReplyMessage(mailUid:number,sendingProfileUid:number){
    // Define the OWS method
    const requestMethod = 'Otys.Services.EmailService.getReplyMessage';
    // Define the body of the request (params)
    const requestBody = [
      mailUid
    ];
    if(sendingProfileUid && sendingProfileUid > 0){
      requestBody.push(sendingProfileUid)
    }
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };

    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

  getReplyAllMessage(mailUid:number,sendingProfileUid:number){
    // Define the OWS method
    const requestMethod = 'Otys.Services.EmailService.getReplyToAllMessage';
    // Define the body of the request (params)
    const requestBody = [
      mailUid
    ];
    if(sendingProfileUid && sendingProfileUid > 0){
      requestBody.push(sendingProfileUid)
    }
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };

    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }
  getForwardMessage(mailUid:number,sendingProfileUid:number){
    // Define the OWS method
    const requestMethod = 'Otys.Services.EmailService.getForwardMessage';
    // Define the body of the request (params)
    const requestBody = [
      mailUid
    ];
    if(sendingProfileUid && sendingProfileUid > 0){
      requestBody.push(sendingProfileUid)
    }
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };

    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }


  updateInviteStatus(mailUid:number, status:string){
    // Define the OWS method
    const requestMethod = 'Otys.Services.EmailService.update';
    // Define the body of the request (params)
    const requestBody = [
      mailUid,
      {
        Calendar : {
          status : status
        }
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };

    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }


  getAutoresponder(){
    // Define the OWS method
    const requestMethod = 'Otys.Services.AutoResponderService.getData';
    // Define the body of the request (params)
    const requestBody = [
      this.current_user.id,
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };

    // Return the response as an observable so other components can listen to any updates
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

  setAutoresponder(autoResponderId:string, start:number, end:number, subject:string, body:string, active:boolean){
    // Define the OWS method
    const requestMethod = 'Otys.Services.AutoResponderService.update';
    // Define the body of the request (params)
    const requestBody = [
      autoResponderId,
      {
        startDate: start,
        endDate: end,
        subject: subject,
        body: body,
        active: active
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };

    // Return the response as an observable so other components can listen to any updates
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

  createAutoresponder(start:number, end:number, subject:string, body:string, active:boolean){
    // Define the OWS method
    const requestMethod = 'Otys.Services.AutoResponderService.add';
    // Define the body of the request (params)
    const requestBody = [
      {
        startDate: start,
        endDate: end,
        subject: subject,
        body: body,
        active: active,
        userId: this.current_user.id
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };

    // Return the response as an observable so other components can listen to any updates
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }



}
