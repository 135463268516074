<div class="drawer-opener" (click)="onOpen()">
  <span>{{currentFilter}}</span>
  <i class="fa-solid fa-chevron-down"></i>
</div>


<div class="stored-query-container" [ngClass]="{'open' : open}" *transloco="let t; read: 'storedqueries'">
  <header>
    <div class="close-container" (click)="onClose()">
      <i class="fa-solid fa-arrow-left"></i>
    </div>
    <div class="title">
      {{title}}
    </div>
  </header>
  <div class="sqc-inner">

    <ng-container *ngIf="hasDefaultFilter">
      <div class="stored-query-tab">
        <div class="sqt-item" [ngClass]="{'active' : defaultFilterActive}" (click)="onDefaultFilterSelect()">
          <span>{{t('defaultFilter')}}</span>
          <i class="fa-solid fa-circle-check" *ngIf="defaultFilterActive"></i>
        </div>
      </div>
    </ng-container>

    <ng-container *ngFor="let sqt of storedQueries">
      <div class="stored-query-tab">
        <h3>{{sqt.name}}</h3>
        <div class="sqt-item" *ngFor="let sqtItem of sqt.storedQueries" [ngClass]="{'active' : sqtItem.uid == activeUid}" (click)="onItemSelect(sqtItem)">
          <span>{{sqtItem.name}}</span>
          <i class="fa-solid fa-circle-check" *ngIf="sqtItem.uid == activeUid"></i>
        </div>

        <div class="no-stored-queries" *ngIf="!sqt.storedQueries || !sqt.storedQueries.length > 0">
          {{t('noStoredQueriesFound')}}
        </div>

      </div>
    </ng-container>
    <button class="secondary remove_active_filters" [ngClass]="{'open' : open}" *ngIf="activeUid != 0" (click)="onResetFilters()">
      {{t('removeFilters')}}
    </button>
  </div>
</div>
