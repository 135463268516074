<div class="alerts" *ngIf="alerts.length">
  <div *ngFor="let alert of alerts.slice().reverse()" class="alert" (click)="removeAlert(alert)">
    <div class="alert-icon" [ngClass]="alert.alertType">
      <i class="fa-solid fa-check" *ngIf="alert.alertType == 'success'"></i>
      <i class="fa-sharp fa-solid fa-exclamation" *ngIf="alert.alertType == 'error' || alert.alertType == 'warning'"></i>
      <i class="fa-solid fa-question" *ngIf="alert.alertType == 'info'"></i>
    </div>
    <div class="alert-message">{{alert.message | capitalize}}</div>
  </div>
</div>
