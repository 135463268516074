<div class="relation-select-input" *ngIf="showInput">
  <input type="text" (keyup)="onInput()" [(ngModel)]="fieldValue">
  <div class="auto-suggest" *ngIf="autoSuggestValues.length" #autoSuggest (outsideClick)="autoSuggestValues = []">
    <div class="auto-suggest-item" *ngFor="let suggestItem of autoSuggestValues" (click)="onSelectItem(suggestItem)">
      <div class="auto-suggest-item-title">
        {{suggestItem.relation}}
      </div>
    </div>
  </div>
</div>

<div class="relation-select-result" *ngIf="result" (click)="onReset()">
    <div class="result-value">
      {{result.relation}}
    </div>
    <div class="result-remove">
      <i class="fa-sharp fa-solid fa-circle-xmark"></i>
    </div>
</div>
