import {Injectable} from "@angular/core";
import {JSEncrypt} from "jsencrypt";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {
  BehaviorSubject,
  catchError, concatMap, distinctUntilChanged,
  map,
  Observable, of, retry,
  shareReplay,
  switchMap,
} from "rxjs";
import {AuthService} from "./auth.service";
import {delay, filter, tap} from "rxjs/operators";

const LS_DATA_KEY: string = 'auth_keys'
const DEVICE_ID_LENGTH: number = 20

interface KeysLSObject {
  publicKey: string | undefined
  deviceId: string,
  id: string,
  userId: number
}

type AuthKeysResponse = {
  id: number,
  result: {
    public_key: string,
    id: string
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthKeysService {

  private keys$ = new BehaviorSubject<KeysLSObject | null>(null)
  private currentUser$ = this.authService.user
  private readonly rS$: Observable<void>


  constructor(private http: HttpClient, private authService: AuthService) {
    const savedKeys$ = this.currentUser$.pipe(
      map(u => {
        const keys = JSON.parse(localStorage.getItem(LS_DATA_KEY)) as KeysLSObject | null
        if (u?.id !== undefined && u.id !== null && keys !== null && keys.userId !== u.id) {
          localStorage.removeItem(LS_DATA_KEY)
          return null
        }
        return keys
      }),
    )
    const apiKeys$ = this.currentUser$.pipe(
      delay(10),
      filter(u => u !== null && u.id !== null),
      switchMap(u => this.getKeysRequest$().pipe(
        map(resp => ({publicKey: resp.result.public_key, id: resp.result.id, userId: u.id}))
      )),
      switchMap(data => {
        const enc = new JSEncrypt()
        enc.setPublicKey(data.publicKey)
        const deviceId = this.generateDeviceId()
        const encryptedDeviceId = enc.encrypt(deviceId)
        if (!encryptedDeviceId) {
          throw new Error('Encryption error')
        }
        return this.registerDeviceRequest$(data.id, encryptedDeviceId).pipe(
          map((response) => {
            if ('error' in response) {
              throw new Error(response.error.message)
            }
            return {
              id: data.id,
              publicKey: data.publicKey,
              deviceId: deviceId,
              userId: data.userId
            } as KeysLSObject
          })
        )
      }),
      tap(data => localStorage.setItem(LS_DATA_KEY, JSON.stringify(data))),
      retry(2),
      catchError(() => of(null))
    )
    savedKeys$.pipe(
      switchMap(v => {
        if (v === null) {
          return apiKeys$
        } else {
          return of(v)
        }
      }),
      distinctUntilChanged((previous, current) => previous?.id === current?.id),
    ).subscribe(this.keys$)

    this.rS$ = this.keys$.pipe(
      map(keys => {
        if (keys === null) {
          throw new Error("keys empty")
        }
        const enc = new JSEncrypt()
        enc.setPublicKey(keys.publicKey)
        const encodedDeviceId = enc.encrypt(keys.deviceId)
        if (!encodedDeviceId) {
          throw new Error("failed to encode")
        }

        return {id: keys.id, encodedDeviceId}
      }),
      concatMap(v => this.restoreSessionRequest$(v.id, v.encodedDeviceId)),
      tap(v => {
        if ('error' in v) {
          throw new Error(v.error)
        }
        this.authService.loginBySessionId(v.result,true)
      }),
      catchError(() => {
        localStorage.removeItem(LS_DATA_KEY)
        throw new Error('failed to restore')
      }),
      shareReplay({refCount: true, bufferSize: 1})
    )
  }

  public canRestoreSession(): boolean {
    return this.keys$.getValue() !== null
  }

  public restoreSession$() {
    return this.rS$
  }

  private getKeysRequest$() {
    return this.http.post<AuthKeysResponse>(
      environment.owsUrl,
      [],
      {
        headers: {
          'ows-method': 'Otys.Services.AuthKeysService.generateAuthKey',
        },
      }
    )
  }

  private registerDeviceRequest$(id: string, deviceIdEncoded: string) {
    return this.http.post<any>(
      environment.owsUrl,
      [
        id,
        deviceIdEncoded
      ],
      {
        headers: {
          'ows-method': 'Otys.Services.AuthKeysService.activateAuthKey',
        }
      }
    )
  }

  private restoreSessionRequest$(id: string, deviceIdEncoded: string) {
    return this.http.post<any>(
      environment.owsUrl,
      [
        id, deviceIdEncoded
      ],
      {
        headers: {
          'ows-method': 'Otys.Services.AuthKeysService.restoreSession',
        }
      }
    )
  }

  private generateDeviceId() {
    return new TextDecoder().decode(window.crypto.getRandomValues(new Uint32Array(DEVICE_ID_LENGTH)))
  }
}
