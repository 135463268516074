<div class="tab-inner" *transloco="let t; read: 'settings'">
  <ng-container *ngFor="let setting of settings | keyvalue">

    <div class="setting no-margin" *ngIf="setting.key == '01theme' && allowedSettings.includes(setting.key)">
      <div class="setting-title">
        {{ t(setting.value.name) }}
      </div>
      <div class="value">
        <div class="input" *ngIf="setting.value.type == 'theme'">

          <swiper #swiperTheme [config]="config" (realIndexChange)="onSwiper($event)">
            <ng-template swiperSlide *ngFor="let theme of appthemes; let i = index;">
              <div class="theme-option" [style.background-color]="theme.themeMain" [style.border-color]="theme.themeBackground">
              </div>
            </ng-template>
          </swiper>
        </div>
      </div>
    </div>

    <div class="setting" *ngIf="setting.key == '05darkMode' && allowedSettings.includes(setting.key)">
      <div class="setting-title">
        {{ t(setting.value.name) }}
      </div>
      <div class="value">
        <div class="input">
          <div class="darkmode-switch">
            <ng-container *ngFor="let answer of darkmodeSetting.answers; let i = index;">
              <div class="option" (click)="onUpdateSettingDarkMode(i)">
                <div class="optionText">{{t('darkModeOption_' + answer)}}</div>
                <div class="optionRadio" [ngClass]="{'active' : darkmodeValue == i}">
                  <i class="fa-solid fa-check" *ngIf="darkmodeValue == i"></i>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="setting" *ngIf="setting.key == '06language' && allowedSettings.includes(setting.key)">
      <div class="setting-title">
        {{t('language')}}
      </div>
      <div class="value">
        <div class="input">
          <select (change)="onUpdateSettingLanguage(langSel.value)" #langSel>
            <ng-container *ngFor="let answer of languageSetting.answers; let i = index;">
              <option [value]="i" [selected]="languageValue == i" *ngIf="i < 8">{{t('languageOption' + answer)}}</option>
            </ng-container>
          </select>
        </div>
      </div>
    </div>

    <div class="setting" *ngIf="setting.key == '07calendarShowWeekends' && allowedSettings.includes(setting.key)">
      <div class="setting-title">
        {{t(setting.value.name)}}
      </div>
      <div class="value">
        <div class="input">
          <app-toggle-switch
          [value]="setting.value.value"
          (toggled)="onUpdateSetting(setting.key, $event)"
        ></app-toggle-switch>
        </div>
      </div>
    </div>

    <div class="setting" *ngIf="setting.key == '02dashboardLayout' && allowedSettings.includes(setting.key)">
      <div class="setting-title">
        {{ t(setting.value.name) }}
      </div>
      <div class="value">
        <ng-container *ngIf="canShowEmailsWidget == false && canShowReminderWidget == false && canShowCandidateWidget == false">
          <div class="widget">
            <div class="notification" *transloco="let t; read: 'dashboard'">{{t('noRightsMessage')}}</div>
          </div>
        </ng-container>
        <div class="input layoutItems">
          <div class="sortableWrap" [sortablejs]="setting.value.value" [sortablejsOptions]="sortableOptions">
            <ng-container *ngFor="let layoutItem of setting.value.value; let i = index;">
              <div class="layoutItem" [ngClass]="{'hidden1': layoutItem.component == 'app-candidatewidget' && canShowCandidateWidget == false,'hidden3': layoutItem.component == 'app-reminderwidget' && canShowReminderWidget == false,'hidden4': layoutItem.component == 'app-mailwidget' && canShowEmailsWidget == false, 'hidden2': layoutItem.component == 'app-mailwidget' && canShowEmailsWidget == false}">
                <div class="sort-grabber">
                  <i class="fa-solid fa-grip-dots-vertical"></i>
                </div>
                <div class="item-name">
                  {{ t(layoutItem.name) | capitalize }}
                </div>
                <div class="item-limit">
                  <span>{{t('max')}}</span>
                  <input type="number" [value]="layoutItem.limit" min="1" max="10" step="1" (change)="onUpdateLimit($event,i)">
                </div>
                <div class="item-toggle">
                  <app-toggle-switch [value]="layoutItem.enabled" (toggled)="onUpdateEnabled($event,i)"></app-toggle-switch>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="setting" *ngIf="setting.key == '03candidateFilterOnDashboard' && allowedSettings.includes(setting.key)" [ngClass]="{'hidden': !canShowCandidateWidget}">
      <div class="setting-title">
        {{ t(setting.value.name) }}
      </div>
      <div class="value">
        <div class="input candidateWidgetFilter" *ngIf="setting.value.type == 'candidateWidgetFilter'">
          <select name="candidateFilter" [formControl]="candFilter" (change)="onCandidateFilterUpdate()">
            <option *ngFor="let filterOption of setting.value.answers;let i = index;" [ngValue]="i" [selected]="i == setting.value.value">{{t(filterOption)}}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="setting" *ngIf="setting.key == 'THESEDONTEXCISTYET' && allowedSettings.includes(setting.key)">
      <div class="setting-title">
        {{ t(setting.value.name) }}
      </div>
      <div class="input" *ngIf="setting.value.type == 'number'">
        <input
          type="number"
          [value]="setting.value.value"
          (change)="onUpdateSetting(setting.key, $event.target.value)"
        />
      </div>
      <div class="input" *ngIf="setting.value.type == 'boolean'">
        <app-toggle-switch
          [value]="setting.value.value"
          (toggled)="onUpdateSetting(setting.key, $event)"
        ></app-toggle-switch>
      </div>
    </div>

    <div class="setting" *ngIf="setting.key == '04emailAutoResponder' && allowedSettings.includes('04emailAutoResponder')">
      <div class="big-setting">
        <app-autoresponder [title]="t(setting.value.name)"></app-autoresponder>
      </div>
    </div>

  </ng-container>
</div>
