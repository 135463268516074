<header *transloco="let t; read: 'calendar'">
  <div class="header-wrap">
    <div class="left-side">
      <app-back-button></app-back-button>
      <h1 *ngIf="newEventMode">{{t('createAgendaItem')}}</h1>
      <h1 *ngIf="!newEventMode">{{t('calendarItemDetail') | capitalize}}</h1>
    </div>
    <div class="right-side">
      <div class="event-compose-icons">
        <img src="/assets/images/spinner.svg" width="20" height="20" *ngIf="loadingSave" class="loading_spinner">
        <ng-container *ngIf="allowEditing">
          <div class="compose-icon delete" id="calendarEventComposerDeleteSingle" (click)="onDeleteEvent()" *ngIf="!newEventMode && !loadingSave">
            <i class="fa-solid fa-trash"></i>
          </div>
          <div class="compose-icon delete" id="calendarEventComposerDeleteMultiple" (click)="onDeleteAllEvents()" *ngIf="!newEventMode && !loadingSave && hasFutureEvents">
            <i class="fa-solid fa-trash-list"></i>
          </div>
          <div class="compose-icon send" id="calendarEventComposerSave" (click)="onSaveEvent()" *ngIf="!loadingSave">
            <i class="fa-solid fa-save"></i>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</header>
<div class="page" *transloco="let t; read: 'calendar'">
  <form class="event-form" [formGroup]="eventForm" [ngClass]="{'doneLoading' : !loadingEvent}">
    <div class="question">
      <label>{{t('calendarItemTitle')}}:</label>
      <input type="text" name="title" formControlName="title" [attr.disabled]="!allowEditing ? '' : null" required>
    </div>
    <div class="questionwrap">
      <div class="question">
        <label>{{t('calendarItemStartDatetime')}}:</label>
        <input type="datetime-local" name="startDateTime" formControlName="startDateTime" [attr.disabled]="!allowEditing ? '' : null" required>
      </div>
      <div class="question">
        <label>{{t('calendarItemEndDatetime')}}:</label>
        <input type="datetime-local" name="endDateTime" formControlName="endDateTime" [attr.disabled]="!allowEditing ? '' : null" required>
      </div>
    </div>
    <div class="question">
      <label>{{t('calendarItemLocation')}}:</label>
      <input type="text" name="location" formControlName="location" [attr.disabled]="!allowEditing ? '' : null">
    </div>
    <div class="questionwrap">
      <div class="question" *ngIf="eventData.userCreated">
        <label>{{t('createdBy')}}:</label>
        <div class="val val_createdBy">
          {{eventData.userCreated}}
        </div>
      </div>
      <div class="question" [ngClass]="{'fullwidth' : !eventData.userCreated}">
        <label>{{t('calendarItemFor')}}:</label>
        <ng-container *ngIf="allowEditing">
          <select name="userId" formControlName="userId" [attr.disabled]="!allowEditing ? '' : null">
            <option *ngFor="let user of calenderUsers" [value]="user.id">{{user.name}}</option>
          </select>
        </ng-container>
        <ng-container *ngIf="!allowEditing && eventData">
          <input name="userId" type="text" disabled [value]="eventData.user" [attr.disabled]="!allowEditing ? '' : null">
        </ng-container>
      </div>
    </div>
    <div class="question">
      <label>{{t('calendarItemType')}}:</label>
      <select name="typeId" formControlName="typeId" [attr.disabled]="!allowEditing ? '' : null">
        <option *ngFor="let type of eventTypes" [value]="type.uid">{{type.name}}</option>
      </select>
    </div>

    <div class="question">
      <div class="togglewrapper">
        <div class="togglewrap">
          <label>{{t('wholeDay')}}</label>
          <input type="hidden" name="wholeDay" formControlName="wholeDay" #wholeDayInput value="false">
          <app-toggle-switch [value]="eventForm.value.wholeDay" (toggled)="eventForm.patchValue({wholeDay: $event})" [disabled]="!allowEditing ? true : false"></app-toggle-switch>
        </div>
        <div class="togglewrap">
          <label>{{t('calendarItemPrivate')}}</label>
          <input type="hidden" name="private" formControlName="private" #privateInput value="false">
          <app-toggle-switch [value]="eventForm.value.private" (toggled)="eventForm.patchValue({private: $event})" [disabled]="!allowEditing ? true : false"></app-toggle-switch>
        </div>
      </div>
    </div>

    <div class="question">
      <label>{{t('calendarItemDescription')}}:</label>
      <ng-container *ngIf="allowEditing && useCke; else regularHtml">
        <ckeditor [editor]="Editor" formControlName="description"></ckeditor>
      </ng-container>
      <ng-template #regularHtml>
        <div class="calItemDescWrap">
          <div class="edit-icon" *ngIf="allowEditing" (click)="onClickEditIcon()">
            <i class="fa-solid fa-pen"></i>
          </div>
          <div class="calendar_item_description" [innerHTML]="this.eventData.description">

          </div>
        </div>
      </ng-template>
    </div>

    <div class="questionwrap">
      <div class="question">
        <label>{{t('travelTimeTo')}}:</label>
        <input type="time" min="00:15" step="900" name="travelTimeTo" formControlName="travelTimeTo" required [attr.disabled]="!allowEditing ? '' : null">
      </div>
      <div class="question">
        <label>{{t('travelTimeFrom')}}:</label>
        <input type="time" min="00:15" step="900" name="travelTimeFrom" formControlName="travelTimeFrom" required [attr.disabled]="!allowEditing ? '' : null">
      </div>
    </div>


    <div class="questionwrap" *ngIf="newEventMode">
      <div class="question">
        <label>{{t('repeatingPattern')}}</label>
        <select name="recurrenceType" formControlName="recurrenceType" [attr.disabled]="!allowEditing ? '' : null">
          <option [value]="0">-</option>
          <option *ngFor="let type of recurrenceTypes | keyvalue" [value]="type.key">{{type.value}}</option>
        </select>
      </div>
      <div class="question">
        <label>{{t('repeatingTimes')}}</label>
        <select name="repeatCount" formControlName="repeatCount" [attr.disabled]="!allowEditing ? '' : null">
          <option *ngFor="let time of recurrenceTimes" [value]="time">
            <ng-container *ngIf="time == 0">
              -
            </ng-container>
            <ng-container *ngIf="time !== 0">
              {{time}}
            </ng-container>
          </option>
        </select>
      </div>
    </div>


    <div class="question">
      <label>{{t('invitationFrom')}}</label>
      <select name="invitationFrom" formControlName="invitationFrom" [attr.disabled]="!allowEditing ? '' : null">
        <option *ngFor="let type of invitationFrom | keyvalue" [value]="type.key">{{type.value}}</option>
      </select>
    </div>

    <div class="question">
      <label>{{t('invitations', {value: totalInvitations})}}</label>
      <div class="fakeinput" (click)="showInvitationSearch = true" *ngIf="allowEditing">
        <span>{{t('invitePeople')}}</span>
        <i class="fa-solid fa-user-plus"></i>
      </div>
      <div class="spacer" *ngIf="!allowEditing">
      </div>
      <div class="invitations" *ngIf="showNewInvites">
        <div class="invitationItem" *ngFor="let invitation of newInvites; let i = index;" (click)="onRemoveInvitation(invitation)">
          <div class="icon" [ngClass]="{'accepted' : invitation.statusId == 1, 'pending' : (invitation.statusId == 0 || invitation.statusId == -1), 'declined' : invitation.statusId == 2}">
            <i class="fa-sharp fa-solid fa-circle-check" *ngIf="invitation.statusId == 1"></i>
            <i class="fa-sharp fa-solid fa-clock" *ngIf="invitation.statusId == 0 || invitation.statusId == -1"></i>
            <i class="fa-solid fa-circle-xmark" *ngIf="invitation.statusId == 2"></i>
          </div>
          <div class="name">
            {{invitation.firstName}} {{invitation.infix}} {{invitation.lastName}}
          </div>
        </div>
      </div>
      <div class="invitations" *ngIf="eventData && eventData.Invitations.length">
        <div class="invitationItem" *ngFor="let invitation of eventData.Invitations | orderBy: 'user'">
          <div class="icon" [ngClass]="{'accepted' : invitation.statusId == 1, 'pending' : invitation.statusId == 0, 'declined' : invitation.statusId == 2}">
            <i class="fa-sharp fa-solid fa-circle-check" *ngIf="invitation.statusId == 1"></i>
            <i class="fa-sharp fa-solid fa-clock" *ngIf="invitation.statusId == 0"></i>
            <i class="fa-solid fa-circle-xmark" *ngIf="invitation.statusId == 2"></i>
          </div>
          <div class="name">
            {{invitation.name}}
          </div>
        </div>
      </div>
    </div>
  </form>
  <ng-container *ngFor="let invite of eventData.Invitations">
    <div class="calendar-invite" *ngIf="invite.userId == current_user.id && invite.statusId == 0">

      <img src="/assets/images/spinner.svg" width="40" height="40" *ngIf="loadingSave" class="loading_spinner">

      <div class="invite-title" *ngIf="!loadingSave">
        {{t('nameInvitedYouToThisEvent', {value: eventData.user})}}
      </div>
      <div class="calendar-invite-buttons" *ngIf="!loadingSave">
        <button (click)="onAcceptInvite()">{{t('calendarAcceptInvite')}}</button>
        <button class="small-secundary" (click)="onDeclineInvite()">{{t('calendarDeclineInvite')}}</button>
      </div>
    </div>
    <div class="spacemaker" *ngIf="invite.userId == current_user.id && invite.statusId == 0">

    </div>
  </ng-container>
</div>
<app-invitation-person-search [eventUid]="eventUid" [currentInvitations]="eventData.Invitations" (newInvitesListener)="onNewInvites($event)" (state)="showInvitationSearch = $event" [show]="showInvitationSearch"></app-invitation-person-search>
