<div class="list">
  <div class="listpage page_{{page.page}}" *ngFor="let page of vacanciesListing;">
    <div class="list-item list-item-relation" *ngFor="let vacancy of page.items" [routerLink]="['/vacancies/' + vacancy.uid]">
      <div class="list-image" [ngStyle]="{'background-image': (vacancy.photoFileName) ? 'url('+vacancy.photoUrl+')' : ''}">
        <ng-template [ngIf]="!vacancy.photoFileName">
          <i class="fa-solid fa-briefcase"></i>
        </ng-template>
      </div>
      <div class="list-item-content">
        <div class="list-item-content-left">
          <div class="list-item-subject semibold">{{vacancy.title}}</div>
          <div class="list-item-subtitle">{{vacancy.relation}}</div>
        </div>
        <div class="list-item-content-right">
          <div class="top">
            <div class="list-item-status" *ngIf="vacancy.status">{{vacancy.status}}</div>
          </div>
          <div class="bottom">
            <div class="job-applycount"><i class="fa-solid fa-user"></i>{{vacancy.applyCount}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
