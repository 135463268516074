<div class="setting-title">
  {{title}}
</div>

<ng-container *ngIf="loaded">
  <form [formGroup]="autoresponderForm" (submit)="onSubmit()" *transloco="let t; read: 'settings'">

    <div class="question">
      <label>{{t('autoResponderSubject')}}</label>
      <input type="text" name="onderwerp" formControlName="onderwerp" required>
    </div>

    <div class="questionwrap">
      <div class="question">
        <label>{{t('autoResponderStartdate')}}</label>
        <input type="date" name="startdatum" formControlName="startdatum" required>
      </div>

      <div class="question">
        <label>{{t('autoResponderEnddate')}}</label>
        <input type="date" name="einddatum" formControlName="einddatum" required>
      </div>
    </div>

    <div class="question">
      <ckeditor [editor]="Editor" formControlName="body" name="body" required></ckeditor>
    </div>

    <div class="question">
      <label>{{t('autoResponderActivate')}}</label>
      <app-toggle-switch [value]="active" (toggled)="active = $event"></app-toggle-switch>
    </div>

    <div class="buttonwrap">
      <button type="submit">
        {{t('autoResponderSave')}}
      </button>
    </div>

  </form>
</ng-container>
