import { EmailFolderService } from './../../../core/services/email-folder.service';
import { ActivatedRoute, Router } from '@angular/router';
import { drawerListItem } from './../../../core/interfaces/drawer-list.interface';
import { mailFolder } from './../../../core/interfaces/mail-interface';
import { EmailsService } from 'src/app/core/services/emails.service';
import { Subscription } from 'rxjs';
import { Component, OnDestroy, AfterViewInit } from '@angular/core';


@Component({
  selector: 'app-email-folders',
  templateUrl: './email-folders.component.html',
  styleUrls: ['./email-folders.component.sass']
})

export class EmailFoldersComponent implements OnDestroy, AfterViewInit{

  currentFolder:string = 'Inbox';
  currentFolderId:any = -1;

  personalFolders:mailFolder[] = [];
  sharedFolders:any = [];

  $personalFoldersSubscription:Subscription;
  $sharedFoldersSubscription:Subscription;
  $emailFolderServiceSubscription1:Subscription;
  $emailFolderServiceSubscription2:Subscription;

  showDrawer:boolean = false;

  sharedFoldersAvailable:boolean = false;

  personalFoldersLoaded:boolean = false;
  sharedFoldersLoaded:boolean = false;

  folderDrawerList:drawerListItem[] = [];

  clickBehaviour:string = 'Navigate';

  constructor(private emailsService: EmailsService, private route: ActivatedRoute, private router: Router, private emailFolderService: EmailFolderService) {
    this.$emailFolderServiceSubscription1 = this.emailFolderService.drawerState.subscribe(newState => {
      this.showDrawer = newState;
    });
    this.$emailFolderServiceSubscription2 = this.emailFolderService.clickBehaviour.subscribe(newBehaviour => {
      this.clickBehaviour = newBehaviour;
    });
    this.loadPersonalFolders();
    this.loadSharedFolders();
    this.route.params.subscribe(params => {
      if(params['folder'] !== undefined){
        // If folder is present in the route and it isn't equal to the current folder
        if(this.currentFolderId != params['folder']){
          // Set this as the current folder
          this.currentFolderId = params['folder'];
          this.updateCurrentFolderTitle();
        }
      }
     });
  }

  ngAfterViewInit(): void {
    this.updateCurrentFolderTitle();
  }

  updateCurrentFolderTitle(){
    const result = this.folderDrawerList.find(({ uid }) => uid === this.currentFolderId);
    if(result){
      this.currentFolder = result.title;
    }
  }

  onDrawerOpen(){
    this.showDrawer = true;
  }

  loadPersonalFolders(){
    this.$personalFoldersSubscription = this.emailsService.getMailFolders().subscribe(response => {
      if(response && response.length){
        this.personalFolders = response;
      }
      this.personalFoldersLoaded = true;
      this.createDrawerContents();
    });
  }

  loadSharedFolders(){
    this.$sharedFoldersSubscription = this.emailsService.getSharedFolders().subscribe(response => {
      if(response && response.length){
        this.sharedFolders = response;
        this.sharedFoldersAvailable = true;
      }
      this.sharedFoldersLoaded = true;
      this.createDrawerContents();
    });
  }

  createDrawerContents(){
    this.folderDrawerList = [];
    if(this.personalFoldersLoaded && this.sharedFoldersLoaded){
      for(let personalFolder of this.personalFolders){
          this.folderDrawerList.push({
            title: personalFolder.folder,
            icon: this.getIconByid(personalFolder.uid),
            uid: personalFolder.uid
          })
      }
      for(let user of this.sharedFolders){
        for(let sharedFolder of user.folders){
          this.folderDrawerList.push({
            subTitle: user.user,
            title: sharedFolder.folder,
            icon: this.getIconByid('sharedFolder'),
            uid: sharedFolder.folderId
          })
        }
      }
    }
  }

  getIconByid(id:string){

    let returnVal = '';

    switch (id) {
      case '-1': // Inbox
        returnVal = '<i class="fa-solid fa-inbox"></i>';
        break;
      case '-2': // Send items
        returnVal = '<i class="fa-solid fa-paper-plane"></i>';
        break;
      case '-3': // Removed items
        returnVal = '<i class="fa-solid fa-trash"></i>';
        break;
      case '-4': // Concepts
        returnVal = '<i class="fa-solid fa-pen-line"></i>';
        break;
      case '-5': // Spam
        returnVal = '<i class="fa-sharp fa-solid fa-circle-xmark"></i>';
        break;
      case '-6': // Delay
        returnVal = '<i class="fa-solid fa-clock"></i>';
        break;
      case '-7': // Undeliverd
        returnVal = '<i class="fa-sharp fa-solid fa-circle-exclamation"></i>';
        break;
      case 'sharedFolder': // Undeliverd
        returnVal = '<i class="fa-solid fa-rectangle-history-circle-user"></i>';
        break;
      default:
        returnVal = '<i class="fa-solid fa-folder-open"></i>';
    }

    return returnVal;
  }


  onDrawerItemClicked(clickedUid:string){
    if(this.clickBehaviour == 'navigate'){
        this.router.navigate(['/emails/folder/' + clickedUid]);
    }else{
      this.emailFolderService.folderClicked(clickedUid);
    }
    /// Always switch back to navigate
    this.emailFolderService.updateClickBehaviour('navigate');
    this.emailFolderService.updateDrawerState(false);
  }

  onUpdateDrawerState(state){
    this.showDrawer = state;
  }

  ngOnDestroy(): void {
    if(this.$personalFoldersSubscription){
      this.$personalFoldersSubscription.unsubscribe();
    }
    if(this.$sharedFoldersSubscription){
      this.$sharedFoldersSubscription.unsubscribe();
    }
  }

}
