import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import * as Editor from 'ckeditor/build/ckeditor';

@Component({
  selector: 'app-email-composer-message',
  templateUrl: './email-composer-message.component.html',
  styleUrls: ['./email-composer-message.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class EmailComposerMessageComponent implements OnInit {

  constructor() { }

  @Output() htmlMessage: EventEmitter<any> = new EventEmitter();

  public Editor = Editor;
  public model = {
    editorData: '<p></p>'
  };

  ngOnInit(): void {
  }

  onChanges(){
    this.htmlMessage.emit(this.model.editorData);
  }

}
