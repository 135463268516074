<div class="list">
  <div class="listpage page_{{page.page}}" *ngFor="let page of reminders;">
    <div class="list-item list-item-reminder" *ngFor="let reminder of page.items" [routerLink]="['/reminders/' + reminder.uid]">
      <div class="list-image">
        <i class="fa-solid fa-bell"></i>
      </div>
      <div class="list-item-content">
        <div class="list-item-content-left">
          <div class="list-item-subject">{{reminder.title | truncate:[30, '...']}}</div>
          <div class="list-item-description" [innerHTML]="reminder.description | stripHTML | truncate:[100, '...']"></div>
        </div>
        <div class="list-item-content-right">
          <div class="top">
            <div class="list-item-date">{{reminder.dateTime | customDate}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
