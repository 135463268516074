import { RelationService } from './../../core/services/relation.service';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-relation-contact-autosuggest',
  templateUrl: './relation-contact-autosuggest.component.html',
  styleUrls: ['./relation-contact-autosuggest.component.sass']
})
export class RelationContactAutosuggestComponent implements OnChanges{

  @ViewChild('relationContactSelect') relationContactSelect:ElementRef;
  @Input() relationUid:number;
  @Output() selectedRelationContact: EventEmitter<string> = new EventEmitter();

  fieldValue:string;
  autoSuggestValues:any = [];
  showInput:boolean = true;
  result:any;

  constructor(private relationService: RelationService) { }

  ngOnChanges(): void {
    if(this.relationUid){
      this.relationService.getRelationContacts(this.relationUid).subscribe(response => {
        if(response.result && response.result.listOutput.length){
          this.autoSuggestValues = response.result.listOutput;
        }else{
          this.autoSuggestValues = [];
        }
      });
    }else{
      this.autoSuggestValues = [];
    }
  }

  onSelectItem(){
    this.selectedRelationContact.emit(this.relationContactSelect.nativeElement.value);

    this.result = {
      name : this.relationContactSelect.nativeElement.options[this.relationContactSelect.nativeElement.selectedIndex].text
    }
    this.showInput = false;
  }


  onReset(){
    this.result = null;
    this.showInput = true;
    this.selectedRelationContact.emit('');
  }

}
