<app-header *transloco="let t; read: 'emails'" listType="email" [title]="t('pageTitle') | capitalize" [searchPlaceHolder]="t('searchTitle')" [additionalSection]="appfolders" (onSearchInput)="onSearchInput($event)" [searchResults]="searchResults.asObservable()" [hideHeader]="batchMode"></app-header>

<ng-template #appfolders>
  <div class="mail_filters_wrap">
    <app-email-folders></app-email-folders>
    <app-email-flagfilter (flagFilterClicked)="onFlagFilterClicked()" [flag]="currentFlagFilter"></app-email-flagfilter>
  </div>
</ng-template>

<app-email-flag-drawer [flagDrawerOpen]="showFlagDrawer" [currentFlag]="currentFlagFilter" (flagDrawerOpenState)="showFlagDrawer = $event" (newFlag)="onNewFlag($event)"></app-email-flag-drawer>

<header class="batch-header" *ngIf="batchMode">
  <div class="batch-left">
    <div class="back-button" (click)="onExitBatchMode()">
      <i class="fa-solid fa-arrow-left"></i>
    </div>
    <div class="batch-title">
      {{this.batchItems.length}}
    </div>
  </div>
  <div class="batch-icons" *ngIf="!batchLoading">
    <div class="batch-icon" (click)="onBatchMove()">
      <i class="fa-solid fa-folder-arrow-down"></i>
    </div>
    <div class="batch-icon" (click)="onBatchRemove()">
      <i class="fa-solid fa-trash"></i>
    </div>
  </div>
  <div class="batch-loading" *ngIf="batchLoading">
    <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
      <defs>
          <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
              <stop stop-color="#0C88E0" stop-opacity="0" offset="0%"/>
              <stop stop-color="#0C88E0" stop-opacity=".631" offset="63.146%"/>
              <stop stop-color="#0C88E0" offset="100%"/>
          </linearGradient>
      </defs>
      <g fill="none" fill-rule="evenodd">
          <g transform="translate(1 1)">
              <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" stroke-width="2">
                  <animateTransform
                      attributeName="transform"
                      type="rotate"
                      from="0 18 18"
                      to="360 18 18"
                      dur="0.9s"
                      repeatCount="indefinite" />
              </path>
              <circle fill="#0C88E0" cx="36" cy="18" r="1">
                  <animateTransform
                      attributeName="transform"
                      type="rotate"
                      from="0 18 18"
                      to="360 18 18"
                      dur="0.9s"
                      repeatCount="indefinite" />
              </circle>
          </g>
      </g>
    </svg>

  </div>
</header>


<div class="page" [ngClass]="{'batchmode': batchMode,'batchloading': batchLoading}">
  <app-list [emails]="emails" (flagUpdated)="onFlagUpdated($event)" [useBatch]="true" (batchUids)="onBatchUidsUpdate($event)"></app-list>
  <app-no-results *ngIf="emails && emails.length && !emails[0].items.length && pageLoading == false"></app-no-results>
  <app-load-more-button id="emailsListLoadMore" [showLoadMoreButton]="showLoadMoreButton" (buttonClicked)="onNextPage();"></app-load-more-button>
</div>
<app-action-button-single (onClick)="onSingleActionClicked()" icon="<i class='fa-solid fa-pen'></i>" id="emailsActionButtonSingle"></app-action-button-single>
