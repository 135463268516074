import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { AuthService } from './../../../core/auth/auth.service';
import { CalendarService } from 'src/app/core/services/calendar.service';
import { Component, OnInit, ViewChild, OnDestroy, ApplicationRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AlertService } from 'src/app/core/services/alert.service';
import { User } from 'src/app/core/auth/user.model';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import * as Editor from 'ckeditor/build/ckeditor';

@Component({
  selector: 'app-event-composer',
  templateUrl: './event-composer.component.html',
  styleUrls: ['./event-composer.component.sass']
})
export class EventComposerComponent implements OnInit,OnDestroy {

  public Editor = Editor;
  public model = {
    editorData: '<p></p>'
  };
  dateNowWholeHours:Date = this.floorToNearestHour(new Date());
  dateNowWholeHoursPlus1:Date = this.ceilToNearestHour(new Date());
  current_user:User;
  eventTypes:any = [];
  recurrenceTypes:any;
  recurrenceTimes = Array(101).fill(0).map((x,i)=>i);
  invitationFrom:any;
  calenderUsers:any = [];
  eventUid:number = 0;
  newEventMode:boolean = true;

  useCke:boolean = false;

  eventForm:FormGroup = new FormGroup({
    title: new FormControl(),
    location: new FormControl(),
    description: new FormControl(),
    startDateTime: new FormControl(this.dateNowWholeHours.toISOString().slice(0, -1)),
    endDateTime: new FormControl(this.dateNowWholeHoursPlus1.toISOString().slice(0, -1)),
    typeId: new FormControl(0),
    userId: new FormControl(),
    wholeDay: new FormControl(false),
    private: new FormControl(false),
    travelTimeTo: new FormControl('00:00'),
    travelTimeFrom: new FormControl('00:00'),
    recurrenceType: new FormControl(0),
    invitationFrom: new FormControl(1),
    repeatCount: new FormControl(0)
  });

  loadingEvent:boolean = true;
  loadingSave:boolean = false;
  showInvitationSearch:boolean = false;
  allowEditing:boolean = false;
  eventData:any = {
    Invitations: []
  };
  totalInvitations:number = 0;
  newInvites:any;
  showNewInvites:boolean = true;
  hasFutureEvents:boolean = false;

  $subscription1:Subscription;
  $subscription2:Subscription;
  $subscription3:Subscription;
  $subscription4:Subscription;
  $subscription5:Subscription;
  $subscription6:Subscription;
  $subscription7:Subscription;
  $subscription8:Subscription;
  $subscription9:Subscription;
  $subscription10:Subscription;
  $subscription11:Subscription;
  $subscription12:Subscription;
  $subscription13:Subscription;

  constructor(private alertService: AlertService, private calendarService: CalendarService, private authService: AuthService, private router: Router, private route: ActivatedRoute, private datePipe: DatePipe, private applicationRef: ApplicationRef, private translationService: TranslocoService) {
    this.$subscription1 = this.authService.user.subscribe(res => {
      this.current_user = res;
    })

    this.$subscription2 = this.route.params.subscribe((params) => {
      if (params['uid']) {
        // If uid is present in the route and it isn't equal to the current uid
        if (this.eventUid != parseInt(params['uid'])) {
          // Set this as the current uid
          this.eventUid = parseInt(params['uid']);
          this.newEventMode = false;
        }
      }
    });

    this.$subscription3 = this.route.queryParams.subscribe(queryParams => {
      if(queryParams['day']){
        // Get the URL from the date
        let dateFromUrlStart = new Date(queryParams['day']);
        // Do some stupid timezone shit
        dateFromUrlStart.setMinutes(dateFromUrlStart.getMinutes() - dateFromUrlStart.getTimezoneOffset());
        let dateFromUrlEnd = new Date(queryParams['day']);
        dateFromUrlEnd.setMinutes(dateFromUrlEnd.getMinutes() - dateFromUrlEnd.getTimezoneOffset());
        // Now add an hour so by default an meeting shoud last een hour
        dateFromUrlEnd.setHours(dateFromUrlEnd.getHours() + 1);
        // Asign the variable
        var start = dateFromUrlStart;
        var end = dateFromUrlEnd;
        // Patch it on the form
        this.eventForm.patchValue({
          startDateTime: start.toISOString().slice(0,16),
          endDateTime: end.toISOString().slice(0,16),
        });
      }
      if (queryParams['mode'] && queryParams['mode'] == 'allday') {
        this.eventForm.patchValue({
          wholeDay: true
        });
      }
    });

  }


  ngOnInit(): void {
    this.watchDates();
    this.getTypes();
    this.getRecurrenceTypes();
    this.getWebsites();
    this.getUsers();
    if(this.newEventMode === false){
      this.loadingEvent = true;
      this.getEventData();
    }else{
      this.loadingEvent = false;
      this.allowEditing = true;
    }
  }

  getEventData(){
    this.$subscription4 =this.calendarService.getEvent(this.eventUid).subscribe(response => {
      if(response && response.result){

        this.eventData = response.result;
        if(this.eventData.hasFutureEvents){
          this.hasFutureEvents = true;
        }else{
          this.hasFutureEvents = false;
        }
        let calItem = response.result;
        if(!calItem.travelTimeFrom){
          calItem.travelTimeFrom = '00:00';
        }
        if(!calItem.travelTimeTo){
          calItem.travelTimeTo = '00:00';
        }
        calItem.startDateTime = this.datePipe.transform(calItem.startDateTime, 'YYYY-MM-ddTHH:mm');
        calItem.endDateTime = this.datePipe.transform(calItem.endDateTime, 'YYYY-MM-ddTHH:mm');
        this.eventForm.patchValue(calItem);
        this.loadingEvent = false;

        this.totalInvitations = this.eventData.Invitations.length;

        if(this.current_user.id == this.eventData.userId){
          // Allow edit
          this.allowEditing = true;
        }else{
          if(this.calenderUsers && this.calenderUsers.length){
            // Check for rights
            if(this.calenderUsers.find(x => x.id === this.eventData.userId)){
              // Allow edit
              this.allowEditing = true;
            }else{
                // Do not allow editing
                this.allowEditing = false;
            }
          }else{
            // Do not allow editing
            this.allowEditing = false;
          }
        }


        if(this.allowEditing == true){
          if(!this.eventData.description){
            this.useCke = true;
          }
        }

      }
    });
  }

  onClickEditIcon(){
    this.useCke = true;
  }

  getTypes(){
    this.$subscription5 = this.calendarService.getTypes().subscribe(response => {
      if(response){
        let typesObj = response;
        let typesArray = Object.entries(typesObj).map(([key, value]) => ({key, value}));
        let componentScope = this;
        typesArray.forEach(function(type){
          componentScope.eventTypes.push({
            uid: type.key,
            name: type.value['name']
          })
        });
      }
    })
  }

  getRecurrenceTypes(){
    this.$subscription11 = this.calendarService.getRecurrenceTypes().subscribe(response => {
      if(response){
        this.recurrenceTypes = response;
      }
    });
  }

  getWebsites(){
    this.$subscription13 = this.calendarService.getWebsites().subscribe(response => {
      if(response){
        this.invitationFrom = response;
      }
    });
  }

  getUsers(){
    this.$subscription6 = this.calendarService.getUsers().subscribe(response => {
      if(response && response.result && response.result.users){
        this.calenderUsers = response.result.users;
        if(this.newEventMode){
          // Set current user as a default value
          this.eventForm.patchValue({
            userId: this.current_user.id
          })
        }
      }
    });
  }

  watchDates(){
    this.$subscription7 = this.eventForm.valueChanges.subscribe(form => {
      var start:any = new Date(form.startDateTime);
      var end:any = new Date(form.endDateTime);
      if(start >= end){
        // Get the hour difference between the 2 date-times
        // And add this difference + 1 extra hour to it
        var hoursDif = Math.abs(start - end) / 36e5;
        var marginBetween = hoursDif + 1;
        // Add the margin to the end date
        end.setHours(end.getHours() + marginBetween);
        // Do some stupid timezone shizzle
        start.setMinutes(start.getMinutes() - start.getTimezoneOffset());
        end.setMinutes(end.getMinutes() - end.getTimezoneOffset());
        // Patch the new value
        this.eventForm.patchValue({
          startDateTime: start.toISOString().slice(0,16),
          endDateTime: end.toISOString().slice(0,16),
        });
      }
   })
  }

  onSaveEvent(){
    this.loadingSave = true;

    if(this.eventForm.valid){
      if(this.newEventMode){
        this.$subscription8 = this.calendarService.addEvent(this.eventForm.value).subscribe(response => {
         if(response.result){

            if(this.newInvites){
              for(let invite of this.newInvites){

                this.calendarService.bindInvite(response.result,invite).subscribe(res => {

                });
              }
              this.newInvites = [];
            }

            this.alertService.success(this.translationService.translate('calendar.newItemCreatedSuccess'));
            this.router.navigate(['/calendar/']);
            this.loadingSave = false;
          }
        });
      }else{
        this.$subscription9 = this.calendarService.saveEvent(this.eventUid, this.eventForm.value,this.newInvites).subscribe(response => {
          if(response.result){

            if(this.newInvites){
              for(let invite of this.newInvites){

                this.calendarService.bindInvite(this.eventUid,invite).subscribe(res => {

                });
              }
              this.newInvites = [];
            }

            this.alertService.success(this.translationService.translate('calendar.changesSavedSuccess'));
            this.router.navigate(['/calendar/']);
            this.loadingSave = false;
          }
        });
      }
    }else{
      this.alertService.error(this.translationService.translate('shared.fillRequiredFields'));
      this.loadingSave = false;
    }
  }

  onDeleteEvent(){
    if(this.eventUid !== 0){
      this.$subscription10 = this.calendarService.deleteEvent(this.eventUid).subscribe(response => {
        if(response){
          this.alertService.success(this.translationService.translate('calendar.eventRemovedSuccess'));
          this.router.navigate(['/calendar/']);
        }else{
          this.alertService.error(this.translationService.translate('calendar.cantRemoveEvent'));
        }
      });
    }
  }

  onDeleteAllEvents(){
    if(this.eventUid !== 0){
      this.$subscription12 = this.calendarService.deleteAllEvents(this.eventUid).subscribe(response => {
        if(response){
          this.alertService.success(this.translationService.translate('calendar.eventRemovedSuccess'));
          this.router.navigate(['/calendar/']);
        }else{
          this.alertService.error(this.translationService.translate('calendar.cantRemoveEvent'));
        }
      });
    }
  }

  ceilToNearestHour(date:Date) {
    date.setMinutes(date.getMinutes() + 30);
    date.setHours(date.getHours() + 2);
    date.setMinutes(0, 0, 0);
    return date;
  }
  floorToNearestHour(date:Date) {
    date.setHours(date.getHours()  + 2);
    date.setMinutes(date.getMinutes() - 30);
    date.setMinutes(0, 0, 0);
    return date;
  }

  onNewInvites(invites){
    this.newInvites = invites;
    this.triggerChange()
  }


  onAcceptInvite(){
    this.loadingSave = true;
    this.calendarService.respondToInvite(this.eventUid, this.current_user.id, 1).subscribe(res => {
        this.getEventData();
        this.loadingSave = false;
    });
  }

  onDeclineInvite(){
    this.loadingSave = true;
    this.calendarService.respondToInvite(this.eventUid, this.current_user.id, 2).subscribe(res => {
        this.getEventData();
        this.loadingSave = false;
    });
  }

  triggerChange(){
    // For some reason angular doesnt detect changes all the time
    this.showNewInvites = false;
    this.applicationRef.tick();
    this.showNewInvites = true;
  }


  ngOnDestroy(): void {
    if(this.$subscription1)
      this.$subscription1.unsubscribe();
    if(this.$subscription2)
      this.$subscription2.unsubscribe();
    if(this.$subscription3)
      this.$subscription3.unsubscribe();
    if(this.$subscription4)
      this.$subscription4.unsubscribe();
    if(this.$subscription5)
      this.$subscription5.unsubscribe();
    if(this.$subscription6)
      this.$subscription6.unsubscribe();
    if(this.$subscription7)
      this.$subscription7.unsubscribe();
    if(this.$subscription8)
      this.$subscription8.unsubscribe();
    if(this.$subscription9)
      this.$subscription9.unsubscribe();
    if(this.$subscription10)
      this.$subscription10.unsubscribe();
    if(this.$subscription11)
      this.$subscription11.unsubscribe();
    if(this.$subscription12)
      this.$subscription12.unsubscribe();
    if(this.$subscription13)
      this.$subscription13.unsubscribe();
  }

}
