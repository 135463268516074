import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-detailpage-item-value',
  templateUrl: './detailpage-item-value.component.html',
  styleUrls: ['./detailpage-item-value.component.sass']
})
export class DetailpageItemValueComponent{
  @Input() label:string = '';
  @Input() value:string = '';
}
