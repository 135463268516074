import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-load-more-button',
  templateUrl: './load-more-button.component.html',
  styleUrls: ['./load-more-button.component.sass']
})
export class LoadMoreButtonComponent{

  @Input() showLoadMoreButton:boolean = false;
  @Output() buttonClicked: EventEmitter<void> = new EventEmitter();

  constructor() { }

  onButtonClicked(){
    this.buttonClicked.emit();
  }

}
