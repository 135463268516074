import { TranslocoService } from '@ngneat/transloco';
import { User } from 'src/app/core/auth/user.model';
import { AuthService } from 'src/app/core/auth/auth.service';
import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'customDate'
})

export class CustomDate implements PipeTransform {

  user:User;

  constructor(private authService: AuthService, private translationService: TranslocoService){
    this.authService.user.subscribe(res => {
      this.user = res;
    })
  }

  transform(value: string) {

    // Define strings for when wording is used
    let yesterday_text = this.translationService.translate('shared.yesterday');
    let tomorrow_text = this.translationService.translate('shared.tomorrow');

    // Get the OTYS date and transform into something the Date function can understand
    var input_nice = value.slice(0, 4) + "-" + value.slice(4);
    input_nice = input_nice.slice(0, 7) + "-" + input_nice.slice(7);
    input_nice = input_nice;
    var inputDate = new Date(input_nice);

    // Define some moments in time so we can compare the to-be-transformed date to
    let todaysDate = new Date();
    let yesterday = new Date((new Date()).valueOf() - 1000*60*60*24);
    let tomorrow = new Date((new Date()).valueOf() + 1000*60*60*24);
    let input_year = inputDate.getFullYear();
    let current_year = todaysDate.getFullYear();

    // The default format
    var format = "dd-LL-YYYY H:mm";
    if(this.user.defaultContentLanguage && this.user.defaultCountry){
      var locale = this.user.defaultContentLanguage + '-' + this.user.defaultCountry;
    }else{
      var locale = 'nl-NL';
    }

    var response = formatDate(value, format, locale , undefined)

    if(inputDate.setHours(0,0,0,0) == todaysDate.setHours(0,0,0,0)){
      var format = "H:mm";
      var response = formatDate(value, format, locale, undefined)
    // Yesterday
    }else if(inputDate.setHours(0,0,0,0) == yesterday.setHours(0,0,0,0)){
      var format = "H:mm";
      var response = yesterday_text + ' ' + formatDate(value, format, locale, undefined)
    // Tomorrow
    }else if(inputDate.setHours(0,0,0,0) == tomorrow.setHours(0,0,0,0)){
      var format = "H:mm";
      var response = tomorrow_text + ' ' + formatDate(value, format, locale, undefined)
    }else{
      // If the date is within the past of this year we dont need to show the year
      if(input_year == current_year){
        var format = "dd-LL H:mm";
        var response = formatDate(value, format, locale, undefined)
      }
    }
    return response;
  }


}
