<div class="search-overlay" *ngIf="show">
  <div class="search-overlay-header" *transloco="let t; read: 'calendar'">
    <div class="searchbar-wrap">
      <a class="search-back-icon" (click)="goBack()">
        <i class="fa-solid fa-chevron-left"></i>
      </a>
      <input type="text" [placeholder]="t('keywords')" (keyup)="onKeyUp()" name="keywordinput" ngModel #keywordsInput>
      <div class="done-trigger" (click)="goBack()">
        {{t('doneAddingInvitations')}}
      </div>
    </div>
  </div>
  <div class="search-results-wrap">

    <div class="invitations" *ngIf="showInvitations">
      <div class="invitationItem" *ngFor="let invitation of newInvites; let i = index;" (click)="onRemoveInvitation(invitation)">
        <div class="icon" [ngClass]="{'accepted' : invitation.statusId == 1, 'pending' : (invitation.statusId == 0 || invitation.statusId == -1), 'declined' : invitation.statusId == 2}">
          <i class="fa-sharp fa-solid fa-circle-check" *ngIf="invitation.statusId == 1"></i>
          <i class="fa-sharp fa-solid fa-clock" *ngIf="invitation.statusId == 0 || invitation.statusId == -1"></i>
          <i class="fa-solid fa-circle-xmark" *ngIf="invitation.statusId == 2"></i>
        </div>
        <div class="name">
          {{invitation.firstName}} {{invitation.infix}} {{invitation.lastName}}
        </div>
      </div>
    </div>

    <app-calendar-invitation-contact-list [contacts]="[listItems]" (clickedContact)="onContactClicked($event)"></app-calendar-invitation-contact-list>

  </div>
</div>
