import { TranslocoService } from '@ngneat/transloco';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { drawerListItem } from 'src/app/core/interfaces/drawer-list.interface';

@Component({
  selector: 'app-email-controls',
  templateUrl: './email-controls.component.html',
  styleUrls: ['./email-controls.component.sass']
})
export class EmailControlsComponent{

  constructor(private translationService: TranslocoService) { }

  @Input() mailUid:any;
  @Output() onMailActionTrigger: EventEmitter<any> = new EventEmitter();

  moreOptionsShow:boolean = false;
  drawerList:drawerListItem[] = [
    {
      title: this.translationService.translate('emails.forward'),
      icon: '<i class="fa-solid fa-share"></i>',
      uid: 'forward'
    },
    {
      title: this.translationService.translate('emails.reply'),
      icon:  '<i class="fa-solid fa-reply"></i>',
      uid: 'reply'
    },
    {
      title: this.translationService.translate('emails.replyAll'),
      icon:  '<i class="fa-solid fa-reply-all"></i>',
      uid: 'replyall'
    },
    {
      title: this.translationService.translate('emails.remove'),
      icon:  '<i class="fa-solid fa-trash"></i>',
      uid: 'remove'
    },
    {
      title: this.translationService.translate('emails.moveToFolder'),
      icon: '<i class="fa-solid fa-folder-arrow-down"></i>',
      uid: 'move'
    },
    {
      title: this.translationService.translate('emails.markAsUnread'),
      icon: '<i class="fa-solid fa-eye-slash"></i>',
      uid: 'markasunread'
    }
  ];

  onUpdateDrawerState(state:boolean){
    this.moreOptionsShow = state;
  }

  onMailAction(event:any){
    this.onMailActionTrigger.emit(event);
  }
}
