import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-verify-token',
  templateUrl: './verify-token.component.html',
  styleUrls: ['./verify-token.component.sass']
})
export class VerifyTokenComponent {

  constructor(private authService:AuthService, private router: Router){
    this.checkToken();
  }

  checkToken(){
    let ssoToken = localStorage.getItem('sso_token');
    if(ssoToken){
      this.authService.verifyToken(ssoToken).subscribe(response => {
        if(response){

          let resResult = response.result;
          var sessionId = '---';
          if(resResult && resResult.user_session_id){
            // When a sessionID was sen't back, we can remove the token from localstorage, because the OWS service only returns a sessionID once.
            localStorage.removeItem('sso_token');
            // Set the variable sessionId to the actual returned sessionId
            sessionId = resResult.user_session_id;
          }
          // Redirect to the login page, and pass the sessionid as a hidden variable
          this.router.navigate(['/login'], {
            state: {sessionId : sessionId}
          });
        }
      });
    }else{
      // No sso token found in the local storage, so no reason to be here
      this.router.navigate(['/login']);
    }
  }
}
