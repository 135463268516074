<div class="list">
  <div class="listpage page_{{page.page}}" *ngFor="let page of relationContactsListing;">
    <div class="list-item list-item-relation" *ngFor="let contact of page.items" [routerLink]="['/relation-contacts/' + contact.uid]">
      <div class="list-image" [ngStyle]="{'background-image': (contact.Person.photoFileName) ? 'url('+contact.photoUrl+')' : ''}">
        <ng-template [ngIf]="!contact.Person.photoFileName">
          <span *ngIf="contact.fullname">
            {{ contact.fullname | getInitials}}
          </span>
        </ng-template>
      </div>
      <div class="list-item-content">
        <div class="list-item-content-left">
          <div class="list-item-subject semibold">{{contact.fullname}}</div>
          <div class="list-item-subtitle">{{contact.relation}}</div>
        </div>
        <div class="list-item-content-right">
          <div class="top">
            <div class="list-item-status" *ngIf="contact.status">{{contact.status.name}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
