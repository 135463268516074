import { EmailsService } from './../../../../core/services/emails.service';
import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  ViewChild,
  ElementRef,
} from '@angular/core';

interface autoSuggestValue {
  name: string;
  fullName: string;
  email: string;
  entity: number;
  emailParsed: string;
}

interface autoSuggestValues {
  to: autoSuggestValue[];
  cc: autoSuggestValue[];
}

@Component({
  selector: 'app-email-composer-form',
  templateUrl: './email-composer-form.component.html',
  styleUrls: ['./email-composer-form.component.sass'],
})

export class EmailComposerFormComponent implements OnInit {

  @ViewChild('toInput') toInput: ElementRef;
  @ViewChild('autoSuggestTo') autoSuggestTo:ElementRef;
  @ViewChild('autoSuggestCc') autoSuggestCc:ElementRef;

  // Define input
  @Input() toValues: string[];
  @Input() ccValues: string[];
  @Input() subjectValue: string;

  // Define output emitters
  @Output() toValuesChange: EventEmitter<string[]> = new EventEmitter();
  @Output() ccValuesChange: EventEmitter<string[]> = new EventEmitter();
  @Output() subjectValueChange: EventEmitter<string> = new EventEmitter();


  // Define the simple single text input elements
  to: string;
  cc: string;

  // Define other variables
  autoSuggestValues: autoSuggestValues = {
    to: [],
    cc: [],
  };

  constructor(private emailsService: EmailsService) {}

  ngOnInit(): void {
  }

  onToCheck() {
    // Check is the input value is valid according to the regex in validateEmail
    if (this.validateEmail(this.to)) {
      // When it's not already within the array of values add it
      if (!this.toValues.includes(this.to)) {
        this.toValues.push(this.to);
      }
      // Clear the input after the email is added
      this.to = '';
    }

    this.toValuesChange.emit(this.toValues);
  }
  onCcCheck() {
    // Check is the input value is valid according to the regex in validateEmail
    if (this.validateEmail(this.cc)) {
      // When it's not already within the array of values add it
      if (!this.ccValues.includes(this.cc)) {
        this.ccValues.push(this.cc);
      }
      // Clear the input after the email is added
      this.cc = '';
    }
    this.ccValuesChange.emit(this.ccValues);
  }

  onToKeyUp() {
    if (this.to.length > 2) {
      this.emailsService.getAutoSuggestionsTo(this.to).subscribe((response) => {
        if (response && response.result) {
          this.autoSuggestValues.to = response.result;
        } else {
          this.autoSuggestValues.to = [];
        }
      });
    } else {
      this.autoSuggestValues.to = [];
    }

    this.autoSuggestValues.cc = [];
  }

  onCcKeyUp() {
    if (this.cc && this.cc.length > 2) {
      this.emailsService.getAutoSuggestionsCc(this.cc).subscribe((response) => {
        if (response && response.result) {
          this.autoSuggestValues.cc = response.result;
        } else {
          this.autoSuggestValues.cc = [];
        }
      });
    } else {
      this.autoSuggestValues.cc = [];
    }

    this.autoSuggestValues.to = [];
  }

  onSubjectChange() {
    this.subjectValueChange.emit(this.subjectValue);
  }

  onToSelectPerson(person: autoSuggestValue) {
    this.to = person.email;
    this.onToCheck();
    this.autoSuggestValues.to = [];
  }

  onCcSelectPerson(person: autoSuggestValue) {
    this.cc = person.email;
    this.onCcCheck();
    this.autoSuggestValues.cc = [];
  }

  validateEmail(email: string) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  // When an email adress is clicked remove it from the array
  onRemoveToValue(key: number) {
    this.toValues.splice(key, 1);
  }
  // When an email adress is clicked remove it from the array
  onRemoveCcValue(key: number) {
    this.ccValues.splice(key, 1);
  }
}
