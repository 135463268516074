import { BehaviorSubject } from 'rxjs';
import { Injectable, OnInit } from "@angular/core";

@Injectable()
export class EmailFolderService implements OnInit{

  constructor(){}

  ngOnInit(): void {
    //
  }

  public drawerState = new BehaviorSubject<boolean>(false);
  public clickBehaviour = new BehaviorSubject<string>('navigate');
  public clickedItem = new BehaviorSubject<string>(null);

  updateDrawerState(newState:boolean){
    this.drawerState.next(newState);
  }

  updateClickBehaviour(newBehaviour:string){
    this.clickBehaviour.next(newBehaviour);
  }

  resetClickedItem(){
    this.clickedItem.next(null);
  }

  folderClicked(item:string){
    this.drawerState.next(false);
    this.clickedItem.next(item);
  }

}

