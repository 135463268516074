import { TaskService } from './core/services/task.service';
import { CandidateService } from 'src/app/core/services/candidate.service';
import { ReminderService } from './core/services/reminder.service';
import { EmailsService } from 'src/app/core/services/emails.service';
import { CachingService } from './core/services/caching.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { DateConverterPipe } from './core/pipes/dateconverter.pipe';
import { InsideViewportDirective } from './core/directives/inside-viewport.directive';
import { OutsideClickDirective } from './core/directives/outside-click.directive';
import { AuthComponent } from './core/auth/auth.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuard } from './core/guards/auth.guard';
import { EmailsComponent } from './modules/emails/emails.component';
import { InterceptorService } from './core/services/interceptor.service';
import { NavigationComponent } from './shared/navigation/navigation.component';
import { MailwidgetComponent } from './modules/dashboard/components/mailwidget/mailwidget.component';

import { CalendarComponent } from './modules/calendar/calendar.component';
import { CandidatesComponent } from './modules/candidates/candidates.component';
import { VacanciesComponent } from './modules/vacancies/vacancies.component';

import { SettingsComponent } from './modules/settings/settings.component';
import { ListComponent } from './modules/emails/list/list.component';
import { HeaderComponent } from './shared/header/header.component';

import { StripHTML } from './core/pipes/striphtml.pipe';
import { GetInitialsPipe } from './core/pipes/initials.pipe';
import { CustomDate } from './core/pipes/customdate.pipe';

import { DatePipe, registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeNl from '@angular/common/locales/nl';
import localeDe from '@angular/common/locales/de';
import localeCs from '@angular/common/locales/cs';
import localeFr from '@angular/common/locales/fr';
import { RelationsComponent } from './modules/relations/relations.component';
import { RelationsContactsComponent } from './modules/relations-contacts/relations-contacts.component';
import { RemindersComponent } from './modules/reminders/reminders.component';
import { ActionButtonSingleComponent } from './shared/action-button-single/action-button-single.component';
import { ActionButtonMultipleComponent } from './shared/action-button-multiple/action-button-multiple.component';
import { SwiperModule } from 'swiper/angular';
import { EmailDetailComponent } from './modules/emails/email-detail/email-detail.component';
import { BackButtonComponent } from './shared/back-button/back-button.component';
import { DrawerComponentComponent } from './shared/drawer-component/drawer-component.component';
import { EmailControlsComponent } from './modules/emails/email-detail/email-controls/email-controls.component';
import { EmailInformationComponent } from './modules/emails/email-detail/email-information/email-information.component';
import { TopButtonsComponent } from './modules/calendar/top-buttons/top-buttons.component';
import { DaySliderComponent } from './modules/calendar/day-slider/day-slider.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { EmailComposerComponent } from './modules/emails/email-composer/email-composer.component';
import { EmailComposerHeaderComponent } from './modules/emails/email-composer/email-composer-header/email-composer-header.component';
import { EmailComposerFormComponent } from './modules/emails/email-composer/email-composer-form/email-composer-form.component';
import { EmailComposerMessageComponent } from './modules/emails/email-composer/email-composer-message/email-composer-message.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { EmailFlagUpdateDrawerComponent } from './modules/emails/email-shared/email-flag-update-drawer/email-flag-update-drawer.component';
import { ToggleSwitchComponent } from './shared/toggle-switch/toggle-switch.component';
import { AlertComponent } from './shared/alert/alert.component';
import { SearchResultListComponent } from './shared/header/search-result-list/search-result-list.component';
import { CalendarInviteComponent } from './modules/emails/email-detail/calendar-invite/calendar-invite.component';
import { ReminderwidgetComponent } from './modules/dashboard/components/reminderwidget/reminderwidget.component';
import { TruncatePipe } from './core/pipes/truncate.pipe';
import { ReminderDetailComponent } from './modules/reminders/reminder-detail/reminder-detail.component';
import { ReminderComposerComponent } from './modules/reminders/reminder-composer/reminder-composer.component';
import { PressAndHoldDirective } from './core/directives/press-and-hold.directive';
import { ReminderListComponent } from './modules/reminders/reminder-list/reminder-list.component';
import { LoadMoreButtonComponent } from './shared/load-more-button/load-more-button.component';
import { RelationListComponent } from './modules/relations/relation-list/relation-list.component';
import { DashboardContactlistComponent } from './modules/dashboard/dashboard-contactlist/dashboard-contactlist.component';
import { RelationDetailComponent } from './modules/relations/relation-detail/relation-detail.component';
import { PhoneNumberWithIconsComponent } from './shared/phone-number-with-icons/phone-number-with-icons.component';
import { MailWithIconComponent } from './shared/mail-with-icon/mail-with-icon.component';
import { AdressWithIconComponent } from './shared/adress-with-icon/adress-with-icon.component';
import { DetailpageItemValueComponent } from './shared/detailpage-item-value/detailpage-item-value.component';
import { DossierTabComponent } from './shared/dossier-tab/dossier-tab.component';
import { DossierDocumentuploadComponent } from './modules/dossier/dossier-documentupload/dossier-documentupload.component';
import { RelationsContactListComponent } from './modules/relations-contacts/relations-contact-list/relations-contact-list.component';
import { RelationsContactDetailComponent } from './modules/relations-contacts/relations-contact-detail/relations-contact-detail.component';
import { VacanciesListComponent } from './modules/vacancies/vacancies-list/vacancies-list.component';
import { VacancyDetailComponent } from './modules/vacancies/vacancy-detail/vacancy-detail.component';
import { ProfilePictureComponent } from './shared/profile-picture/profile-picture.component';
import { NoResultsComponent } from './shared/no-results/no-results.component';
import { CreateVacancyComponent } from './modules/vacancies/create-vacancy/create-vacancy.component';
import { InteractionsComponent } from './modules/interactions/interactions.component';
import { FillInFormComponent } from './modules/interactions/fill-in-form/fill-in-form.component';
import { CreateRelationComponent } from './modules/relations/create-relation/create-relation.component';
import { RelationAutosuggestComponent } from './shared/relation-autosuggest/relation-autosuggest.component';
import { RelationContactAutosuggestComponent } from './shared/relation-contact-autosuggest/relation-contact-autosuggest.component';
import { NgPipesModule } from 'ngx-pipes';
import { CandidateListComponent } from './modules/candidates/candidate-list/candidate-list.component';
import { CandidateDetailComponent } from './modules/candidates/candidate-detail/candidate-detail.component';
import { CandidateCriteriaItemComponent } from './shared/candidate-criteria-item/candidate-criteria-item.component';
import { DossierComponent } from './modules/dossier/dossier.component';
import { ViewFileComponent } from './modules/dossier/view-file/view-file.component';
import { CandidatewidgetComponent } from './modules/dashboard/components/candidatewidget/candidatewidget.component';
import { CreateCandidateComponent } from './modules/candidates/create-candidate/create-candidate.component';
import { PictureDetailComponent } from './shared/picture-detail/picture-detail.component';
import { EventComposerComponent } from './modules/calendar/event-composer/event-composer.component';
import { EventListComponent } from './modules/calendar/event-list/event-list.component';
import { StoredqueriesDrawerComponent } from './shared/storedqueries-drawer/storedqueries-drawer.component';
import { SortablejsModule } from 'ngx-sortablejs';
import { WhatsappComponent } from './modules/whatsapp/whatsapp.component';
import { SanitizeHtmlPipe } from './core/pipes/sanitizeHtml.pipe';
import { FavoriteStarComponent } from './shared/favorite-star/favorite-star.component';
import { TranslocoRootModule } from './transloco-root.module';
import { TranslocoService } from '@ngneat/transloco';
import { CapitalizePipe } from './core/pipes/capitalize.pipe';
import { InvitationPersonSearchComponent } from './modules/calendar/event-composer/invitation-person-search/invitation-person-search.component';
import { CalendarInvitationContactListComponent } from './modules/calendar/event-composer/invitation-person-search/calendar-invitation-contact-list/calendar-invitation-contact-list.component';
import { VacancyPublicationFormComponent } from './modules/vacancies/vacancy-detail/vacancy-publication-form/vacancy-publication-form.component';
import { DossierCreatenoteComponent } from './modules/dossier/dossier-createnote/dossier-createnote.component';
import { NoteComponent } from './modules/dossier/note/note.component';
import { EmailFoldersComponent } from './modules/emails/email-folders/email-folders.component';
import { EmailFlagfilterComponent } from './modules/emails/email-flagfilter/email-flagfilter.component';
import { EmailFlagDrawerComponent } from './modules/emails/email-flagfilter/email-flag-drawer/email-flag-drawer.component';
import { SettingTabComponent } from './modules/settings/setting-tab/setting-tab.component';
import { AutoresponderComponent } from './modules/settings/autoresponder/autoresponder.component';
import { CalendarService } from './core/services/calendar.service';
import { DashBoardContactsService } from './core/services/dashboard-contact.service';
import { DossierService } from './core/services/dossier.service';
import { EmailFolderService } from './core/services/email-folder.service';
import { FavoriteService } from './core/services/favorite.service';
import { FileUploadService } from './core/services/file-upload.service';
import { InteractionService } from './core/services/interaction.service';
import { LoaderService } from './core/services/loader.service';
import { RelationContactService } from './core/services/relation-contact.service';
import { RelationService } from './core/services/relation.service';
import { SettingsService } from './core/services/settings.service';
import { StoredQueryTransformerService } from './core/services/storedquery-transformer.service';
import { StoredqueryService } from './core/services/storedquery.service';
import { VacancyService } from './core/services/vacancy.service';
import { WhatsappService } from './core/services/whatsapp.service';
import { HashingService } from './core/services/hashing.service';
import { TasksComponent } from './modules/tasks/tasks.component';
import { TaskDetailComponent } from './modules/tasks/task-detail/task-detail.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';
import { VerifyTokenComponent } from './core/auth/verify-token/verify-token.component';
import {AuthKeysService} from "./core/auth/auth-keys.service";

registerLocaleData(localeNl);
registerLocaleData(localeDe);
registerLocaleData(localeCs);
registerLocaleData(localeFr);


export function preloadUser(transloco: TranslocoService) {
  return function() {
    var langauge = 'en';
    if(localStorage.getItem('user')){
      let userObj = JSON.parse(localStorage.getItem('user'));
      if(userObj.defaultContentLanguage){
        if(userObj.languageSet !== 0){
          langauge = userObj.languageSet + '_' + userObj.defaultContentLanguage;
        }else{
          langauge = userObj.defaultContentLanguage;
        }
      }
    }else if(localStorage.getItem('lastLanguage')){
      let langi = localStorage.getItem('lastLanguage');
      let languageSet = localStorage.getItem('lastLanguageSet');
      if(languageSet !== '0' && languageSet !== null){
        langauge = languageSet + '_' + langi;
      }else{
        langauge = langi;
      }
    }
    transloco.setActiveLang(langauge);
    return transloco.load(langauge).toPromise();
  };
}

export const preLoad = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: preloadUser,
  deps: [TranslocoService, CachingService]
};

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    AuthComponent,
    EmailsComponent,
    NavigationComponent,
    MailwidgetComponent,
    GetInitialsPipe,
    CapitalizePipe,
    SanitizeHtmlPipe,
    DateConverterPipe,
    StripHTML,
    CustomDate,
    CalendarComponent,
    CandidatesComponent,
    VacanciesComponent,
    SettingsComponent,
    ListComponent,
    HeaderComponent,
    RelationsComponent,
    RelationsContactsComponent,
    RemindersComponent,
    ActionButtonSingleComponent,
    ActionButtonMultipleComponent,
    EmailDetailComponent,
    BackButtonComponent,
    DrawerComponentComponent,
    EmailControlsComponent,
    EmailInformationComponent,
    TopButtonsComponent,
    DaySliderComponent,
    EmailComposerComponent,
    EmailComposerHeaderComponent,
    EmailComposerFormComponent,
    EmailComposerMessageComponent,
    OutsideClickDirective,
    EmailFlagUpdateDrawerComponent,
    ToggleSwitchComponent,
    AlertComponent,
    SearchResultListComponent,
    CalendarInviteComponent,
    ReminderwidgetComponent,
    TruncatePipe,
    PressAndHoldDirective,
    InsideViewportDirective,
    ReminderDetailComponent,
    ReminderComposerComponent,
    ReminderListComponent,
    LoadMoreButtonComponent,
    RelationListComponent,
    DashboardContactlistComponent,
    RelationDetailComponent,
    PhoneNumberWithIconsComponent,
    MailWithIconComponent,
    AdressWithIconComponent,
    DetailpageItemValueComponent,
    DossierTabComponent,
    DossierDocumentuploadComponent,
    RelationsContactListComponent,
    RelationsContactDetailComponent,
    VacanciesListComponent,
    VacancyDetailComponent,
    ProfilePictureComponent,
    NoResultsComponent,
    CreateVacancyComponent,
    InteractionsComponent,
    FillInFormComponent,
    CreateRelationComponent,
    RelationAutosuggestComponent,
    RelationContactAutosuggestComponent,
    CandidateListComponent,
    CandidateDetailComponent,
    CandidateCriteriaItemComponent,
    DossierComponent,
    ViewFileComponent,
    CandidatewidgetComponent,
    CreateCandidateComponent,
    PictureDetailComponent,
    EventComposerComponent,
    EventListComponent,
    StoredqueriesDrawerComponent,
    WhatsappComponent,
    FavoriteStarComponent,
    InvitationPersonSearchComponent,
    CalendarInvitationContactListComponent,
    VacancyPublicationFormComponent,
    DossierCreatenoteComponent,
    NoteComponent,
    EmailFoldersComponent,
    EmailFlagfilterComponent,
    EmailFlagDrawerComponent,
    SettingTabComponent,
    AutoresponderComponent,
    TasksComponent,
    TaskDetailComponent,
    VerifyTokenComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgPipesModule,
    FullCalendarModule,
    SwiperModule,
    CKEditorModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 5 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:5000'
    }),
    NgxMatomoTrackerModule.forRoot({
      siteId: '1',
      trackerUrl: '//insights.otysonthego.nl'
    }),
    NgxMatomoRouterModule,
    SortablejsModule.forRoot({ animation: 150 }),
    TranslocoRootModule,
  ],
  providers: [
    HashingService,
    CachingService,
    EmailsService,
    ReminderService,
    CandidateService,
    CalendarService,
    TaskService,
    DashBoardContactsService,
    DossierService,
    LoaderService,
    RelationService,
    RelationContactService,
    InteractionService,
    SettingsService,
    VacancyService,
    StoredQueryTransformerService,
    WhatsappService,
    FileUploadService,
    FavoriteService,
    EmailFolderService,
    StoredqueryService,
    AuthService,
    AlertService,
    DatePipe,
    DateConverterPipe,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    AuthKeysService,
    preLoad,
    {
      provide: LOCALE_ID,
      useValue: "nl-NL"
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
