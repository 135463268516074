import { TranslocoService } from '@ngneat/transloco';
import { AlertService } from './../../../core/services/alert.service';
import { Subscription } from 'rxjs';
import { ReminderService } from './../../../core/services/reminder.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {Location} from '@angular/common';
import { reminderDetail } from 'src/app/core/interfaces/reminder.interface';

@Component({
  selector: 'app-reminder-detail',
  templateUrl: './reminder-detail.component.html',
  styleUrls: ['./reminder-detail.component.sass']
})
export class ReminderDetailComponent implements OnInit,OnDestroy {

  $subscription1:Subscription;
  $subscription2:Subscription;
  $subscription3:Subscription;
  $subscription4:Subscription;

  reminderUid:number;
  reminder:reminderDetail;
  reminderUsers:any = [];
  reminderLoaded:boolean = false;

  reminderSaveLoading:boolean = false;
  reminderRemoveLoading:boolean = false;

  constructor(private route: ActivatedRoute, private reminderService: ReminderService, private _location: Location, private alertService: AlertService, private translationService: TranslocoService) { }


  ngOnInit(): void {
    // When the component is loaded trough an URL including the uid param in the route, we should read it
    this.route.params.subscribe(params => {
      if(params['uid']){
        // If uid is present in the route and it isn't equal to the current uid
        if(this.reminderUid != parseInt(params['uid'])){
          // Set this as the current uid
          this.reminderUid = parseInt(params['uid']);
          // And retrieve the emails
          this.loadReminder();
        }
      }
     });
  }

  loadReminder(){
    this.$subscription1 = this.reminderService.getReminderDetail(this.reminderUid).subscribe((response) => {
      if(response && response.result){
        this.reminder = response.result;
        this.loadUserOptions();
        this.reminderLoaded = true;
      }else{
        this.reminderLoaded = false;
      }
    });
  }

  loadUserOptions(){
    this.$subscription2 = this.reminderService.getUserOptionList().subscribe(response => {
      this.reminderUsers = response;
    });
  }

  onRemoveReminder(){
    this.reminderRemoveLoading = true;
    this.$subscription3 = this.reminderService.removeReminder(this.reminderUid).subscribe(response => {
      if(response.result){
        setTimeout(() => {
          this.reminderRemoveLoading = false;
          this._location.back();
          this.alertService.success(this.translationService.translate('reminders.removedSuccesfully'));  
        }, 200);
      }
    });
  }

  onSaveReminder(){
    this.reminderSaveLoading = true;
    this.$subscription3 = this.reminderService.saveReminder(this.reminderUid,this.reminder).subscribe(response => {
      if(response.result){
        this.reminderSaveLoading = false;
        this._location.back();
        this.alertService.success(this.translationService.translate('reminders.savedSuccesfully'));
      }
    });

  }

  onTitleChange(title:string){
    this.reminder.title = title;
  }
  onDescriptionChange(description:string){
    this.reminder.description = description;
  }
  onDateChange(dateTime:string){
    this.reminder.dateTime = dateTime;
  }
  onForChange(forUid:number){
    this.reminder.forUserId = forUid;
  }


  ngOnDestroy(): void {
    if(this.$subscription1){
      this.$subscription1.unsubscribe();
    }
    if(this.$subscription2){
      this.$subscription2.unsubscribe();
    }
    if(this.$subscription3){
      this.$subscription3.unsubscribe();
    }
    if(this.$subscription4){
      this.$subscription4.unsubscribe();
    }
  }
}
