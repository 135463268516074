import { EmailsService } from './../../../../core/services/emails.service';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CupertinoPane } from 'cupertino-pane';
import { flagitem } from 'src/app/core/interfaces/flag-item.interface';

@Component({
  selector: 'app-email-flag-update-drawer',
  templateUrl: './email-flag-update-drawer.component.html',
  styleUrls: ['./email-flag-update-drawer.component.sass']
})

export class EmailFlagUpdateDrawerComponent implements OnInit, OnChanges, AfterViewInit {

  @Output() flagDrawerOpenState: EventEmitter<any> = new EventEmitter();
  @Output() newFlag: EventEmitter<any> = new EventEmitter();


  @Input() flagDrawerOpen:boolean = false;
  @Input() currentFlag:number;
  @Input() email_uid:number;

  flagDrawer: CupertinoPane = null;
  flagDrawerLoaded:boolean = false;
  list:flagitem[];

  constructor(private emailsService: EmailsService) {}

  ngOnInit(): void {
    this.list = [
      {
        title: 'noFlag',
        icon: '<i class="fa-solid fa-flag"></i>',
        id: 0,
        color: '#B5B5CC',
        show: this.currentFlag !== 0 ? true : false
      },
      {
        title: 'important',
        icon: '<i class="fa-solid fa-flag"></i>',
        id: 1,
        color: '#F15F79',
        show: this.currentFlag !== 1 ? true : false
      },
      {
        title: 'toDo',
        icon: '<i class="fa-solid fa-flag"></i>',
        id: 2,
        color: '#FCAA3F',
        show: this.currentFlag !== 2 ? true : false
      },
      {
        title: 'later',
        icon: '<i class="fa-solid fa-flag"></i>',
        id: 3,
        color: '#202020',
        show: this.currentFlag !== 3 ? true : false
      },
      {
        title: 'finished',
        icon: '<i class="fa-solid fa-flag"></i>',
        id: 4,
        color: '#06BEB6',
        show: this.currentFlag !== 4 ? true : false
      },
      {
        title: 'pending',
        icon: '<i class="fa-solid fa-flag"></i>',
        id: 5,
        color: '#40ADFA',
        show: this.currentFlag !== 5 ? true : false
      },{
        title: 'personal',
        icon: '<i class="fa-solid fa-flag"></i>',
        id: 6,
        color: '#B923B4',
        show: this.currentFlag !== 6 ? true : false
      }
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.flagDrawerLoaded) {
      if (!this.flagDrawerOpen) {
        this.flagDrawer.destroy({
          animate: true,
        });
      } else {
        this.flagDrawer.present({
          animate: true,
        });
      }
    }
  }

  ngAfterViewInit(): void {
    this.flagDrawer = new CupertinoPane('.flag-drawer', {
      backdrop: true,
      backdropOpacity: 0.7,
      bottomClose: true,
      fastSwipeClose: false,
      events: {
        onDidDismiss: (ev) => {
          this.flagDrawerOpen = false;
          this.flagDrawerOpenState.emit(this.flagDrawerOpen);
        },
        onBackdropTap: () => this.flagDrawer.destroy({ animate: true }),
      },
    });

    this.flagDrawerLoaded = true;
  }

  onCloseDrawer() {
    this.flagDrawerOpenState.emit(false);
  }

  onSelectFlag(id: number) {
    this.emailsService.updateFlag(this.email_uid, id).subscribe(response => {
      this.newFlag.emit(id);
    });
  }
}
