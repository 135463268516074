import { TranslocoService } from '@ngneat/transloco';
import { AlertService } from 'src/app/core/services/alert.service';
import { FileUploadService } from '../../../core/services/file-upload.service';
import { ActivatedRoute } from '@angular/router';
import { DossierService } from 'src/app/core/services/dossier.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MatomoTracker } from '@ngx-matomo/tracker';

@Component({
  selector: 'app-dossier-documentupload',
  templateUrl: './dossier-documentupload.component.html',
  styleUrls: ['./dossier-documentupload.component.sass']
})
export class DossierDocumentuploadComponent{

  documentTypes:any;
  entityUid:string;
  entityType:number;
  documentSubject:string;
  documentType:string;
  documentFileUid:string;
  documentFileName:string;
  documentOnTop:boolean = false;
  documentPrivate:boolean = false;
  documentDescription:string;
  documentLoading:boolean = false;
  @ViewChild('fileinput') fileinput:ElementRef;
  @ViewChild('typeSelect') typeSelect:ElementRef;


  constructor(private dossierService: DossierService, private route: ActivatedRoute, private fileUploadService: FileUploadService, private alertService: AlertService,private _location: Location, private translationService: TranslocoService,private readonly tracker: MatomoTracker) {
    this.getDocumentTypes();
    // When the component is loaded trough an URL including the uid param in the route, we should read it
    this.route.params.subscribe((params) => {
      if (params['uid']) {
        // If uid is present in the route and it isn't equal to the current uid
        if (this.entityUid != params['uid']) {
          // Set this as the current uid
          this.entityUid = params['uid'];
        }
      }

      if (params['entity']) {
        // If uid is present in the route and it isn't equal to the current uid
        if (this.entityType != parseInt(params['entity'])) {
          // Set this as the current uid
          this.entityType = parseInt(params['entity']);
        }
      }

    });
  }

  getDocumentTypes(){
    this.dossierService.documentTypes.subscribe(doctypes => {
      this.documentTypes = doctypes;
    })
  }

  onToggleAlwaysOnTop(newval:boolean){
    this.documentOnTop = newval;
  }

  onTogglePrivate(newval:boolean){
    this.documentPrivate = newval;
  }

  onTypeSelected(){
    this.documentType = this.typeSelect.nativeElement.value;
  }

  onFileSelected(files: FileList) {
    const file = files[0];
    const filename = file.name;
    this.fileUploadService.uploadFile(file).subscribe((response) => {
      this.documentFileUid = response[filename].ouid;
      this.documentFileName = response[filename].name;
    });
  }

  onRemoveUploadedFile(){
    this.fileinput.nativeElement.value = '';
    this.documentFileUid = null;
    this.documentFileName = null;
  }

  onSaveDocument(){
    if(!this.documentLoading){
      if(this.documentSubject){
        if(this.documentType){
          if(this.documentFileUid){
            this.documentLoading = true;
            this.dossierService.createDocument(this.entityType,this.documentSubject, this.documentType, this.documentPrivate, this.documentOnTop, this.documentDescription, this.entityUid, this.documentFileUid).subscribe(res => {
              if(res && res.result){
                this.alertService.success(this.translationService.translate('dossier.messageSuccesfullyCreatedDocument'));
                this.tracker.trackGoal(16);
                this._location.back();
                this.documentLoading = false;
              }else{
                this.alertService.error(this.translationService.translate('dossier.messageSomethingWentWrong'));
              }
            });
          }else{
            this.alertService.error(this.translationService.translate('dossier.messagePleaseUploadADocument'));
          }
        }else{
          this.alertService.error(this.translationService.translate('dossier.messagePleaseFillInTheDocumentType'));
        }
      }else{
        this.alertService.error(this.translationService.translate('dossier.messagePleaseAddASubject'));
      }
    }
  }
}
