<div class="calendar-buttons">
  <div class="user-select">
    <div class="user-select-icon" id="calendarSelectUsersIcon">
      <i class="fa-solid fa-calendar-circle-user"></i>
    </div>
    <select multiple name="usersControl" [formControl]="usersControl" (change)="onSelectUpdate()">
      <option *ngFor="let user of calendarUsers" [ngValue]="user.id">{{user.name}}</option>
    </select>
  </div>
</div>
