import { drawerListItem } from './../../../core/interfaces/drawer-list.interface';
import { User } from './../../../core/auth/user.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from './../../../core/auth/auth.service';
import { mailDetailResponse, mailDetailResponseInner, calenderItem } from './../../../core/interfaces/mail-interface';
import { Subscription } from 'rxjs';
import { EmailsService } from './../../../core/services/emails.service';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {Location} from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TranslocoService } from '@ngneat/transloco';
import { SettingsService } from 'src/app/core/services/settings.service';

@Component({
  selector: 'app-email-detail',
  templateUrl: './email-detail.component.html',
  styleUrls: ['./email-detail.component.sass']
})

export class EmailDetailComponent implements OnInit, AfterViewInit,OnDestroy{

  @ViewChild('mailbody') mailBody: ElementRef;

  mailLoaded:boolean = false;
  mailUid:number;
  showContentsDark:boolean = true;
  darkMode:boolean = false;
  mailDetail:mailDetailResponseInner;
  mailExtraStyling:string = `
    <style>
      body{
        font-family: Sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        word-break: break-word;
      }
    </style>
  `;
  // Create empty subscriptions so we can destroy them later on when someone goes away
  $subscription1: Subscription;
  $subscription2: Subscription;
  $subscription3: Subscription;
  $subscription4: Subscription;
  $subscription5: Subscription;
  markAsReadTimeOut:ReturnType<typeof setTimeout>;
  updateIframeTimeOut:ReturnType<typeof setTimeout>;
  moreOptionsShow:boolean = false;
  current_user:User;
  calItem:calenderItem = {
    show: false,
    title: '',
    status: '',
    start: '',
    end: ''
  };

  scaleToSet:number = 1;


  showMoveMailDrawer:boolean = false;
  mailFolders:any;
  moveMailToFolderList:drawerListItem[] = [];

  @ViewChild('iframe_holder') iframe_holder:ElementRef;


  constructor(private settingsService: SettingsService, private translationService: TranslocoService, private route: ActivatedRoute,private router: Router, private emailService: EmailsService, private location: Location, private authService: AuthService, private alertService: AlertService) {
    this.authService.user.subscribe(res => {
      this.current_user = res;
    })

    this.emailService.getMailFolders().subscribe(res => {
      if(res && res.length){
          this.mailFolders = res;
      }
    });

    this.settingsService.themeObservable.subscribe(res => {
      if(res){
        this.onUpdateDarkMode();
      }
    });
    this.onUpdateDarkMode();

  }

  onUpdateDarkMode(){
    let theSetting = this.settingsService.getSetting('05darkMode');
    let theSettingVal = theSetting.value;
    if(theSettingVal == 0){
      this.darkMode = true;
    }else if(theSettingVal == 1){
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.darkMode = true;
      }else{
        this.darkMode = false;
      }
    }else{
      this.darkMode = false;
    }
  }

  ngAfterViewInit(): void {
    this.updateIframeElement(true);

    // Wait for 4 seconds so we can make sure we can mark it as read
    this.markAsReadTimeOut = setTimeout(() => {
      this.onMarkAsRead();
    }, 2000)
  }

  ngOnInit(): void {
    // When the component is loaded trough an URL including the uid param in the route, we should read it
    this.route.params.subscribe(params => {
      if(params['uid']){
        // If uid is present in the route and it isn't equal to the current uid
        if(this.mailUid != parseInt(params['uid'])){
          // Set this as the current uid
          this.mailUid = parseInt(params['uid']);
          // And retrieve the emails
          this.loadMail();
        }
      }
     });
  }

  loadMail(){
    this.$subscription1 = this.emailService.getMailDetail(this.mailUid).subscribe((response:mailDetailResponse) => {
      if(response && response.result){
        this.mailDetail = response.result;
        this.mailLoaded = true;
        this.addPhotoUrlToMailDetail();
        this.updateIframeElement(true);
        this.updateIframeTimeOut = setTimeout(() => {
          this.updateIframeElement(true);
        }, 1000);
        this.checkCalendarItems();
      }else{
        this.mailLoaded = false;
      }
    });
  }

  checkCalendarItems(){
    // For some reason OWS returns an empty array if theres no calendar item assigned
    if(!Array.isArray(this.mailDetail.Calendar)){
      this.calItem.show = true;
      this.calItem.status = this.mailDetail.Calendar.status;
      this.calItem.start = this.mailDetail.Calendar.startDateTime;
      this.calItem.end = this.mailDetail.Calendar.endDateTime;
      this.calItem.title = this.mailDetail.Calendar.title;
    }
  }


  addPhotoUrlToMailDetail(){
    if(this.mailDetail.Person && this.mailDetail.Person.photoFileOuid){
      this.mailDetail.photourl = environment.fileServiceDownload + '?ouid=' + this.mailDetail.Person.photoFileOuid + '&class=' + this.mailDetail.Person.photoFileClass + '&sessionId=' + this.current_user.sessionId;
    }else{
      this.mailDetail.photourl = false;
    }
  }

  updateIframeElement(preferDark:boolean = true){

    var showInDark = preferDark;
    if(!this.darkMode){
      showInDark = false;
    }

    if(this.mailBody && this.mailDetail){
      if(this.mailBody.nativeElement.contentWindow){
        var thedoc = this.mailBody.nativeElement.contentWindow.document;

        thedoc.open();
        thedoc.write(this.mailExtraStyling);
        thedoc.write(this.mailDetail.htmlMessage);
        thedoc.close();
        thedoc.body.style.margin = '0';
        thedoc.body.style.padding = '0';
        thedoc.body.style.fontFamily = 'Open sans, sans-serif';
        thedoc.body.style.fontSize = '14px';
        thedoc.body.style.lineHeight = '24px';
        thedoc.body.style.wordBreak = 'break-word';

        /*Inverting in darkmode*/
        if(showInDark){
          this.showContentsDark = true;
          thedoc.body.style.backgroundColor = 'transparent';
          thedoc.body.style.filter = 'invert(1)';

          let images_in_mail = thedoc.body.getElementsByTagName('img');
          for (var i = 0; i < images_in_mail.length; i++) {
            var a = images_in_mail[i];
            a.style.filter = 'invert(1)';
            a.style.setProperty('max-width','100%', 'important');
            a.style.setProperty('height','auto', 'important');
          }

        }else{
          this.showContentsDark = false;
          thedoc.body.style.filter = 'invert(0)';
          thedoc.body.style.backgroundColor = '#fff';

          let images_in_mail = thedoc.body.getElementsByTagName('img');
          for (var i = 0; i < images_in_mail.length; i++) {
            var a = images_in_mail[i];
            a.style.filter = 'invert(0)';
            a.style.setProperty('max-width','100%', 'important');
            a.style.setProperty('height','auto', 'important');
          }
        }

        let screenWidth = this.iframe_holder.nativeElement.offsetWidth;
        let mail_width = thedoc.body.scrollWidth;
        if(mail_width > screenWidth){
          let desired_size_perc = ((screenWidth - 30) / mail_width * 100);
          var perc = desired_size_perc.toFixed(0);
          var percInt = parseInt(perc);
          if(this.scaleToSet <= 1){
            this.scaleToSet = parseFloat('0.' +perc);
          }
          thedoc.body.style.transformOrigin = 'top left';
          thedoc.body.style.transform = 'scale('+this.scaleToSet+')';
        }

        if(this.scaleToSet < 1){
          this.mailBody.nativeElement.style.height = (thedoc.body.scrollHeight / 100 * percInt) + 'px';
        }else{
          this.mailBody.nativeElement.height = thedoc.body.scrollHeight + 'px';
        }

        // Set all links within the email to target _blank so it won't open within the iframe
        let links_in_mail = thedoc.body.getElementsByTagName('a');
        for (var i = 0; i < links_in_mail.length; i++) {
          var a = links_in_mail[i];
          // Set all link attributes to blank by default
          a.setAttribute("target", "_blank")
          // Open all tasks (with a link to Go!) within the app
          if(a.href.includes('/modular.html#/tasks/')){
            let link = a.href;
            let tasknumber = link.split('/tasks/').pop();
            a.setAttribute("target", "_PARENT");
            a.href = '/tasks/' + tasknumber;
          }
          // Open all mailto: links wihtin app
          if(a.href.includes('mailto:')){
            let link = a.href;
            let email = link.split('mailto:').pop();
            a.setAttribute("target", "_PARENT");
            a.href = '/emails/compose?email=' + email;
          }

        }
      }
    }
  }

  iframeLoaded(){
    if(this.mailBody){
      this.mailBody.nativeElement.height = this.mailBody.nativeElement.contentWindow.document.body.scrollHeight + 'px';
    }
  }

  onMarkAsRead(){
    if(!this.mailDetail.read){
      this.$subscription3 = this.emailService.markAsRead(this.mailUid).subscribe();
    }
  }

  onMarkAsUnRead(){
    if(this.mailDetail.read){
      this.$subscription4 = this.emailService.markAsUnRead(this.mailUid).subscribe();
    }
  }

  onRemoveEmail(){
    this.$subscription2 = this.emailService.moveEmails([this.mailUid], '-3').subscribe((response) => {
      if(response){
        this.location.back();
        this.alertService.success(this.translationService.translate('emails.emailRemovedSucces'));
      }
    });
  }

  onReply(){
    this.router.navigate(['/emails/reply/' + this.mailDetail.uid]);
  }

  onReplyAll(){
    this.router.navigate(['/emails/replyall/' + this.mailDetail.uid]);
  }

  onForward(){
    this.router.navigate(['/emails/forward/' + this.mailDetail.uid]);
  }

  onMove(){
    this.createDrawerList();
    this.showMoveMailDrawer = true;
    /*
    this.$subscription2 = this.emailService.moveEmails([this.mailUid], '-3').subscribe((response) => {
      if(response.result){
        this.moreOptionsShow = false;
      }
    });
    */
  }

  onShowMoreOptions(){
    this.moreOptionsShow = true;
  }

  onDrawerItemClicked(event:any){
    switch (event) {
      case 'forward':
        this.onForward();
        break;
      case 'replyall':
        this.onReplyAll();
        break;
      case 'reply':
        this.onReply();
        break;
      case 'remove':
        this.onRemoveEmail();
        break;
      case 'move':
        this.onMove();
        break;
      case 'markasunread':
        this.onMarkAsUnRead();
        break;
      default:
        this.moreOptionsShow = false;
    }
  }

  ngOnDestroy(): void {
    this.$subscription1.unsubscribe();

    if(this.$subscription2){
      this.$subscription2.unsubscribe();
    }
    if(this.$subscription3){
      this.$subscription3.unsubscribe();
    }

    if(this.$subscription4){
      this.$subscription4.unsubscribe();
    }

    if(this.$subscription5){
      this.$subscription5.unsubscribe();
    }

    clearTimeout(this.markAsReadTimeOut);
    clearTimeout(this.updateIframeTimeOut);
  }

  onShowMailInverted(inverted:boolean){

    if(inverted){
      // The user wan'ts to see it light
      this.updateIframeElement(false);
    }else{
      // The user wan'ts to see it dark again
      this.updateIframeElement(true);
    }
  }

  createDrawerList(){
    let foldersToRead = this.mailFolders;
    for(var folderKey in foldersToRead)
    {
      // Create a new object property called "title"
      foldersToRead[folderKey].title = foldersToRead[folderKey].folder;
      // Remove the old object property
      delete foldersToRead[folderKey].folder;

      // If theres a truthy mail count (so 1 or higher) we add it to the title
      if(foldersToRead[folderKey].unreadEmailCount){
        foldersToRead[folderKey].title = foldersToRead[folderKey].title + '(' + foldersToRead[folderKey].unreadEmailCount + ')';
      }
      // Now the unreadcount is added to the title we can remove it from the object
      delete foldersToRead[folderKey].unreadEmailCount;

      // Assign different font awesome icons to the different folder UID's
      switch (foldersToRead[folderKey].uid) {
        case '-1': // Inbox
          foldersToRead[folderKey].icon = '<i class="fa-solid fa-inbox"></i>';
          break;
        case '-2': // Send items
          foldersToRead[folderKey].icon = '<i class="fa-solid fa-paper-plane"></i>';
          break;
        case '-3': // Removed items
          foldersToRead[folderKey].icon = '<i class="fa-solid fa-trash"></i>';
          break;
        case '-4': // Concepts
          foldersToRead[folderKey].icon = '<i class="fa-solid fa-pen-line"></i>';
          break;
        case '-5': // Spam
          foldersToRead[folderKey].icon = '<i class="fa-sharp fa-solid fa-circle-xmark"></i>';
          break;
        case '-6': // Delay
          foldersToRead[folderKey].icon = '<i class="fa-solid fa-clock"></i>';
          break;
        case '-7': // Undeliverd
          foldersToRead[folderKey].icon = '<i class="fa-sharp fa-solid fa-circle-exclamation"></i>';
          break;
        default:
          foldersToRead[folderKey].icon = '<i class="fa-solid fa-folder-open"></i>';
      }
    }

    // Assign the new array of objects to the drawerList variable
    this.moveMailToFolderList = foldersToRead;
  }


  onMoveMailToFolder(folderId:string){
    if(folderId == this.mailDetail.folderId){
      this.location.back();
      this.alertService.success(this.translationService.translate('emails.emailAlreadyInFolder'));
    }else{
      this.$subscription5 = this.emailService.moveEmails([this.mailUid], folderId).subscribe((response) => {
        if(response){
          this.location.back();
          this.alertService.success(this.translationService.translate('emails.emailMovedSucces'));
        }
      });
    }

  }










}
