<div class="list">
  <div class="listpage page_{{page.page}}" *ngFor="let page of emails;">
    <a class="list-item list-item-mail" *ngFor="let email of page.items;let i = index;" (click)="onMailItemClick(email.uid, listItem)" (pressAndHold)="onPressAndHold(email.uid, listItem)" #listItem>
      <div class="list-image" [ngStyle]="{'background-image': (email.photourl) ? 'url('+email.photourl+')' : ''}" (click)="onListImageClick($event,email.uid, listItem)">

        <ng-container *ngIf="email.folderId !== '-2' && email.folderId !== '-4' && email.folderId !== '-6'">
          <ng-template [ngIf]="email.From.name != ''" [ngIfElse]="showMailsAsInitials ">
            <span *ngIf="!email.photourl">
              {{ email.From.name | getInitials}}
            </span>
          </ng-template>
          <ng-template #showMailsAsInitials>
            <span *ngIf="!email.photourl">{{ email.From.email | getInitials}}</span>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="email.folderId == '-2' || email.folderId == '-4' || email.folderId == '-6'">
          <ng-template [ngIf]="email.To.length > 0  && email.To[0].name != ''" [ngIfElse]="showMailsAsInitials2 ">
            <span *ngIf="!email.photourl">
              {{ email.To[0].name | getInitials}}
            </span>
          </ng-template>
          <ng-template #showMailsAsInitials2>
            <span *ngIf="!email.photourl && email.To.length > 0">{{ email.To[0].email | getInitials}}</span>
          </ng-template>
        </ng-container>



        <div class="entity-tag candidate-tag" *ngIf="email.Person.candidateUid !== null">
          <i class="fa-solid fa-user"></i>
        </div>
        <div class="entity-tag relationcontact-tag" *ngIf="email.Person.relationContactUid !== null">
          <i class="fa-solid fa-user"></i>
        </div>
        <div class="entity-tag user-tag" *ngIf="email.Person.userId !== null">
          <i class="fa-solid fa-user"></i>
        </div>
      </div>
      <div class="list-item-content">
        <div class="list-item-content-left">
          <ng-container *ngIf="email.folderId !== '-2' && email.folderId !== '-4' && email.folderId !== '-6'">
            <div class="list-item-title" [ngClass]="{'unread': email.read != true}">
              <i class="fa-solid fa-exclamation" *ngIf="email.priorityId == 1"></i>
              <span *ngIf="email.From.name != ''; else showMailAsName">{{ email.From.name }}</span>
              <ng-template #showMailAsName><span>{{ email.From.email }}</span></ng-template>
            </div>
          </ng-container>
          <ng-container *ngIf="email.folderId == '-2' || email.folderId == '-4' || email.folderId == '-6'">
            <div class="list-item-title title_mail" [ngClass]="{'unread': email.read != true}">
              <i class="fa-solid fa-exclamation" *ngIf="email.priorityId == 1"></i>
              <ng-container *ngFor="let toPerson of email.To;let last = last;">
                <span *ngIf="toPerson.name != ''; else showMailAsName">{{ toPerson.name }}</span>
                <ng-template #showMailAsName><span>{{ toPerson.email }}</span></ng-template>
                <span *ngIf="!last">, </span>
              </ng-container>
            </div>
          </ng-container>
          <div class="list-item-subject subject_mail" [ngClass]="{'unread': email.read != true}">{{ email.subject }}</div>
          <div class="list-item-description" [ngClass]="{'unread': email.read != true}">{{ email.plainTextMessage | stripHTML }}</div>
        </div>
        <div class="list-item-content-right">
          <div class="top">

            <i class="fa-solid fa-share" *ngIf="email.lastAction == 'forward'"></i>
            <i class="fa-solid fa-share icon-reverse" *ngIf="email.lastAction == 'reply'"></i>

            <i class="fa-regular fa-paperclip" *ngIf="email.hasAttachment"></i>
            <span class="list-item-date">{{ email.sentDateTime | customDate }}</span>
          </div>
          <div class="bottom">
            <div class="folder-name" *ngIf="showFolders">
              {{email.folder}}
            </div>
            <div class="mail-icon">
              <i class="fa-solid fa-flag" [ngClass]="email.tag !== 0 ? 'tag_' + email.tag : ''" (click)="onListItemFlagClicked($event,email.tag,email.uid,i,page.page)"></i>
            </div>
            <div class="mail-icon" *ngIf="email.private">
              <i class="fa-solid fa-user-lock"></i>
            </div>

          </div>
        </div>
      </div>
    </a>
  </div>
</div>
<app-email-flag-update-drawer [flagDrawerOpen]="updateMailFlagShow" (newFlag)="onFlagUpdate($event)" [currentFlag]="flagState" [email_uid]="flagMailUid" (flagDrawerOpenState)="updateMailFlagShow = $event"></app-email-flag-update-drawer>
