import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CupertinoPane } from 'cupertino-pane';
import { drawerListItem } from 'src/app/core/interfaces/drawer-list.interface';

@Component({
  selector: 'app-drawer-component',
  templateUrl: './drawer-component.component.html',
  styleUrls: ['./drawer-component.component.sass'],
})
export class DrawerComponentComponent implements AfterViewInit, OnChanges {
  @Input() drawerTitle: string = 'Select';
  @Input() list: drawerListItem[];
  @Input() drawerOpen: boolean = false;
  @Input() backdropOpacity: number = 0.7;

  @Input() drawerClass: string = 'drawer';
  @Input() useTransloco: boolean = false;

  drawerLoaded: boolean = false;
  drawerPresentedOnce: boolean = false;

  @Output() onClicked: EventEmitter<any> = new EventEmitter();
  @Output() drawerState: EventEmitter<any> = new EventEmitter();

  drawer: CupertinoPane = null;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.drawerLoaded) {
      if (this.drawerOpen) {
        this.drawerPresentedOnce = true;
        this.drawer.present({
          animate: true,
        });
      } else {
        if (this.drawerPresentedOnce) {
          this.drawer.destroy({
            animate: true,
          });
        }
      }
    }
  }

  ngAfterViewInit(): void {
    this.drawer = new CupertinoPane('.' + this.drawerClass, {
      backdrop: true,
      backdropOpacity: this.backdropOpacity,
      bottomClose: true,
      fastSwipeClose: false,
      events: {
        onDidDismiss: (ev) => {
          this.drawerOpen = false;
          this.drawerState.emit(this.drawerOpen);
        },
        onBackdropTap: () => {
          this.drawerOpen = false;
          this.drawerState.emit(this.drawerOpen);
        },
      },
    });
    this.drawerLoaded = true;
  }

  onCloseDrawer() {
    this.drawerState.emit(false);
  }

  onDrawerItemClicked(theButton: any) {
    this.onClicked.emit(theButton);
  }
}
