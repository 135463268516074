<app-header
  *transloco="let t; read: 'calendar'"
  [title]="t('pageTitle') | capitalize"
  listType="event"
  [searchPlaceHolder]="t('searchTitle')"
  (onSearchInput)="onSearchInput($event)"
  [searchResults]="searchResults"
></app-header>

<div class="calendar-tools">
  <div class="calendar-control">
    <span class="prevmonth" id="calendarPrevMonthIcon" (click)="onPrevMonth()"
      ><i class="fa-solid fa-circle-caret-left"></i
    ></span>
    <span class="nextmonth" id="calendarNextMonthIcon" (click)="onNextMonth()"
      ><i class="fa-solid fa-circle-caret-right"></i
    ></span>
  </div>

  <div class="calendar-title">
    <span class="currentmonth">{{ fcCurrentDateStart | date: "MMMM Y" : null : monthLocale }}</span>
  </div>

  <app-top-buttons (selectedUsers)="onUpdatedSelectedUsers($event);"></app-top-buttons>
</div>

<div class="fullcalendar-wrap" (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')">
  <full-calendar
    [options]="calendarOptions"
    class="otys-calendar"
    #calendar
  ></full-calendar>

  <app-day-slider
    [dayPopDay]="dayPopDay"
    [dayPopupDays]="dayPopupDays"
    [dayPopupActive]="dayPopupActive"
    (onCloseDayClicked)="onCloseDay()"
    (monthNav)="onMonthNavigation($event)"
  ></app-day-slider>
</div>

<app-action-button-single
  (onClick)="onSingleActionClicked($event)"
  [icon]="floatingActionIcon"
></app-action-button-single>
