import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-action-button-single',
  templateUrl: './action-button-single.component.html',
  styleUrls: ['./action-button-single.component.sass']
})
export class ActionButtonSingleComponent{

  @Output() onClick: EventEmitter<MouseEvent> = new EventEmitter();
  @Input() icon:any = '';

  constructor() { }

  onClicked(event:MouseEvent){
    this.onClick.emit(event);
    const canVibrate = window.navigator.vibrate;
    if (canVibrate){
      window.navigator.vibrate(1);
    }
  }

}
