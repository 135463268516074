import { Component, Input } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.sass']
})
export class BackButtonComponent{

  @Input() times:number = 1;

  constructor(private location: Location) { }

  onGoBack(){
    if(this.times == 1){
      this.location.back();
    }else if(this.times == 2){
      this.location.back();
      this.location.back();
    }else if(this.times == 3){
      this.location.back();
      this.location.back();
      this.location.back();
    }else if(this.times == 4){
      this.location.back();
      this.location.back();
      this.location.back();
      this.location.back();
    }
  }

}
