import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DossierService } from 'src/app/core/services/dossier.service';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.sass']
})
export class NoteComponent implements OnInit {


  noteUid:number;
  note:any;

  constructor(
    private route: ActivatedRoute,
    private dossierService: DossierService
  ) {
    this.route.params.subscribe((params) => {
      if (params['uid']) {
        // If uid is present in the route and it isn't equal to the current uid
        if (this.noteUid != parseInt(params['uid'])) {
          // Set this as the current uid
          this.noteUid = parseInt(params['uid']);
        }
      }
    });
  }

  ngOnInit(): void {
    this.loadNote();
  }


  loadNote(){
    this.dossierService.getNote(this.noteUid).subscribe(res => {
      this.note = res.result;
    });
  }

}
