import { StoredQueryTransformerService } from './storedquery-transformer.service';
import { CachingService } from './caching.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class RelationService {
  private relationList = new BehaviorSubject<any>(null);
  private relationSearchResultList = new BehaviorSubject<any>(null);
  private relationDetail = new BehaviorSubject<any>(null);
  private relationCriteriaNames = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private cachingService: CachingService,
    private storedQueryTransformerService: StoredQueryTransformerService
  ) {}

  getRelationList(page: number = 1, storedQueryData:any = null) {
    let limit = 20;
    let offset = 0;

    if (page != 1) {
      offset = page * limit - limit;
    }

    // Define the OWS method
    const requestMethod = 'Otys.Services.RelationService.getListEx';
    // Define the body of the request (params)
    const requestBody:any = [
      {
        search: {
          ACTONOMY: {
            DATA: { dummy: { value: 1 } },
            OPTIONS: {
              getTotalCount: 1,
              limit: limit,
              offset: offset,
              sort: {
                SCORE: 'DESC',
                entryDateFull: 'DESC',
              },
            },
            VERSION: 2,
            SUB_VERSION: 0,
          },
        },
        limit: limit,
        excludeLimitCheck: true,
        getTotalCount: true,
        what: {
          uid: 1,
          relation: 1,
          logoFileName: 1,
          status: 1,
          statusId: 1,
          city: 1,
        },
      },
    ];

    if(storedQueryData){
      let transformed = this.storedQueryTransformerService.transform(storedQueryData);
      if(transformed.conditional){
        requestBody[0].condition = transformed.conditional;
      }
      requestBody[0].search.ACTONOMY.DATA = transformed.actonomy;
    }

    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'true'
      },
    };

    if (page == 1) {
      // Retrieve cached data (if theres any)
      let cached_data = this.cachingService.getCache(
        requestMethod,
        requestBody
      );
      if (cached_data) {
        if (cached_data.result.listOutput.length) {
          this.relationList.next({
            page: page,
            items: cached_data.result.listOutput,
          });
        }
      } else {
        this.relationList.next(null);
      }
    } else {
      this.relationList.next(null);
    }

    // Retrieve live data
    this.http
      .post<any>(environment.owsUrl, requestBody, requestHeaders)
      .subscribe((resData) => {
        if (resData) {
          if (resData.result.listOutput) {
            this.relationList.next({
              page: page,
              items: resData.result.listOutput,
            });
          }
        }
      });

    // Return the relation list as an observable so other components can listen to any updates
    return this.relationList.asObservable();
  }

  getSearchResults(searchInput: string) {

    this.relationSearchResultList.next(null);

    // Define the OWS method
    const requestMethod = 'Otys.Services.RelationService.getListEx';
    // Define the body of the request (params)
    const requestBody = [
      {
        search: {
          ACTONOMY: {
            DATA: {
              keywords: {
                options: {
                  enableExpansion: false,
                  required: true,
                  searchList: [
                    "relation"
                  ],
                  searchMode: "one"
                },
                value: searchInput + '*'
              }
            },
            OPTIONS: {
              getTotalCount: 1,
              limit: 75,
              offset: 0,
              sort: {
                SCORE: "DESC",
                entryDateFull: "DESC"
              },
              facetsToUse: []
            },
            VERSION: 2,
            SUB_VERSION: 0
          }
        },
        limit: 75,
        excludeLimitCheck: true,
        getTotalCount: true,
        what: {
          uid: 1,
          relation: 1,
          logoFileName: 1,
          status: 1,
          statusId: 1,
          city: 1,
        },
      },
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'false',
      },
    };


    // Retrieve live data
    this.http
    .post<any>(environment.owsUrl, requestBody, requestHeaders)
    .subscribe((resData) => {
      if (resData) {
        if (resData.result.listOutput) {
          this.relationSearchResultList.next({
            page: 1,
            items: resData.result.listOutput,
          });
        }
      }
    });

    // Return the relation list as an observable so other components can listen to any updates
    return this.relationSearchResultList.asObservable();
  }

  getRelationDetail(uid: number) {
    // Define the OWS method
    const requestMethod = 'Otys.Services.RelationService.getDetail';
    // Define the body of the request (params)
    const requestBody = [
      uid,
      {
        uid: 1,
        is_klantid: 1,
        entryDateTime: 1,
        modifiedBy: 1,
        createdByUser: 1,
        lastModified: 1,
        lastCommercialContact: 1,
        relation: 1,
        city: 1,
        Addresses: 1,
        Criteria: 1,
        email: 1,
        website: 1,
        status: 1,
        statusId: 1,
        user: 1,
        userId: 1,
        phoneNumberMain: 1,
        logoFileName: 1,
      },
      null,
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'true'
      },
    };

    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if (cached_data) {
      this.relationDetail.next(cached_data.result);
    } else {
      this.relationDetail.next(null);
    }

    // Retrieve live data
    this.http
      .post<any>(environment.owsUrl, requestBody, requestHeaders)
      .subscribe((resData) => {
        if (resData) {
          if (resData.result) {
            this.relationDetail.next(resData.result);
          }
        }
      });

    // Return the relation list as an observable so other components can listen to any updates
    return this.relationDetail.asObservable();
  }

  getCrmCriteriaNames() {
    // Define the OWS method
    const requestMethod = 'Otys.Services.CrmCriteriaCategoryService.getListEx';
    // Define the body of the request (params)
    const requestBody = [
      {
        what: {
          uid: 1,
          name: 1,
        },
        limit: 200,
        condition: {
          type: 'COND',
          field: 'availableInRelations',
          op: 'NE',
          param: '',
        },
      },
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'true'
      },
    };

    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if (cached_data) {
      this.relationCriteriaNames.next(cached_data.result.listOutput);
    } else {
      this.relationCriteriaNames.next(null);
    }

    // Retrieve live data
    if (
      this.cachingService.getCacheAge(requestMethod, requestBody) === false ||
      this.cachingService.getCacheAge(requestMethod, requestBody) > 1440
    ) {
      this.http
        .post<any>(environment.owsUrl, requestBody, requestHeaders)
        .subscribe((resData) => {
          this.relationCriteriaNames.next(resData.result.listOutput);
        });
    }

    // Return the relation list as an observable so other components can listen to any updates
    return this.relationCriteriaNames.asObservable();
  }


  getRelationContacts(relationUid:number){
    // Define the OWS method
    const requestMethod = 'Otys.Services.RelationContactService.getListEx';
    // Define the body of the request (params)
    const requestBody = [
      {
        what: {
          uid: 1,
          jobTitle: 1,
          internalId: 1,
          Person: {
            firstName: 1,
            infix: 1,
            lastName: 1,
            emailPrimary: 1,
            statusName: 1,
            PhoneNumbers: {
              type: 1,
              phoneNumber: 1
            }
          }
        },
        limit: 500,
        offset: 0,
        getTotalCount: true,
        sort: {
          "Person.firstName": "ASC",
          "Person.lastName": "ASC"
        },
        condition: {
          type: "AND",
          items: [
            {
              type: "COND",
              field: "relationId",
              op: "EQ",
              param: relationUid
            }
          ]
        }
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'false'
      },
    };
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders)
  }


  getVacancies(relationUid:number){
     // Define the OWS method
     const requestMethod = 'Otys.Services.VacancyService.getListEx';
     // Define the body of the request (params)
     const requestBody = [
       {
         what: {
           uid: 1,
           title: 1,
           applyCount:1,
           status: 1,
           user: 1,
           location: 1
         },
         limit: 100,
         offset: 0,
         getTotalCount: 1,
         sort: {
           entryDateTime: "DESC"
         },
         excludeLimitCheck: true,
         condition: {
           type: "AND",
           items: [
             {
               type: "COND",
               field: "relationId",
               op: "EQ",
               param: relationUid
             }
           ]
         }
       }
     ];
     // Define the request headers
     const requestHeaders = {
       headers: {
         'ows-method': requestMethod,
         'ows-cachable': 'false'
       },
     };
     return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders)
  }

  suggestRelation(input:string){
    // Define the OWS method
    const requestMethod = 'Otys.Services.RelationService.getList';
    // Define the body of the request (params)
    const requestBody = [
      {
        what: {
          uid: 1,
          relation: 1
        },
        condition: {
          type: "AND",
          items: [
            {
              type: "AND",
              items: [
                {
                  type: "OR",
                  items: [
                    {
                      type: "COND",
                      field: "relation",
                      op: "LIKE",
                      param: "%"+input+"%"
                    },
                    {
                      type: "COND",
                      field: "customerNr",
                      op: "LIKE",
                      param: "%"+input+"%"
                    }
                  ]
                }
              ]
            }
          ]
        },
        sort: {
          relation: "ASC"
        },
        limit: 200
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'false'
      },
    };
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders)
  }

}
