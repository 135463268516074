<form *transloco="let t; read: 'vacancies'">

  <div class="questionwrap_publication">
    <div class="question">
      <label>{{t('published')}}</label>
      <app-toggle-switch [value]="vacancy.published" (toggled)="onUpdateValue('published', $event)"></app-toggle-switch>
    </div>
    <div class="question" *ngIf="multiSite">
      <label>{{t('publishedWebsites')}}</label>
      <select multiple name="publishedWebsites" [(ngModel)]="publishedWebsites" (change)="onUpdateValue('publishedWebsites', publishedWebsites)">
        <option *ngFor="let website of publicationOptions.publishedWebsites | keyvalue" [ngValue]="website.key">{{website.value}}</option>
      </select>
    </div>
  </div>

  <div class="questionwrap_publication">
    <div class="question">
      <label>{{t('publishedShortlist')}}</label>
      <app-toggle-switch [value]="vacancy.publishedInShortlist" (toggled)="onUpdateValue('publishedInShortlist', $event)"></app-toggle-switch>
    </div>
    <div class="question" *ngIf="multiSite">
      <label>{{t('publishedShortlistWebsites')}}</label>
      <select multiple name="publishedInShortlistWebsites" [(ngModel)]="publishedInShortlistWebsites" (change)="onUpdateValue('publishedInShortlistWebsites', publishedInShortlistWebsites)">
        <option *ngFor="let website of publicationOptions.publishedWebsites | keyvalue" [ngValue]="website.key">{{website.value}}</option>
      </select>
    </div>
  </div>

  <div class="questionwrap_publication">
    <div class="question">
      <label>{{t('publishedJobbanner')}}</label>
      <app-toggle-switch [value]="vacancy.jobBanner" (toggled)="onUpdateValue('jobBanner', $event)"></app-toggle-switch>
    </div>
    <div class="question" *ngIf="multiSite">
      <label>{{t('publishedJobbannerWebsites')}}</label>
      <select multiple name="jobBannerWebsites" [(ngModel)]="jobBannerWebsites" (change)="onUpdateValue('jobBannerWebsites', jobBannerWebsites)">
        <option *ngFor="let website of publicationOptions.publishedWebsites | keyvalue" [ngValue]="website.key">{{website.value}}</option>
      </select>
    </div>
  </div>


  <div class="questionwrap">
    <div class="question">
      <label>{{t('publicationStartDate')}}</label>
      <input type="date" [value]="vacancy.publicationStartDate | dateconverter" (change)="onUpdateValue('publicationStartDate', $event.target.value)">
    </div>
    <div class="question">
      <label>{{t('publicationEndDate')}}</label>
      <input type="date" [value]="vacancy.publicationEndDate | dateconverter" (change)="onUpdateValue('publicationEndDate', $event.target.value)">
    </div>
  </div>

  <div class="question">
    <label>{{t('publicationLanguage')}}</label>
    <select multiple name="publishLanguages" [(ngModel)]="publishLanguages" (change)="onUpdateValue('publishLanguages', publishLanguages)">
      <option *ngFor="let language of current_user.contentLanguages" [ngValue]="language">{{language | uppercase}}</option>
    </select>
  </div>

  <div class="question">
    <label>{{t('publicationStatus')}}</label>
    <select (change)="onUpdateValue('publicationStatusId', $event.target.value)">
      <option value="null">{{t('pickAnOption')}}</option>
      <option *ngFor="let status of publicationOptions.publicationStatusId | keyvalue" [value]="status.key" [attr.selected]="status.key == vacancy.publicationStatusId ? true: null">{{status.value}}</option>
    </select>
  </div>
</form>
