import { BehaviorSubject, fromEvent } from 'rxjs';
import { Injectable } from "@angular/core";

@Injectable()
export class LoaderService{

  constructor(){
    this.watchUserOffline();
  }
  private online:boolean = true;
  private loading_methods:string[] = [];
  private loading_requests:number = 0;


  public isLoading = new BehaviorSubject<boolean>(false);

  get numberOfRequests(){
    return this.loading_requests;
  }

  get loadingMethods(){
    return this.loading_methods;
  }

  set loadingMethods(methodArray:string[]){
    this.loading_methods = methodArray;
    this.loading_requests = this.loading_methods.length;
    if(this.loading_requests > 0){
      this.isLoading.next(true);
    }else{
      this.isLoading.next(false);
    }
  }

  private watchUserOffline(){
    this.online = navigator.onLine;
    fromEvent(window, 'online').subscribe((data) => {
      if (data.type == 'online') {
        this.online = true;
      }
    });
    fromEvent(window, 'offline').subscribe((data) => {
      if (data.type == 'offline') {
        this.online = false;
      }
    });
  }

}

