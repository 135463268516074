<header>
  <div class="header-wrap" *ngIf="reminderLoaded">
    <div class="left-side">
      <app-back-button></app-back-button>
      <h1 *ngIf="reminder.title">
        {{ reminder.title }}
      </h1>
    </div>
    <div class="right-side controls">
      <div class="control control-remove" (click)="onRemoveReminder()" *ngIf="!reminderRemoveLoading">
        <i class="fa-solid fa-trash"></i>
      </div>
      <img src="/assets/images/spinner.svg" width="16" height="16" *ngIf="reminderRemoveLoading" class="loading_spinner">
      <div class="control control-save" (click)="onSaveReminder()" *ngIf="!reminderSaveLoading">
        <i class="fa-solid fa-save"></i>
      </div>
      <img src="/assets/images/spinner.svg" width="16" height="16" *ngIf="reminderSaveLoading" class="loading_spinner">
    </div>
  </div>
</header>
<div class="page" *ngIf="reminderLoaded">
  <div class="reminder-form" *transloco="let t; read: 'reminders'">
    <div class="question">
      <label>{{t('title')}}:</label>
      <input type="text" [value]="reminder.title" (input)="onTitleChange($event.target.value)" required>
    </div>
    <div class="question">
      <label>{{t('for')}}:</label>
      <select (input)="onForChange($event.target.value)">
        <option *ngFor="let reminderUser of reminderUsers | keyvalue" [value]="reminderUser.key" [selected]="reminderUser.key == reminder.forUserId">{{reminderUser.value}}</option>
      </select>
    </div>
    <div class="question" *ngIf="reminder.forUserId != reminder.fromUserId">
      <label>{{t('from')}}:</label>
      <input type="text" disabled [value]="reminder.fromUser" class="reminderFormInput">
    </div>
    <div class="question">
      <label>{{t('date')}}:</label>
      <input type="datetime-local" [value]="reminder.dateTime | date:'YYYY-MM-ddTHH:mm'" (input)="onDateChange($event.target.value)" required>
    </div>
    <div class="question">
      <label>{{t('description')}}:</label>
      <textarea (input)="onDescriptionChange($event.target.value)">{{reminder.description | stripHTML}}</textarea>
    </div>
  </div>
</div>
