import { User } from './../auth/user.model';
import { TranslocoService } from '@ngneat/transloco';
import { CachingService } from './caching.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { dossierTab } from '../interfaces/dossier.interface';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class DossierService{

  dossierTabs = new BehaviorSubject<dossierTab[]>([]);
  documentTypes = new BehaviorSubject<any[]>([]);

  candidateNoteTypes = new BehaviorSubject<any>(null);
  vacancyNoteTypes = new BehaviorSubject<any>(null);
  relationNoteTypes = new BehaviorSubject<any>(null);
  taskNoteTypes = new BehaviorSubject<any>(null);
  relationContactNoteTypes = new BehaviorSubject<any>(null);

  current_user:User;

  constructor(private authService: AuthService, private http: HttpClient, private cachingService: CachingService, private translationService: TranslocoService){
    this.authService.user.subscribe(res => {
      this.current_user = res;
    })

    this.createEmptyTabs();
    this.getDocTypes();

    this.getCandidateNoteTypes();
    this.getVacancyNoteTypes();
    this.getRelationNoteTypes();
    this.getRelationContactNoteTypes();
    this.getTaskNoteTypes();
  }

  createEmptyTabs(){
    let allTabs = [
      {
        name: this.translationService.translate('dossier.tabEmails'),
        classes: ['Emails'],
        active: false,
        loading: false,
        hasAnyResults: null,
        pages: []
      },
      {
        name: this.translationService.translate('dossier.tabDocuments'),
        classes: ['AttachedDocs', 'CV'],
        active: false,
        loading: false,
        hasAnyResults: null,
        pages: []
      },
      {
        name: this.translationService.translate('dossier.tabApointments'),
        classes: ['AgendaItem'],
        active: false,
        loading: false,
        hasAnyResults: null,
        pages: []
      },
      {
        name: this.translationService.translate('dossier.tabNotes'),
        classes: ['Notes'],
        active: false,
        loading: false,
        hasAnyResults: null,
        pages: []
      },
      {
        name: this.translationService.translate('dossier.tabReminders'),
        classes: ['Reminders'],
        active: false,
        loading: false,
        hasAnyResults: null,
        pages: []
      }
    ];

    // Check the current user
    if(this.current_user){
      // Check if this user has rights for Emails
      if(this.current_user.modules.includes('Emails') !== true){
        // If not let's remove the dossier tab for it.
        allTabs.splice(0, 1);
      }
    }

    // Activate the first tab
    allTabs[0].active = true;
    this.dossierTabs.next(allTabs);
  }

  getDossierPage(tab:number, entity:number, entityUid:number){

    this.dossierTabs.getValue()[tab].loading = true;
    this.dossierTabs.next(this.dossierTabs.getValue());

    let limit = 50;
    let offset = 0;
    let page = this.dossierTabs.getValue()[tab].pages.length + 1;

    if(page != 1){
      offset = page * limit - limit;
    }

    // Define the OWS method
    const requestMethod = 'Otys.Services.DossierService.getList';
    // Define the body of the request (params)
    const requestBody = [
      {
        objectEntityId: entity,
        objectUid: entityUid,
        restrictClass: this.dossierTabs.getValue()[tab].classes,
        limit: limit,
        offset: offset
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };


    // Retrieve live data
    this.http.post<any>(environment.owsUrl, requestBody, requestHeaders).subscribe( resData => {
      if(resData){
        this.dossierTabs.getValue()[tab].loading = false;
        if(resData.result.length){
          this.dossierTabs.getValue()[tab].hasAnyResults = true;
          this.dossierTabs.getValue()[tab].pages.push({
            page: page,
            items: resData.result
          })
        }else{
          if(page == 1){
            this.dossierTabs.getValue()[tab].hasAnyResults = false;
          }
        }
        this.dossierTabs.next(this.dossierTabs.getValue());
      }
    });
  }

  private getCandidateNoteTypes(){
    // Define the OWS method
    const requestMethod = 'Otys.Services.NoticeService.getTypesForEntity';
    // Define the body of the request (params)
    const requestBody = [
      "Candidate",
      "Note"
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'true'
      },
    };

    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if (cached_data) {
      this.candidateNoteTypes.next(cached_data.result);
    }

    if(this.cachingService.getCacheAge(requestMethod, requestBody) === false || this.cachingService.getCacheAge(requestMethod, requestBody) > 1440){
      // Retrieve live data
      this.http
      .post<any>(environment.owsUrl, requestBody, requestHeaders)
      .subscribe((resData) => {
        if (resData) {
          if (resData.result) {
             this.candidateNoteTypes.next(resData.result);
          }
        }
      });
    }
  }

  private getVacancyNoteTypes(){
    // Define the OWS method
    const requestMethod = 'Otys.Services.NoticeService.getTypesForEntity';
    // Define the body of the request (params)
    const requestBody = [
      "Vacancy",
      "Note"
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'true'
      },
    };

    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if (cached_data) {
      this.vacancyNoteTypes.next(cached_data.result);
    }

    if(this.cachingService.getCacheAge(requestMethod, requestBody) === false || this.cachingService.getCacheAge(requestMethod, requestBody) > 1440){
      // Retrieve live data
      this.http
      .post<any>(environment.owsUrl, requestBody, requestHeaders)
      .subscribe((resData) => {
        if (resData) {
          if (resData.result) {
             this.vacancyNoteTypes.next(resData.result);
          }
        }
      });
    }
  }

  private getTaskNoteTypes(){
    // Define the OWS method
    const requestMethod = 'Otys.Services.NoticeService.getTypesForEntity';
    // Define the body of the request (params)
    const requestBody = [
      "Task",
      "Note"
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'true'
      },
    };

    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if (cached_data) {
      this.taskNoteTypes.next(cached_data.result);
    }

    if(this.cachingService.getCacheAge(requestMethod, requestBody) === false || this.cachingService.getCacheAge(requestMethod, requestBody) > 1440){
      // Retrieve live data
      this.http
      .post<any>(environment.owsUrl, requestBody, requestHeaders)
      .subscribe((resData) => {
        if (resData) {
          if (resData.result) {
             this.taskNoteTypes.next(resData.result);
          }
        }
      });
    }
  }

  private getRelationNoteTypes(){
    // Define the OWS method
    const requestMethod = 'Otys.Services.NoticeService.getTypesForEntity';
    // Define the body of the request (params)
    const requestBody = [
      "Relation",
      "Note"
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'true'
      },
    };

    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if (cached_data) {
      this.relationNoteTypes.next(cached_data.result);
    }

    if(this.cachingService.getCacheAge(requestMethod, requestBody) === false || this.cachingService.getCacheAge(requestMethod, requestBody) > 1440){
      // Retrieve live data
      this.http
      .post<any>(environment.owsUrl, requestBody, requestHeaders)
      .subscribe((resData) => {
        if (resData) {
          if (resData.result) {
             this.relationNoteTypes.next(resData.result);
          }
        }
      });
    }
  }

  private getRelationContactNoteTypes(){
    // Define the OWS method
    const requestMethod = 'Otys.Services.NoticeService.getTypesForEntity';
    // Define the body of the request (params)
    const requestBody = [
      "RelationContact",
      "Note"
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'true'
      },
    };

    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if (cached_data) {
      this.relationContactNoteTypes.next(cached_data.result);
    }

    if(this.cachingService.getCacheAge(requestMethod, requestBody) === false || this.cachingService.getCacheAge(requestMethod, requestBody) > 1440){
      // Retrieve live data
      this.http
      .post<any>(environment.owsUrl, requestBody, requestHeaders)
      .subscribe((resData) => {
        if (resData) {
          if (resData.result) {
             this.relationContactNoteTypes.next(resData.result);
          }
        }
      });
    }
  }

  private getDocTypes(){
    // Define the OWS method
    const requestMethod = 'Otys.Services.AttachedDocumentsService.getOptionListsEx';
    // Define the body of the request (params)
    const requestBody = [
      [
        "typeId"
      ]
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'true'
      },
    };

    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if (cached_data) {

      let docTypesTemp = [];
      cached_data.result.typeId.forEach(function (document){
        docTypesTemp.push({
          title: document.value,
          uid: document.uid
        });
      });
      this.documentTypes.next(docTypesTemp);
    }

    if(this.cachingService.getCacheAge(requestMethod, requestBody) === false || this.cachingService.getCacheAge(requestMethod, requestBody) > 1440){
      // Retrieve live data
      this.http
      .post<any>(environment.owsUrl, requestBody, requestHeaders)
      .subscribe((resData) => {
        if (resData) {
          if (resData.result) {
              let docTypesTemp = [];
              resData.result.typeId.forEach(function (document){
                docTypesTemp.push({
                  title: document.value,
                  uid: document.uid
                });
              });
              this.documentTypes.next(docTypesTemp);
          }
        }
      });
    }
  }

  createDocument(entityType:number,subject:string, typeId:string, privateDoc:boolean, alwaysOnTop:boolean, description:string, entityUid:any, fileUid:string){
    // Define the OWS method
    const requestMethod = 'Otys.Services.AttachedDocumentsService.add';
    // Define the body of the request (params)
    const requestBody:any = [
      {
        subject: subject,
        typeId: typeId,
        private: privateDoc,
        alwaysOnTop: alwaysOnTop,
        description: description,
        fileUid: fileUid
      }
    ];

    if(entityType == 1){
      requestBody[0].vacancyUid = entityUid;
    }
    if(entityType == 2){
      requestBody[0].candidateUid = entityUid;
    }
    if(entityType == 3){
      requestBody[0].relationUid = entityUid;
    }
    if(entityType == 4){
      requestBody[0].relationContactUid = entityUid;
    }

    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'false'
      },
    };

    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

  createNote(entityType:number,subject:string, typeId:string, privateDoc:boolean, alwaysOnTop:boolean, description:string, entityUid:any){
    // Define the OWS method
    const requestMethod = 'Otys.Services.NoticeService.add';
    // Define the body of the request (params)
    const requestBody:any = [
      {
        subject: subject,
        text: description,
        typeId: typeId,
        private: privateDoc,
        alwaysOnTop: alwaysOnTop
      }
    ];

    if(entityType == 1){
      requestBody[0].vacancyUid = entityUid;
      requestBody[0].Bindings = [
        {
          entityId: 1,
          uid: entityUid
        }
      ];
    }
    if(entityType == 2){
      requestBody[0].candidateUid = entityUid;
      requestBody[0].Bindings = [
        {
          entityId: 2,
          uid: entityUid
        }
      ];
    }
    if(entityType == 3){
      requestBody[0].relationUid = entityUid;
      requestBody[0].Bindings = [
        {
          entityId: 3,
          uid: entityUid
        }
      ];
    }
    if(entityType == 4){
      requestBody[0].relationContactUid = entityUid;
      requestBody[0].Bindings = [
        {
          entityId: 4,
          uid: entityUid
        }
      ];
    }

    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'false'
      },
    };

    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

  getCandidateCVs(uid:string){
    // Define the OWS method
    const requestMethod = 'Otys.Services.AttachedDocumentsService.getList';
    // Define the body of the request (params)
    const requestBody:any = [
      {
        what: {
          uid: 1,
          fileName: 1,
          subject: 1,
          entryDateTime: 1,
          type: 1
        },
        condition: {
          type: "AND",
          invert: false,
          items: [
            {
              type: "COND",
              field: "candidateUid",
              op: "EQ",
              param: uid
            },
            {
              type: "COND",
              field: "typeId",
              op: "EQ",
              param: 1
            }
          ]
        },
        sort: {
          entryDateTime: "DESC"
        },
        limit: 20
      }
    ];

    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'false'
      },
    };

    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

  getFileTempKey(fileUid:number){
    // Define the OWS method
    const requestMethod = 'Otys.Services.FileService.getTempKey';
    // Define the body of the request (params)
    const requestBody:any = [
      "AttachedDocument",
      fileUid
    ];

    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'false'
      },
    };

    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

  getNote(uid:number){
    // Define the OWS method
    const requestMethod = 'Otys.Services.NoticeService.getDetail';
    // Define the body of the request (params)
    const requestBody:any = [
      uid,
      {
        uid: 1,
        user: 1,
        entryDateTime: 1,
        lastModified: 1,
        subject: 1,
        text: 1,
        typeId: 1,
        type: 1,
        private: 1,
        privateDossierGroupId: 1,
        alwaysOnTop: 1,
        Bindings: 1,
        incomingOutgoing: 1,
        relationContact: 1,
        relation: 1,
        customerRightsLevel: 1
      }
    ];

    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'false'
      },
    };

    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }


}
