import { Subscription } from 'rxjs';
import { Component, Input, OnDestroy, OnInit, ViewChild, ApplicationRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SortableOptions } from 'sortablejs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { User } from 'src/app/core/auth/user.model';
import { setting, theme } from 'src/app/core/interfaces/settings.interface';
import { SettingsService } from 'src/app/core/services/settings.service';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import SwiperCore, { EffectCoverflow } from 'swiper';

SwiperCore.use([EffectCoverflow]);

@Component({
  selector: 'app-setting-tab',
  templateUrl: './setting-tab.component.html',
  styleUrls: ['./setting-tab.component.sass']
})
export class SettingTabComponent implements OnInit, OnDestroy {

  @Input() allowedSettings:string[];

  candFilter = new FormControl();

  darkmodeSetting:any;
  darkmodeValue:number;

  languageSetting:any;
  languageValue:number;

  settings:setting;

  themeNow:any;
  appthemes:theme[] = [
    {
      themeMain: '#0c88e0',
      themeBackground : '#F3F5FF'
    },
    {
      themeMain: '#0B30E0',
      themeBackground : '#E6EAFC'
    },
    {
      themeMain: '#6941C7',
      themeBackground : '#F0EBF9'
    },
    {
      themeMain: '#f55839',
      themeBackground : '#f5f5f5'
    },
    {
      themeMain: '#ff4a6a',
      themeBackground : '#ffeff2'
    }
  ]

  canShowCandidateWidget:boolean = false;
  canShowReminderWidget:boolean = false;
  canShowEmailsWidget:boolean = false;
  current_user:User;

  sortableOptions:SortableOptions;

  $subscription1:Subscription;
  $subscription2:Subscription;

  config: SwiperOptions = {
    slidesPerView: 4,
    spaceBetween: 0,
    centeredSlides: true,
    loop: true,
    slideToClickedSlide: true
  };

  @ViewChild('swiperTheme', { static: false }) swiperTHeme?: SwiperComponent;

  constructor(private settingService:SettingsService, private authService: AuthService, private applicationRef: ApplicationRef) {

    this.$subscription1 = this.authService.user.subscribe(res => {
      this.current_user = res;
      this.canShowCandidateWidget = this.current_user.modules.includes('Candidates');
      this.canShowReminderWidget = this.current_user.modules.includes('Reminders');
      this.canShowEmailsWidget = this.current_user.modules.includes('Emails');
    })

    this.settings = this.settingService.getSettings();

    this.$subscription2 = this.settingService.themeObservable.subscribe(res => {
      if(res){
        // When the theme get's updated let's also update the settings page
        this.themeNow = this.settingService.getSetting('01theme').value;
      }
    });

    this.themeNow = this.settingService.getSetting('01theme').value;

    this.candFilter.setValue(this.settings['03candidateFilterOnDashboard'].value);

    this.sortableOptions = {
      animation: 500 ,
      handle: '.sort-grabber',
      onUpdate: (event: any) => {
        this.onLayoutSorted();
        this.onUpdateSetting('02dashboardLayout', this.settings['02dashboardLayout'].value);
      }
     }
  }

  ngOnInit(): void {
    if(this.allowedSettings.includes('01theme')){
      this.setCurrentTheme();
    }

    if(this.allowedSettings.includes('05darkMode')){
      this.darkmodeSetting = this.settings['05darkMode'];
      this.darkmodeValue = this.settings['05darkMode'].value;
    }

    if(this.allowedSettings.includes('06language')){
      this.languageSetting = this.settings['06language'];
      this.languageValue = this.settings['06language'].value;
    }

  }

  setCurrentTheme(){
    let active_theme = this.appthemes.findIndex(x => x.themeMain === this.themeNow.themeMain);
    this.config.initialSlide = active_theme;
  }

  onUpdateSetting(key:string,newvalue:string|boolean|number|theme){
    this.settingService.setSetting(key, newvalue);
  }

  onSwiper(swiperInstance:any){
    let themeIndex = swiperInstance[0].realIndex;
    let themeRef = this.appthemes[themeIndex];
    if(themeRef.themeMain != this.themeNow.themeMain){
      this.onUpdateSetting('01theme', {
        themeMain: themeRef.themeMain,
        themeBackground: themeRef.themeBackground
      });
      this.applicationRef.tick();
    }
  }

  onLayoutSorted(){
    console.log(this.settings);
  }

  onUpdateLimit(event:any, layoutItemIndex:any){
    if(parseInt(event.target.value) > 20){
      event.target.value = 20;
    }else if(parseInt(event.target.value) < 1){
      event.target.value = 1;
    }
    this.settings['02dashboardLayout'].value[layoutItemIndex].limit = parseInt(event.target.value);
    this.onUpdateSetting('02dashboardLayout', this.settings['02dashboardLayout'].value);
  }

  onUpdateEnabled(event:any, layoutItemIndex:any){
    this.settings['02dashboardLayout'].value[layoutItemIndex].enabled = event;
    this.onUpdateSetting('02dashboardLayout', this.settings['02dashboardLayout'].value);
  }

  onCandidateFilterUpdate(){
    this.onUpdateSetting('03candidateFilterOnDashboard', this.candFilter.value);
  }

  onUpdateSettingDarkMode(index:number){
    this.onUpdateSetting('05darkMode', index);
    this.darkmodeValue = index;
  }

  onUpdateSettingLanguage(index:number){
    this.onUpdateSetting('06language', index);
    this.languageValue = index;
  }

  ngOnDestroy(): void {
    if(this.$subscription1){
      this.$subscription1.unsubscribe();
    }
    if(this.$subscription2){
      this.$subscription2.unsubscribe();
    }
  }
}
