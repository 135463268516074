<app-header
  *transloco="let t; read: 'relationContacts'"
  listType="relationcontact"
  [title]="t('pageTitle') | capitalize"
  [searchPlaceHolder]="t('searchTitle')"
  (onSearchInput)="onSearchInput($event)"
  [searchResults]="searchResults.asObservable()"
  [additionalSection]="storedQueriesDrawer"
></app-header>

<ng-template #storedQueriesDrawer>
  <app-storedqueries-drawer handle="RELATIONCONTACTS" [open]="showStoredQueries" (openClose)="showStoredQueries = $event" (filterData)="onStoredQueryClicked($event)"></app-storedqueries-drawer>
</ng-template>

<div class="page">
  <app-relations-contact-list [relationContactsListing]="relationContactsListing"></app-relations-contact-list>
  <app-no-results *ngIf="relationContactsListing.length && relationContactsListing[0].items.length == 0 && relationsLoading == false"></app-no-results>
  <app-load-more-button
    [showLoadMoreButton]="showLoadMoreButton"
    (buttonClicked)="onNextPage()"
  ></app-load-more-button>
</div>
