<header>
  <div class="detail-page-header" *ngIf="candidateLoaded">
    <div class="topbar">
      <app-back-button></app-back-button>
      <div class="status" *ngIf="candidate.status">
        {{candidate.status}}
      </div>
      <app-favorite-star [entity]="2" [uid]="candidateUid"></app-favorite-star>
    </div>
    <div class="main-info">
      <div class="detail-image" [ngStyle]="{'background-image': (candidate.Person.photoFileName && !pictureUpload) ? 'url('+candidatePhotoUrl+')' : ''}" (click)="showPhotoDetail = true">
        <ng-container *ngIf="!candidate.Person.photoFileName">
          <span>{{candidate.fullname|getInitials}}</span>
        </ng-container>
        <img src="/assets/images/spinner.svg" width="38" height="38" *ngIf="pictureUpload" class="uploadingSpinner">
      </div>
      <div class="text-info">
        <h1>{{candidate.fullname}}</h1>
      </div>
    </div>
  </div>
  <div class="tabs-nav">
    <div class="swiper-pagination"></div>
  </div>
</header>

<app-picture-detail [photoUrl]="candidatePhotoUrl" [showDetailed]="showPhotoDetail" (pictureState)="showPhotoDetail = false" (uploadedFileName)="onNewPictureUploaded($event)" (pictureUploadStart)="pictureUpload = true" (pictureRemove)="onRemovePicture()"></app-picture-detail>

<div class="tabswrap" *ngIf="candidate">
  <swiper #swiper [config]="config" *transloco="let t; read: 'candidates'">
      <ng-template swiperSlide>
        <div class="tab">
          <div class="tab-inner">
            <div class="icon-and-text-vals" *ngIf="candidate.phone || candidate.Person.emailPrimary">
              <app-phone-number-with-icons [phone]="candidate.phone"></app-phone-number-with-icons>
              <app-mail-with-icon [mail]="candidate.Person.emailPrimary"></app-mail-with-icon>
              <app-adress-with-icon [adress]="candidate.Addresses.home"></app-adress-with-icon>
            </div>

            <button *ngIf="lastCVUid" [routerLink]="['/dossier/view/' + lastCVUid]" class="view_cv_button secondary" id="candidateDetailViewCVButton">
              <i class="fa-solid fa-file-user cvbuttonicon"></i> {{t('viewCv')}}
            </button>

            <app-detailpage-item-value *ngIf="candidate.Person.birthdate" [label]="t('birthDate')" [value]="candidate.Person.birthdate|dateconverter|date"></app-detailpage-item-value>
            <app-detailpage-item-value *ngIf="candidate.Person.placeOfBirth" [label]="t('placeOfBirth')" [value]="candidate.Person.placeOfBirth"></app-detailpage-item-value>
            <app-detailpage-item-value *ngIf="candidate.user" [label]="t('connectedConsultant')" [value]="candidate.user"></app-detailpage-item-value>
            <app-detailpage-item-value *ngIf="candidate.entryDateTime" [label]="t('entryDate')" [value]="candidate.entryDateTime|customDate"></app-detailpage-item-value>
            <app-detailpage-item-value *ngIf="candidate.applyCount" [label]="t('applyCount')" [value]="candidate.applyCount"></app-detailpage-item-value>
            <app-detailpage-item-value *ngIf="candidate.nationality" [label]="t('nationality')" [value]="candidate.nationality"></app-detailpage-item-value>
            <app-detailpage-item-value *ngIf="candidate.Person.married" [label]="t('married')" [value]="t('yes')"></app-detailpage-item-value>

            <div class="extra-detailpage-item" *ngIf="candidate.shortDescription">
              <label>{{t('shortDescription')}}</label>
              <div class="value shortdescval" [innerHTML]="candidate.shortDescription"></div>
            </div>

            <div class="extra-detailpage-item" *ngIf="
              (candidate.matchCriteria_1 && candidate.matchCriteria_1.length !== 0 )||
              (candidate.matchCriteria_2 && candidate.matchCriteria_2.length !== 0 )||
              (candidate.matchCriteria_3 && candidate.matchCriteria_3.length !== 0 )||
              (candidate.matchCriteria_4 && candidate.matchCriteria_4.length !== 0 )||
              (candidate.matchCriteria_5 && candidate.matchCriteria_5.length !== 0 )||
              (candidate.matchCriteria_6 && candidate.matchCriteria_6.length !== 0 )||
              (candidate.matchCriteria_7 && candidate.matchCriteria_7.length !== 0 )||
              (candidate.matchCriteria_8 && candidate.matchCriteria_8.length !== 0 )||
              (candidate.matchCriteria_9 && candidate.matchCriteria_9.length !== 0 )||
              (candidate.matchCriteria_10 && candidate.matchCriteria_10.length !== 0) ||
              (candidate.matchCriteria_11 && candidate.matchCriteria_11.length !== 0) ||
              (candidate.matchCriteria_12 && candidate.matchCriteria_12.length !== 0) ||
              (candidate.matchCriteria_13 && candidate.matchCriteria_13.length !== 0) ||
              (candidate.matchCriteria_14 && candidate.matchCriteria_14.length !== 0) ||
              (candidate.matchCriteria_15 && candidate.matchCriteria_15.length !== 0) ||
              (candidate.matchCriteria_16 && candidate.matchCriteria_16.length !== 0) ||
              (candidate.matchCriteria_17 && candidate.matchCriteria_17.length !== 0) ||
              (candidate.matchCriteria_18 && candidate.matchCriteria_18.length !== 0)
            ">
              <ng-container *ngFor="let criteriaName of candidate.matchCriteriaNames | keyvalue">
                <app-candidate-criteria-item [criteriaValues]="candidate[criteriaName.key]"  [criteriaName]="criteriaName.value"></app-candidate-criteria-item>
              </ng-container>
            </div>

          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="tab">
          <app-dossier-tab [dossierEntity]="2" [entityUid]="candidateUid" *ngIf="showDossier"></app-dossier-tab>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="tab">
          <div class="tab-inner">
            <div class="vacancy-procedures-list">
              <div class="list-item list-item-procedure" *ngFor="let procedure of procedures" [routerLink]="['/vacancies/' + procedure.vacancyUid]">
                <div class="list-image">
                  <span *ngIf="procedure.vacancy">
                    {{ procedure.vacancy | getInitials}}
                  </span>
                </div>
                <div class="list-item-content">
                  <div class="list-item-content-left">
                    <div class="list-item-subject medium">{{procedure.vacancy}}</div>
                    <div class="list-item-status" *ngIf="procedure.ProcedureStatus3.status">{{procedure.ProcedureStatus3.status}}</div>
                    <div class="list-item-status" *ngIf="!procedure.ProcedureStatus3.status && procedure.ProcedureStatus2.status">{{procedure.ProcedureStatus2.status}}</div>
                    <div class="list-item-status" *ngIf="!procedure.ProcedureStatus3.status && !procedure.ProcedureStatus2.status && procedure.ProcedureStatus1.status">{{procedure.ProcedureStatus1.status}}</div>
                  </div>
                  <div class="list-item-content-right">
                    <div class="top">
                      <div class="list-item-date" *ngIf="procedure.entryDateTime">{{procedure.entryDateTime|customDate}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <app-no-results *ngIf="!procedures.length"></app-no-results>
          </div>
        </div>
      </ng-template>
  </swiper>
</div>
