<div class="pictureWrap" *ngIf="showDetailed" [ngClass]="{'loaded': loadStep1}">
  <div class="picture" *ngIf="photoUrl" [ngStyle]="{'background-image': (photoUrl) ? 'url('+photoUrl+')' : ''}" (outsideClick)="onCloseDetail()" [ngClass]="{'loaded': loadStep2}">
  </div>
</div>

<div class="hiddenPictureUpload">
  <div class="uploaded-file" *ngIf="documentFileName" (click)="onRemoveUploadedFile()">
    {{documentFileName}} <i class="fa-solid fa-xmark"></i>
  </div>
  <input type="file" accept="image/*" (change)="onFileSelected($event.target.files)" #fileinput [ngClass]="{'hidden': documentFileName}">
</div>

<app-drawer-component *transloco="let t; read: 'shared'" [drawerOpen]="openDrawer" (drawerState)="onDrawerStateChange($event)" (onClicked)="onDrawerItemClicked($event)" [drawerTitle]="t('pickAnAction')" [list]="drawerList" [backdropOpacity]="0"></app-drawer-component>
