import { Subject, Subscription } from 'rxjs';
import { AuthService } from './../../core/auth/auth.service';
import { Component, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/core/auth/user.model';
import { storedQueryData } from 'src/app/core/interfaces/stored-query.interface';
import { VacancyService } from 'src/app/core/services/vacancy.service';
import { vacanciesPage } from 'src/app/core/interfaces/vacancies.interface';

@Component({
  selector: 'app-vacancies',
  templateUrl: './vacancies.component.html',
  styleUrls: ['./vacancies.component.sass'],
})

export class VacanciesComponent implements OnDestroy {

  vacanciesListing:vacanciesPage[] = [];

  showLoadMoreButton: boolean = false;
  vacanciesLoading: boolean = false;
  currentPage: number = 1;
  current_user: User;
  searchResults: Subject<any> = new Subject<any>();

  showStoredQueries: boolean = false;
  storedQueryData: storedQueryData;

  $subscription1: Subscription;
  $subscription2: Subscription;
  $subscription3: Subscription;

  constructor(
    private vacancyService: VacancyService,
    private authService: AuthService
  ) {
    this.$subscription3 = this.authService.user.subscribe((res) => {
      this.current_user = res;
    });
  }

  onStoredQueryClicked(storedQueryData: storedQueryData) {
    if (this.$subscription1) {
      this.$subscription1.unsubscribe();
    }
    this.storedQueryData = storedQueryData;
    this.currentPage = 1;
    this.vacanciesListing = [];
    this.loadvacancies();
  }

  loadvacancies() {
    this.vacanciesLoading = true;

    this.$subscription1 = this.vacancyService
      .getVacancyList(this.currentPage, this.storedQueryData)
      .subscribe((resData) => {
        if (resData) {
          if (resData.items.length < 20) {
            this.showLoadMoreButton = false;
          } else {
            this.showLoadMoreButton = true;
          }
          if (this.vacanciesListing.find((o) => o.page === this.currentPage)) {
            this.vacanciesListing.find(
              (o:vacanciesPage, i: number) => {
                if (o.page === this.currentPage) {
                  this.vacanciesListing[i] = resData;
                  return true;
                } else {
                  return false;
                }
              }
            );
          } else {
            this.vacanciesListing.push(resData);
          }

          this.vacanciesLoading = false;

          this.addPhotoUrlToVacancies();
        }
      });
  }

  onNextPage() {
    if (this.vacanciesLoading == false) {
      this.currentPage++;
      this.loadvacancies();
    }
  }

  addPhotoUrlToVacancies() {
    var sess = this.current_user.sessionId;
    this.vacanciesListing.forEach(function (page) {
      page.items.forEach(function (vacancy) {
        if (vacancy.photoFileName) {
          vacancy.photoUrl =
            environment.fileServiceDownload +
            '?ouid=' +
            vacancy.photoFileName +
            '&class=VacancyPhoto&sessionId=' +
            sess;
        } else {
          vacancy.photoUrl = null;
        }
      });
    });
  }

  onSearchInput(searchInput: string) {
    var sess = this.current_user.sessionId;
    // There should be at least 2 characters
    if (searchInput.length > 2) {
      this.$subscription2 = this.vacancyService
        .getSearchResults(searchInput)
        .subscribe((resData) => {
          if (resData) {
            resData.items.forEach(function (vacancy) {
              if (vacancy.photoFileName) {
                vacancy.photoUrl =
                  environment.fileServiceDownload +
                  '?ouid=' +
                  vacancy.photoFileName +
                  '&class=VacancyPhoto&sessionId=' +
                  sess;
              } else {
                vacancy.photoUrl = null;
              }
            });
            this.searchResults.next(resData);
          } else {
            this.searchResults.next([]);
          }
        });
    } else {
      this.searchResults.next([]);
    }
  }

  ngOnDestroy(): void {
    if (this.$subscription1) {
      this.$subscription1.unsubscribe();
    }
    if (this.$subscription2) {
      this.$subscription2.unsubscribe();
    }
    if (this.$subscription3) {
      this.$subscription3.unsubscribe();
    }
  }
}
