import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { AuthService } from './../../../core/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { RelationService } from './../../../core/services/relation.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { relationResult } from 'src/app/core/interfaces/relation.interface';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/core/auth/user.model';
import SwiperCore, { Pagination, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

SwiperCore.use([Pagination]);

@Component({
  selector: 'app-relation-detail',
  templateUrl: './relation-detail.component.html',
  styleUrls: ['./relation-detail.component.sass'],
})
export class RelationDetailComponent implements OnInit,OnDestroy {

  relationLoaded: boolean = false;
  relationUid: number;
  relation: relationResult;
  relationLogoUrl: string;
  crmCriteriaNames: any;
  crmCriterias: any = [];
  vacanciesEnabled:boolean = false;
  config: SwiperOptions = {
    slidesPerView: 1,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        let tabs = ['General', 'Dossier', 'Contacts', 'Vacancies'];
        return '<span class="' + className + '">' + tabs[index] + '</span>';
      },
    },
  };
  current_user: User;
  relationContacts:any = [];
  relationVacancies:any = [];
  showDossier:boolean = false;
  documentTypeDrawer:any;

  $subscription1:Subscription;
  $subscription2:Subscription;
  $subscription3:Subscription;
  $subscription4:Subscription;
  $subscription5:Subscription;
  $subscription6:Subscription;
  $subscription7:Subscription;


  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  constructor(
    private relationService: RelationService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private translationService: TranslocoService
  ) {
    this.$subscription1 = this.authService.user.subscribe((res) => {
      this.current_user = res;
    });

    // When the component is loaded trough an URL including the uid param in the route, we should read it
    this.$subscription2 = this.route.params.subscribe((params) => {
      if (params['uid']) {
        // If uid is present in the route and it isn't equal to the current uid
        if (this.relationUid != parseInt(params['uid'])) {
          // Set this as the current uid
          this.relationUid = parseInt(params['uid']);
        }
      }
    });
  }

  ngOnInit(): void {
    this.loadCriteriaNames();
    this.loadRelation();
    this.getRelationContacts();
    this.getVacancies();
    this.loadTabTranslations();
  }

  loadRelation() {
    this.$subscription3 = this.relationService
      .getRelationDetail(this.relationUid)
      .subscribe((response) => {
        if (response) {
          this.relation = response;
          this.addLogoUrl();
          this.relationLoaded = true;

          this.createCrmNameValueObject();
          this.showDossier = true;
        }
      });
  }

  createCrmNameValueObject() {
    // Reset the excisting array if this triggers multiple times
    this.crmCriterias = [];
    // Now go trough the criteria names and push them to an workable object array
    for (let item in this.crmCriteriaNames) {
      // Exclude the use_ranking item because this does NOT belong there
      if (item !== 'use_ranking') {
        let nameObjVal = this.crmCriteriaNames[item];
        // Now push this workable object to the array
        this.crmCriterias.push({
          uid: nameObjVal.uid,
          name: nameObjVal.name,
          values: [],
        });
      }
    }
    // Check if this relations has any criteria
    if (this.relation.Criteria !== 0) {
      // Now go trough these criteria and push them to the corresponding object within the criteria array
      for (let relCrit of Object.keys(this.relation.Criteria)) {
        let relCritVal = this.relation.Criteria[relCrit];
        this.crmCriterias.find((o,i) => {
          if (o.uid == relCritVal.categoryId) {
            this.crmCriterias[i].values.push(relCritVal.value);
            return true;
          }else{
            return false;
          }
        });
      }
    }
  }

  loadCriteriaNames() {
    this.$subscription4 = this.relationService.getCrmCriteriaNames().subscribe((result) => {
      if (result) {
        this.crmCriteriaNames = result;
      }
    });
  }

  addLogoUrl() {
    let sess = this.current_user.sessionId;
    this.relationLogoUrl =
      environment.fileServiceDownload +
      '?ouid=' +
      this.relationUid +
      '&class=RelationLogo&sessionId=' +
      sess;
  }

  getVacancies(){
    if(this.current_user.modules.includes('Vacancies')){
      this.$subscription5 = this.relationService.getVacancies(this.relationUid).subscribe(res => {
          if(res && res.result && res.result.listOutput.length){
            this.relationVacancies = res.result.listOutput;
          }
        });
    }
  }

  getRelationContacts(){
    this.$subscription6 = this.relationService.getRelationContacts(this.relationUid).subscribe(res => {
      if(res && res.result && res.result.listOutput){

        let contacts = res.result.listOutput;
        contacts.forEach(function(relation){


          // Create a fullname field
          relation.fullname = '';
          if(relation.Person.firstName)
            relation.fullname += relation.Person.firstName;
          if(relation.Person.infix)
            relation.fullname += ' ' + relation.Person.infix;
          if(relation.Person.lastName)
            relation.fullname += ' ' + relation.Person.lastName;

          // Create a phone number field.
          relation.phone = '';
          if(relation.Person.PhoneNumbers["1"].phoneNumber && relation.phone == '')
            relation.phone = relation.Person.PhoneNumbers["1"].phoneNumber
          if(relation.Person.PhoneNumbers["2"].phoneNumber && relation.phone == '')
            relation.phone = relation.Person.PhoneNumbers["2"].phoneNumber
          if(relation.Person.PhoneNumbers["3"].phoneNumber && relation.phone == '')
            relation.phone = relation.Person.PhoneNumbers["3"].phoneNumber
          if(relation.Person.PhoneNumbers["4"].phoneNumber && relation.phone == '')
            relation.phone = relation.Person.PhoneNumbers["4"].phoneNumber
          if(relation.Person.PhoneNumbers["5"].phoneNumber && relation.phone == '')
            relation.phone = relation.Person.PhoneNumbers["5"].phoneNumber
          if(relation.Person.PhoneNumbers["6"].phoneNumber && relation.phone == '')
            relation.phone = relation.Person.PhoneNumbers["6"].phoneNumber
        });

        this.relationContacts = contacts;
      }
    });
  }


  loadTabTranslations(){
    this.$subscription7 = this.translationService.selectTranslate('relations.tabGeneral').subscribe(res => {
      // Get the translation values for the tabs
      let tabs = [
        this.translationService.translate('relations.tabGeneral'),
        this.translationService.translate('relations.tabDossier'),
        this.translationService.translate('relations.tabContacts'),
        this.translationService.translate('relations.tabVacancies')
      ];
      // Set the new renderBullet function for the new translations
      this.config.pagination = {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + tabs[index] + '</span>';
        },
      };
    });
  }

  ngOnDestroy(): void {
    if(this.$subscription1){
      this.$subscription1.unsubscribe();
    }
    if(this.$subscription2){
      this.$subscription2.unsubscribe();
    }
    if(this.$subscription3){
      this.$subscription3.unsubscribe();
    }
    if(this.$subscription4){
      this.$subscription4.unsubscribe();
    }
    if(this.$subscription5){
      this.$subscription5.unsubscribe();
    }
    if(this.$subscription6){
      this.$subscription6.unsubscribe();
    }
    if(this.$subscription7){
      this.$subscription7.unsubscribe();
    }
  }

}
