import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { calendarUser } from 'src/app/core/interfaces/calendar-interface';
import { CalendarService } from 'src/app/core/services/calendar.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-top-buttons',
  templateUrl: './top-buttons.component.html',
  styleUrls: ['./top-buttons.component.sass']
})
export class TopButtonsComponent implements OnInit,OnDestroy{

  calendarUsers:calendarUser[] = [];
  $userSubscription:Subscription;
  usersControl = new FormControl();

  @Output() selectedUsers: EventEmitter<number[]> = new EventEmitter();

  constructor(private calendarService: CalendarService) {
  }

  ngOnInit(): void {
    this.onGetSelectedUsers();
    this.onLoadUsers();
  }

  onSelectUpdate(){
    this.selectedUsers.emit(this.usersControl.value);
  }

  onGetSelectedUsers(){
    this.calendarService.getSelectedUsers().subscribe(res => {
      this.usersControl.patchValue(res.result);
      this.selectedUsers.emit(res.result);
    });
  }

  onLoadUsers(){
    this.$userSubscription = this.calendarService.getUsers().subscribe((resUsers) => {
      if(resUsers){
        this.calendarUsers = resUsers.result.users;
      }
    });
  }

  ngOnDestroy(): void {
    if(this.$userSubscription){
      this.$userSubscription.unsubscribe();
    }
  }
}
