<div class="daypop" *ngIf="dayPopupActive" [ngClass]="{'updating' : changesLoading}">
  <swiper #swiper [config]="config" (activeIndexChange)="onIndexChange($event[0].activeIndex)" (afterInit)="onSwiperLoaded()" *ngIf="useSwiper">

    <ng-template swiperSlide>
      <div class="day">
        <div class="daybackdrop" (click)="onCloseDay()"></div>
        <div class="day-inner prev-next-control" *transloco="let t; read: 'calendar'">
           <button class="onnextprevbutton prev" id="calendarDaySliderPrevButton" (click)="onNavigateMonth('prev')"><i class="fa-solid fa-arrow-left-long"></i>{{t('prevMonthButton')}}</button>
        </div>
      </div>
    </ng-template>

    <ng-template swiperSlide *ngFor="let day of dayPopupDays">
      <div class="day" *transloco="let t; read: 'calendar'">
        <div class="daybackdrop" (click)="onCloseDay()"></div>
        <div class="day-inner">
          <div class="day-title">
            <div class="daynumber" [ngClass]="{'today': (day.date|date) == (today | date)}">
              {{day.date|date:'d'}}
            </div>
            <div class="dayname">
              {{day.date|date:'EEEE'}}
            </div>
          </div>
          <div class="listview" *ngIf="!planmode">
            <div class="events" *ngIf="day.events.length > 0">
              <div class="eventWrapper" *ngFor="let event of day.events; let i = index">
                <div class="travelTime" *ngIf="event._def.extendedProps.travelTimeTo">
                  {{t('travelTimeTo')}} {{t('min', {'value' : event._def.extendedProps.travelTimeTo})}}
                </div>
                <div class="event" [routerLink]="(event._def.extendedProps.private == true && event._def.extendedProps.userId !== userId) ? [] : ['/calendar/compose/' + event._def.extendedProps.uid]">
                  <div class="event-titlewrap">
                    <div class="event-title">{{event.title}}</div>
                    <div class="invitationscount" *ngIf="event._def.extendedProps.invitationsCount > 0">
                      <i class="fa-solid fa-user" *ngIf="event._def.extendedProps.invitationsCount == 1"></i>
                      <i class="fa-solid fa-user-group" *ngIf="event._def.extendedProps.invitationsCount == 2"></i>
                      <i class="fa-solid fa-users" *ngIf="event._def.extendedProps.invitationsCount > 2"></i>
                      {{event._def.extendedProps.invitationsCount}}
                    </div>
                  </div>
                  <div class="event-content">
                    <div class="event-color" [ngStyle]="event._def.extendedProps.typeColor != '#' && {backgroundColor: event._def.extendedProps.typeColor}"></div>
                    <div class="event-time">
                      <ng-container *ngIf="event._def.allDay === true">
                        <span>{{t('wholeDay')}}</span>
                      </ng-container>
                      <ng-container *ngIf="event._def.allDay !== true">
                        <span class="start">{{event.start|date:'shortTime'}}</span> - <span class="end">{{event.end|date:'shortTime'}}</span>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="travelTime" *ngIf="event._def.extendedProps.travelTimeFrom">
                  {{t('travelTimeFrom')}} {{t('min', {'value' : event._def.extendedProps.travelTimeFrom})}}
                </div>
              </div>
            </div>
            <div class="events" *ngIf="day.events.length < 1">
              <p class="no-events">{{t('noCalendarItems')}}</p>
            </div>
          </div>
          <div class="planview" *ngIf="planmode">
            <div class="slots">
              <div class="slot allday_slot" [routerLink]="['/calendar/compose']" [queryParams]="{mode : 'allday', day: day.date|date : 'YYYY-MM-dd 09:00'}">
                <span>{{t('wholeDay')}}</span>
                <div class="slotEvents">
                  <ng-container *ngFor="let event of day.events; let i = index">
                    <div class="slotevent" *ngIf="event._def.allDay" [routerLink]="['/calendar/compose/' + event._def.extendedProps.uid]">
                      <div class="slotevent-inner" [ngStyle]="event._def.extendedProps.typeColor != '#' && {borderLeftColor: event._def.extendedProps.typeColor}">
                        <div class="slotevent-event-titlewrap">
                          <div class="slotevent-event-title">{{event.title}}</div>
                          <div class="invitationscount" *ngIf="event._def.extendedProps.invitationsCount > 0">
                            <i class="fa-solid fa-user" *ngIf="event._def.extendedProps.invitationsCount == 1"></i>
                            <i class="fa-solid fa-user-group" *ngIf="event._def.extendedProps.invitationsCount == 2"></i>
                            <i class="fa-solid fa-users" *ngIf="event._def.extendedProps.invitationsCount > 2"></i>
                            {{event._def.extendedProps.invitationsCount}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="slot" *ngFor="let slot of daySlots"  [routerLink]="['/calendar/compose']" [queryParams]="{day: (day.date|date : 'YYYY-MM-dd') + ' ' + (slot|date : 'HH:mm')}">

                <span>{{slot|date:'shortTime'}}</span>

                <div class="slotEvents" *ngIf="day.events.length > 0">

                  <ng-container *ngFor="let event of day.events; let i = index">

                    <div
                      class="slotevent"
                      *ngIf="(slot|date:'HH') == (event.start|date:'HH')"
                      [routerLink]="['/calendar/compose/' + event._def.extendedProps.uid]"
                    >
                      <div class="travelTime travelTimeTo" *ngIf="event._def.extendedProps.travelTimeTo" [style.height.px]="event._def.extendedProps.travelTimeTo / 3 * 2" [style.transform]="'translateY('+(event.start|date:'m') / 3 * 2+'px)'">
                        <div class="travelicon">
                          <i class="fa-solid fa-car-side"></i>
                          {{t('min', {'value' : event._def.extendedProps.travelTimeTo})}}
                        </div>
                      </div>
                      <div class="slotevent-inner"
                      [style.height.px]="((event.end) - (event.start)) / 60000 / 3 * 2"
                      [style.marginTop.px]="(event.start|date:'m') / 3 * 2"
                      [ngStyle]="event._def.extendedProps.typeColor != '#' && {borderLeftColor: event._def.extendedProps.typeColor}">
                        <div class="slotevent-event-titlewrap">
                          <div class="slotevent-event-title" [ngClass]="{'smallTitle': (((event.end) - (event.start)) / 60000 / 3 * 2) < 20}">{{event.title}}</div>
                          <div class="invitationscount" *ngIf="event._def.extendedProps.invitationsCount > 0">
                            <i class="fa-solid fa-user" *ngIf="event._def.extendedProps.invitationsCount == 1"></i>
                            <i class="fa-solid fa-user-group" *ngIf="event._def.extendedProps.invitationsCount == 2"></i>
                            <i class="fa-solid fa-users" *ngIf="event._def.extendedProps.invitationsCount > 2"></i>
                            {{event._def.extendedProps.invitationsCount}}
                          </div>
                        </div>
                      </div>
                      <div class="travelTime travelTimeFrom" *ngIf="event._def.extendedProps.travelTimeFrom" [style.height.px]="event._def.extendedProps.travelTimeFrom / 3 * 2" [style.top.px]="(((event.end) - (event.start)) / 60000 / 3 * 2) + ((event.start|date:'m') / 3 * 2)">
                        <div class="travelicon">
                          <i class="fa-solid fa-car-side fa-flip-horizontal"></i>
                          {{t('min', {'value' : event._def.extendedProps.travelTimeFrom})}}
                        </div>
                      </div>
                    </div>


                  </ng-container>

                </div>
              </div>
            </div>
          </div>
          <div class="daycontrol">
            <button class="secondary create_item" [routerLink]="['/calendar/compose/']" [queryParams]="{day: (day.date|date:'YYYY-MM-dd') + 'T09:00'}">
              {{t('createAgendaItem')}}
            </button>
            <div class="viewtoggle enable_planmode" (click)="onUpdatePlanMode(true)" *ngIf="!planmode">
              <i class="fa-solid fa-line-height"></i>
            </div>
            <div class="viewtoggle disable_planmode" (click)="onUpdatePlanMode(false)" *ngIf="planmode">
              <i class="fa-solid fa-list-ul"></i>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template swiperSlide>
      <div class="day">
        <div class="daybackdrop" (click)="onCloseDay()"></div>
        <div class="day-inner prev-next-control" *transloco="let t; read: 'calendar'">
          <button class="onnextprevbutton next" id="calendarDaySliderNextButton" (click)="onNavigateMonth('next')">{{t('nextMonthButton')}}<i class="fa-solid fa-arrow-right-long"></i></button>
        </div>
      </div>
    </ng-template>

  </swiper>
</div>
