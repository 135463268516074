<div class="mail-information-header">
  <div class="split-information">
    <div class="split-left">

      <div class="mail-image" [ngStyle]="{'background-image': (mailDetail.photourl!==false) ? 'url('+mailDetail.photourl+')' : ''}">

        <ng-template [ngIf]="mailDetail.From.name != ''" [ngIfElse]="showMailsAsInitials ">
          <span *ngIf="!mailDetail.photourl">
            {{ mailDetail.From.name | getInitials}}
          </span>
        </ng-template>
        <ng-template #showMailsAsInitials>
          <span *ngIf="!mailDetail.photourl">{{ mailDetail.From.email | getInitials}}</span>
        </ng-template>

        <div class="entity-tag candidate-tag" *ngIf="mailDetail.Person.candidateUid !== null">
          <i class="fa-solid fa-user"></i>
        </div>
        <div class="entity-tag relationcontact-tag" *ngIf="mailDetail.Person.relationContactUid !== null">
          <i class="fa-solid fa-user"></i>
        </div>
        <div class="entity-tag user-tag" *ngIf="mailDetail.Person.userId !== null">
          <i class="fa-solid fa-user"></i>
        </div>

      </div>

      <div class="mail-details" *transloco="let t; read: 'emails'">

        <div class="mail-person-name">{{mailDetail.From.name}}</div>
        <div class="mail-from">
          <div class="label">{{t('from')}}:</div>
          <div class="value" *ngIf="mailDetail.From.name && 0 == 2">
            <span>{{mailDetail.From.name}}</span>
          </div>
          <div class="value" *ngIf="!mailDetail.From.name && 0 == 2">
            <span>{{mailDetail.From.email}}</span>
          </div>
          <div class="value">
            <span>{{mailDetail.From.email}}</span>
          </div>
        </div>
        <div class="mail-to">
          <div class="label">{{t('to')}}:</div>
          <div class="value">
            <ng-container *ngFor="let emailPerson of mailDetail.To;let isLast=last;index as i;">
              <span *ngIf="i < 3">{{emailPerson.name}}{{isLast || (i == 2 && !showMoreToItems) ? '' : ', '}}</span>
            </ng-container>
            <ng-container *ngIf="showMoreToItems">
              <ng-container *ngFor="let emailPerson of mailDetail.To;let isLast=last;index as i;">
                <span *ngIf="i > 2">{{emailPerson.name}}{{isLast ? '' : ', '}}</span>
              </ng-container>
            </ng-container>
            <div class="show_more_holder" *ngIf="mailDetail.To.length > 3 && showMoreToItems == false">
              <button class="show_more_small" (click)="showMoreToItems = true">Show more</button>
            </div>
          </div>
        </div>

        <div class="mail-cc" *ngIf="mailDetail.Cc.length">
          <div class="label">CC:</div>
          <div class="value">
            <ng-container *ngFor="let emailPersonCc of mailDetail.Cc;let isLast=last;index as k;">
              <span *ngIf="k < 3">{{emailPersonCc.name}}{{isLast || (k == 2 && !showMoreToItems) ? '' : ', '}}</span>
            </ng-container>
            <ng-container *ngIf="showMoreCcItems">
              <ng-container *ngFor="let emailPersonCc of mailDetail.Cc;let isLast=last;index as k;">
                <span *ngIf="k > 2">{{emailPersonCc.name}}{{isLast ? '' : ', '}}</span>
              </ng-container>
            </ng-container>
            <div class="show_more_holder" *ngIf="mailDetail.Cc.length > 3 && showMoreCcItems == false">
              <button class="show_more_small" (click)="showMoreCcItems = true">{{t('showMoreAdresses')}}</button>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="split-right">

      <div class="mail-date">
        <span *ngIf="mailDetail.receivedDateTime">
          {{mailDetail.receivedDateTime|customDate}}
        </span>
        <span *ngIf="!mailDetail.receivedDateTime && mailDetail.sentDateTime">
          {{mailDetail.receivedDateTime|customDate}}
        </span>
      </div>

      <div class="mail-icons">
        <div class="mail-icon" *ngIf="darkMode" (click)="onToggleMailLight()">
          <i class="fa-solid fa-sun-bright" [ngClass]="{'inverted' : lightState}"></i>
        </div>
        <div class="mail-icon" (click)="updateMailFlagShow = true">
          <i class="fa-solid fa-flag" [ngClass]="mailDetail.tag !== 0 ? 'tag_' + mailDetail.tag : ''"></i>
        </div>
        <div class="mail-icon" *ngIf="mailDetail.private">
          <i class="fa-solid fa-user-lock"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="mail-attachments" *ngIf="mailDetail.Attachments">
    <div *ngFor="let attachment of mailDetail.Attachments|keyvalue">
      <a [href]="this.downloadLinkBase + '?ouid='+ attachment.key + '&class=EmailAttachment&mode=0&sessionId=' + user.sessionId">
        <span *ngIf="attachment.value.filename"><i class="fa-solid fa-paperclip"></i>{{attachment.value.filename}}</span>
      </a>
    </div>
  </div>
</div>
<app-email-flag-update-drawer [flagDrawerOpen]="updateMailFlagShow" (newFlag)="onFlagUpdate($event)" [currentFlag]="mailDetail.tag" [email_uid]="mailDetail.uid" (flagDrawerOpenState)="updateMailFlagShow = $event"></app-email-flag-update-drawer>
