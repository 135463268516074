<nav *ngIf="loggedin && showNavigation && loading == false" (window:resize)="onCheckActiveLine()">
  <div class="active-line" #activeLine></div>
  <ng-container *ngFor="let navitem of appModules; index as i;" >
    <a class="nav-item" [routerLink]="navitem.route" *ngIf="navitem.allowed && i < 3" routerLinkActive="active" [id]="navitem.id" #navigationitem>
      <div class="nav-item-icon">
        <i class="{{ navitem.icon }}"></i>
      </div>
      <div class="nav-item-name" *transloco="let t;">
        {{ t(navitem.title) | capitalize}}
        <span *ngIf="navitem.name == 'Emails' && unreadMailCount" class="unreadMailCountCircle">{{unreadMailCount}}</span>
      </div>
     </a>
  </ng-container>
  <a class="nav-item" id="navItemMore" (click)="onToggleNavigationDrawer()" #moreItem>
    <div class="nav-item-icon">
      <i class="fa-solid fa-ellipsis"></i>
    </div>
    <div class="nav-item-name" *transloco="let t; read: 'navigation'">
      {{t('more')}}
    </div>
  </a>
</nav>

<div class="navigation-drawer" [ngClass]="{'active': drawerOpen}" (click)="onToggleNavigationDrawer()" *ngIf="loggedin && loading == false">
  <div class="navigation-drawer-inner" (click)="$event.stopPropagation();">
    <ng-container *ngFor="let navitem of appModules; index as i;" >
      <a class="nav-item nav-item-within-drawer" [routerLink]="navitem.route" *ngIf="navitem.allowed && i > 2" routerLinkActive="active" [id]="navitem.id" #navigationitem>
        <div class="nav-item-icon">
          <i class="{{ navitem.icon }}"></i>
        </div>
        <div class="nav-item-name" *transloco="let t;">
            {{ t(navitem.title) | capitalize}}
        </div>
       </a>
    </ng-container>
    <a class="nav-item" id="navItemSignout" (click)="onSignOut()">
      <div class="nav-item-icon">
        <i class="fa-solid fa-right-from-bracket"></i>
      </div>
      <div class="nav-item-name" *transloco="let t; read: 'auth'">
        {{ t('signOut') }}
      </div>
    </a>
  </div>
</div>
