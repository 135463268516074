<div class="composer-form" *transloco="let t; read: 'emails'">
  <div class="question">
    <div class="label-values-wrap">
      <label>{{t('to')}}:</label>
      <div class="seperated-values" *ngIf="toValues.length">
        <div class="value" *ngFor="let emailAdress of toValues;index as k;" (click)="onRemoveToValue(k)">{{emailAdress}}</div>
      </div>
    </div>
    <input type="email" email name="to" id="to" (keyup.enter)="onToCheck()" (keyup)="onToKeyUp()" (blur)="onToCheck()" [(ngModel)]="to" required>
    <div class="auto-suggest" *ngIf="autoSuggestValues.to.length" #autoSuggestTo (outsideClick)="autoSuggestValues.to = []">
      <div class="auto-suggest-item" *ngFor="let suggestItem of autoSuggestValues.to" (click)="onToSelectPerson(suggestItem)">
        <div class="person-name">{{suggestItem.fullName}}</div>
        <div class="person-email">{{suggestItem.email}}</div>
      </div>
    </div>
  </div>

  <div class="question">
    <div class="label-values-wrap">
      <label>{{t('cc')}}:</label>
      <div class="seperated-values" *ngIf="ccValues.length">
        <div class="value" *ngFor="let emailAdress of ccValues;index as k;" (click)="onRemoveCcValue(k)">{{emailAdress}}</div>
      </div>
    </div>
    <input type="mail" name="cc" id="cc" (keyup.enter)="onCcCheck()" (keyup)="onCcKeyUp()" (blur)="onCcCheck()" [(ngModel)]="cc" required>
    <div class="auto-suggest" *ngIf="autoSuggestValues.cc.length" #autoSuggestCc (outsideClick)="autoSuggestValues.cc = []">
      <div class="auto-suggest-item" *ngFor="let suggestItem of autoSuggestValues.cc" (click)="onCcSelectPerson(suggestItem)">
        <div class="person-name">{{suggestItem.fullName}}</div>
        <div class="person-email">{{suggestItem.email}}</div>
      </div>
    </div>
  </div>

  <div class="question">
      <label>{{t('subject')}}:</label>
    <input type="text" name="subject" id="subject" (keyup)="onSubjectChange()" required [(ngModel)]="subjectValue">
  </div>

</div>
