import { AuthService } from './../../../../core/auth/auth.service';
import { EmailsService } from '../../../../core/services/emails.service';
import { Component, AfterContentInit } from '@angular/core';
import { SettingsService } from 'src/app/core/services/settings.service';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/core/auth/user.model';
import { CachingService } from 'src/app/core/services/caching.service';

@Component({
  selector: 'app-mailwidget',
  templateUrl: './mailwidget.component.html',
  styleUrls: ['./mailwidget.component.sass']
})
export class MailwidgetComponent implements AfterContentInit {

  current_user:User;

  constructor(private emailsService: EmailsService, private settingService: SettingsService, private authService: AuthService) {
    this.authService.user.subscribe(res => {
      this.current_user = res;
    })
  }


  emails:any = [];
  totalEmails:number = 0;
  moreThan5:boolean = false;
  listLimit:number = 1;
  noResults:boolean = false;
  dashboardLayout:any;

  ngAfterContentInit(): void {
    this.dashboardLayout = this.settingService.getSetting('02dashboardLayout').value;
    let mailWidget = this.dashboardLayout.find(item => item.component === 'app-mailwidget');
    this.listLimit = mailWidget.limit;

    this.emailsService.getMailPage(-1, 1).subscribe( resData => {
      if(resData){
        this.totalEmails = resData.items.length
        this.emails = [
          {
            page: 1,
            items: resData.items.slice(0, this.listLimit)
          }
        ];
        this.moreThan5 = resData.items.length > this.listLimit;
        this.noResults = resData.items.length === 0;
        this.addPhotoUrlToEmails();
      }
    });
  }


  addPhotoUrlToEmails(){
    for(let email in this.emails[0].items){
      if(this.emails[0].items[email].Person && this.emails[0].items[email].Person.photoFileOuid){
        this.emails[0].items[email].photourl = environment.fileServiceDownload + '?ouid=' + this.emails[0].items[email].Person.photoFileOuid + '&class=' + this.emails[0].items[email].Person.photoFileClass + '&sessionId=' + this.current_user.sessionId;
      }else{
        this.emails[0].items[email].photourl = false;
      }
    }
  }

  onFlagUpdated($event:any){
    let page = $event.page - 1;
    this.emails[page].items[$event.index].tag = $event.flag;
  }

}

