<div class="flag-drawer" (click)="onCloseDrawer()">
  <div class="flag-drawer-inner" [ngClass]="{'show': flagDrawerOpen}">
    <div class="flag-drawer-title" *transloco="let t; read: 'emails'">{{ t('pickAFlag') }}</div>
    <div class="flag-drawer-list">
      <div *ngFor="let listItem of list" (click)="onSelectFlag(listItem.id)">
        <div class="flag-drawer-list-item"  *ngIf="listItem.show">
          <div class="flag-drawer-list-item-icon" [innerHtml]="listItem.icon" [style]="{color: listItem.color ? listItem.color : ''}">
          </div>
          <div class="flag-drawer-list-item-title" *transloco="let t; read: 'emails.flags'">{{ t(listItem.title) }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
