import { Component, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-search-result-list',
  templateUrl: './search-result-list.component.html',
  styleUrls: ['./search-result-list.component.sass']
})
export class SearchResultListComponent implements OnDestroy{

  @Input() listItems:any;
  @Input() listType:string;

  constructor() {
  }

  ngOnDestroy(): void {
    this.listItems = [];
    this.listType = '';
  }

}
