import { environment } from 'src/environments/environment';
import { SettingsService } from 'src/app/core/services/settings.service';
import { Subscription } from 'rxjs';
import { AuthService } from './auth.service';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { CachingService } from '../services/caching.service';

@Component({
  selector : 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.sass']
})

export class AuthComponent implements OnInit,OnDestroy, AfterViewInit{

  loading = false;
  tunixchecked = false;
  tunixEnabled:any = false;
  tunixDenied = false;
  noRights:boolean = false;
  submittedWithoutData = false;
  incorrectCredentials = false;
  incorrectSessionIdInUrl:boolean = false;
  generalError = false;
  backgroundImage:string = '';
  userRedirected:boolean = false;
  prefix:string = '';
  showPassword:boolean = false;

  logoClickCount:number = 0;

  azureMode:boolean = false;
  azureNoPrefix:boolean = false;
  azureInvalidPrefix:boolean = false;

  totpInvalidCode = false;
  totpChecked = false;
  totpEnabled = false;

  $subscription1:Subscription;
  $subscription2:Subscription;
  $subscription3:Subscription;
  $subscription4:Subscription;
  $subscription5:Subscription;

  @ViewChild('userNameInput') userNameInput: ElementRef;
  @ViewChild('passwordInput') passwordInput: ElementRef;
  @ViewChild('totpCodeInput') totpCodeInput: ElementRef;

  userNameValue = '';
  passwordValue = '';


  constructor(private authService: AuthService, private router: Router,private route: ActivatedRoute, private settingService: SettingsService, private cachingService: CachingService){
    this.backgroundImage = 'app-login-' + this.getRandomInt(1,2) + '.jpg';

    // Used by the verify-token component - Azure.
    let currentRouteExtra = this.router.getCurrentNavigation().extras;
    if(currentRouteExtra && currentRouteExtra.state && currentRouteExtra.state['sessionId']){
      this.onLoginWithSessionId(currentRouteExtra.state['sessionId']);
    }
    // No actively used by any known method, but is usefull for testing reasons
    this.route.queryParams.subscribe(params => {
      if(params['sessionId']){
        this.onLoginWithSessionId(params['sessionId']);
      }
    });
  }



  ngAfterViewInit(): void {
    if(this.azureMode == false){
      this.appleNativeAppBugFix();
    }
  }

  ngOnInit(): void {
    if(localStorage.getItem('sso_prefix')){
      let prefix = localStorage.getItem('sso_prefix');
      this.prefix = prefix;
      this.onClickAzureLogin();
    }
  }

  // Because the native wrapper uses WkWebView and that thing is bugged because of stupid apple shit we have to this...
  appleNativeAppBugFix(){
    const nativeEmailInput = this.userNameInput.nativeElement;
    const nativePasswordInput = this.passwordInput.nativeElement;
    nativeEmailInput.addEventListener('change', (ev: Event) => {
      requestAnimationFrame(() => {
        this.userNameValue = (ev.target as HTMLInputElement).value;
      });
    });
    nativePasswordInput.addEventListener('change', (ev: Event) => {
      requestAnimationFrame(() => {
        this.passwordValue = (ev.target as HTMLInputElement).value;
      });
    });
    nativeEmailInput.addEventListener('keyup', (ev: Event) => {
      requestAnimationFrame(() => {
        this.userNameValue = (ev.target as HTMLInputElement).value;
      });
    });
    nativePasswordInput.addEventListener('keyup', (ev: Event) => {
      requestAnimationFrame(() => {
        this.passwordValue = (ev.target as HTMLInputElement).value;
      });
    });
  }


  onSubmit(form: NgForm){
    /*
      Can't use the regular way because of apple's stupid shitty bug....
      So now we check if the fields are filled, otherwise we fallback on the Angular form data
    */

    // First dispatch all the events on the inputs so their value gets updated
    this.userNameInput.nativeElement.dispatchEvent(new Event('change'));
    this.passwordInput.nativeElement.dispatchEvent(new Event('change'));
    this.userNameInput.nativeElement.dispatchEvent(new Event('keyup'));
    this.passwordInput.nativeElement.dispatchEvent(new Event('keyup'));

    // Now check the hard variables for a value that can be filled by the appleNativeAppBugFix() function
    var username = this.userNameValue;
    var password = this.passwordValue;

    // If these fields are empty, we can fill the variable with the acutal form data from angular
    if(username == '' || password == ''){
      username = form.value.username;
      password = form.value.password;
    }

    // If it's still empty the user probably didn't fill something in
    if(username == '' || password == ''){
      this.loading = false;
      this.noRights = false;
      this.tunixchecked = false;
      this.tunixEnabled = false;
      this.submittedWithoutData = true;
      this.incorrectCredentials = false;
      this.generalError = false;
      this.incorrectSessionIdInUrl = false;
      this.tunixDenied = false;
    }else{
      this.loading = true;
      this.noRights = false;
      this.tunixchecked = false;
      this.tunixEnabled = false;
      this.submittedWithoutData = false;
      this.incorrectCredentials = false;
      this.generalError = false;
      this.incorrectSessionIdInUrl = false;
      this.tunixDenied = false;

      // Check if Tunix is enabled
      this.onCheckTunix(username,password);
    }
    // reset the field values
    this.userNameValue = '';
    this.passwordValue = '';
  }

  onAzureSubmit(form: NgForm){
    const prefix = form.value.prefix;
    this.azureNoPrefix = false;
    this.azureInvalidPrefix = false;
    if(prefix.length){
      this.loading = true;

      this.checkPrefix(prefix);

    }else{
      this.azureNoPrefix = true;
    }
  }

  onTotpSubmit(form: NgForm){
    const totpCode = form.value.code;
    this.totpInvalidCode = false;
    if(totpCode.length){
      this.loading = true;
      this.checkTotpCode(totpCode);
    }else{
      this.totpInvalidCode = true;
    }
  }


  checkTotpCode(code:string){
    this.$subscription5 = this.authService.checkTotpCode(code).subscribe(resData => {
      if(resData){
        let result = resData.result.verified;
        if(result == true){
          this.authService.updateUser(true);
          this.watchUser();
        }else{
          this.totpInvalidCode = true;
          this.loading = false;
        }
      }
    });
  }

  checkPrefix(prefix:string){
    this.$subscription4 = this.authService.checkPrefix(prefix).subscribe(resData => {
      let result = resData.result.azure;
      if(result.azure_enable){
        if(result.sso_url){
         localStorage.setItem('sso_prefix', prefix);
         localStorage.setItem('sso_token', result.otysToken);
          // Redirect to sso url
          window.location.href = result.sso_url;
        }
      }else{
        this.azureInvalidPrefix = true;
        this.loading = false;
      }
    });
  }

  onCheckTunix(username:string, password:string){
    this.$subscription1 = this.authService.checkTunix(username, password).subscribe(resData => {
      if(resData.result == 1){
        this.tunixEnabled = true;
      }else{
        this.tunixEnabled = false;
      }
      this.tunixchecked = true;
      this.onLogin(username,password);
    },// Have to check for HTTP ERROR Response becuase of dumb OWS logic
    () => {
      this.tunixchecked = true;
      this.tunixEnabled = false;
      this.onLogin(username,password);
    });
  }

  onCheckTotp(sessionId){
    this.$subscription1 = this.authService.checkTotp(sessionId).subscribe(resData => {
      if(resData.result.totpActive == true){
        this.authService.setCheckResponse(resData.result);
        this.totpEnabled = true;
        this.loading = false;
        setTimeout(() => {
          if(this.totpCodeInput && this.totpCodeInput.nativeElement){
            this.totpCodeInput.nativeElement.focus();
          }
        }, 1500);
      }else{
        this.totpEnabled = false;
        this.watchUser();
      }
      this.totpChecked = true;
    });
  }

  onLogin(username:string, password:string){
    this.$subscription1 = this.authService.login(username, password).subscribe(resData => {
      if(typeof resData.result === 'string'){
        // This means we got back an session ID, and we can go on
        this.onCheckTotp(resData.result);
      }else if(resData.result === false){
        this.submittedWithoutData = true;
        this.loading = false;
      }else if(resData.error.code == 401){
        if(this.tunixEnabled){
          this.tunixDenied = true;
          this.loading = false;
        }else{
          this.generalError = true;
          this.loading = false;
        }
      }
    },// Have to check for HTTP ERROR Response becuase of dumb OWS logic
    () => {
      this.incorrectCredentials = true;
      this.loading = false;
    });
  }

  onLoginWithSessionId(sessionId:string){
    this.loading = true;
    this.noRights = false;
    this.tunixchecked = false;
    this.tunixEnabled = false;
    this.submittedWithoutData = false;
    this.incorrectCredentials = false;
    this.generalError = false;
    this.incorrectSessionIdInUrl = false;
    this.tunixDenied = false;

    this.authService.loginBySessionId(sessionId);

    this.authService.checkIfSessionIdIsValid().subscribe(response => {
      if(response && response.result !== false){
        this.watchUser();
      }else{
        this.incorrectSessionIdInUrl = true;
        this.loading = false;
      }
    });
  }

  watchUser(){
    this.$subscription3 = this.authService.user.subscribe(user => {
      if(user && user.id && user.modules && user.modules.length > 0 && user.userSettings){
        if(this.userRedirected == false){
          if(user.canUseApp === true || user.canUseApp === false){
            if(user.canUseApp === true){
              this.router.navigate(['/dashboard']);
              this.loading = false;
              this.userRedirected = true;
              this.noRights = false;
              this.settingService.triggerThemeChange();
            }else{
              this.noRights = true;
              this.loading = false;
              this.authService.logout();
            }
          }
        }
      }
    })
  }

  onClickAzureLogin(){
    this.azureMode = true;
  }

  onGoBackToRegularLogin(){
    this.azureMode = false;
    localStorage.setItem('sso_prefix', '');
    this.appleNativeAppBugFix();
  }

  getRandomInt(min:number, max:number):string {
    min = Math.ceil(min);
    max = Math.floor(max);
    let result = Math.floor(Math.random() * (max - min + 1)) + min;
    return result.toString();
  }

  clickLogo(){
    this.logoClickCount++;
    if(this.logoClickCount == 3){
      this.cachingService.resetCaching();
    }
  }

  ngOnDestroy(): void {
    if(this.$subscription1){
      this.$subscription1.unsubscribe();
    }
    if(this.$subscription2){
      this.$subscription2.unsubscribe();
    }
    if(this.$subscription3){
      this.$subscription3.unsubscribe();
    }
  }
}
