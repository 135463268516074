<div class="list">
  <div class="listpage page_{{page.page}}" *ngFor="let page of eventsListing;">
    <ng-container *ngFor="let event of page.items">
      <div class="list-item list-item-event" [routerLink]="['/calendar/compose/' + event.uid]">
        <div class="list-image">
         {{event.initials}}
        </div>
        <div class="list-item-content">
          <div class="list-item-content-left">
            <div class="list-item-subject semibold">{{event.title}}</div>
            <div class="list-item-subtitle">{{event.startDateTime|date:'dd-MM-YYYY'}} {{event.startDateTime|date:'HH:mm'}} - {{event.endDateTime|date:'HH:mm'}}</div>
          </div>
          <div class="list-item-content-right">
            <div class="top">
              <div class="list-item-date"></div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
