import { Component, Input, OnInit } from '@angular/core';

interface adressObject{
  type: string,
  address: string,
  city: string,
  postcode:string,
  countryCode:string,
  country: string,
  email: string,
  website: string,
  description:string,
  phoneNumberMain: string,
  phoneNumberFax: string,
  name: string,
  stateId: string
}

@Component({
  selector: 'app-adress-with-icon',
  templateUrl: './adress-with-icon.component.html',
  styleUrls: ['./adress-with-icon.component.sass']
})
export class AdressWithIconComponent implements OnInit{

  @Input() adress:adressObject;
  validobj:boolean = false;
  fulladress:string = '';
  urlAdress:string = '';

  ngOnInit() {
    if(this.adress.address){
      this.fulladress += this.adress.address;
      this.validobj = true;
    }
    if(this.adress.address && this.adress.postcode){
      this.fulladress += ', ';
    }
    if(this.adress.postcode){
      this.fulladress += this.adress.postcode;
      this.validobj = true;
    }
    if(this.adress.postcode){
      this.fulladress += ' ';
    }
    if(this.adress.city){
      this.fulladress += this.adress.city;
      this.validobj = true;
    }

    this.urlAdress = encodeURIComponent(this.fulladress);
  }
}
