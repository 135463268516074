import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { AuthService } from './../../core/auth/auth.service';
import { Component, ViewChild, OnDestroy } from '@angular/core';
import { User } from 'src/app/core/auth/user.model';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.sass']
})
export class SettingsComponent implements OnDestroy {

  config: SwiperOptions = {
    slidesPerView: 1,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        let tabs = ['General', 'Dashboard', 'Email', 'Calendar'];
        return '<span class="' + className + '">' + tabs[index] + '</span>';
      },
    }
  };

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  current_user:User;

  $subscription1:Subscription;
  $subscription2:Subscription;

  constructor(private authService: AuthService, private translationService: TranslocoService) {
    this.$subscription2 = this.authService.user.subscribe(res => {
      this.current_user = res;
    });

    this.loadTabTranslations();
  }


  loadTabTranslations(){
    this.$subscription1 = this.translationService.selectTranslate('settings.tabGeneral').subscribe(res => {
      // Get the translation values for the tabs
      let tabs = [
        this.translationService.translate('settings.tabGeneral'),
        this.translationService.translate('settings.tabDashboard'),
        this.translationService.translate('settings.tabEmail'),
        this.translationService.translate('settings.tabCalendar')
      ];
      // Set the new renderBullet function for the new translations
      this.config.pagination = {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + tabs[index] + '</span>';
        },
      };
    });
  }

  ngOnDestroy(): void {
    if(this.$subscription1){
      this.$subscription1.unsubscribe();
    }
    if(this.$subscription2){
      this.$subscription2.unsubscribe();
    }
  }

}
